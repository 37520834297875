document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "workers") {
        
        $('#user_phone').mask('(216) 99 999999');
        $('#user-phone').mask('(216) 99 999999');

        $("#user_phone").on("click", function() {
            let tel = document.getElementById("user_phone");
            tel.focus()
            tel.setSelectionRange(6, 6);
        })

        $("#user-phone").on("click", function() {
            let tel = document.getElementById("user-phone");
            tel.focus()
            tel.setSelectionRange(6, 6);
        })

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_users != null)
                table_users.destroy();
        })

        table_users = $('#table-equipe').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-equipe').data('source'),
            },
            "columns": [
                {"data": "full_name"},
                {"data": "role"},
                {"data": "workshop"},
                {"data": "active",
                    "render": function(data, display, record) {
                        return record.decorate_active
                    }
                },
                {"data": "id",
                    "render": function(data, display, record) {

                        if ($("body").data("user_role") == "super_admin" || $("body").data("user_role") == "admin") {
                            let edit = ""
                            if ($("body").data("user_role") == "admin" && parseInt(record.id) === parseInt($("body").data("user_id"))) {
                                edit = `<a href="/workers/${record.id}/edit" class="btn-edit-user" title="Modifier"><i class="fa-solid fa-pen" aria-hidden="true"></i></a>`
                            }
                            else if ($("body").data("user_role") == "super_admin" || $("body").data("user_role") == "admin") {
                                edit = `<a href="/workers/${record.id}/edit" class="btn-edit-user" title="Modifier"><i class="fa-solid fa-pen" aria-hidden="true"></i></a>`
                                if (record.role == "super_admin") {
                                    edit = ""
                                }
                            }

                            let supp = ''

                            if ($("body").data("user_role") == "super_admin" || $("body").data("user_role") == "admin") {
                                if (record.manager_appointments == "false") {
                                        supp = `<a href="javascript:" class="btn-delete-user" title="Supprimer"><i class="fa-solid fa-trash" aria-hidden="true"></i></a>`
                                }
                            }

                            return `${edit} ${supp}`
                        }
                        else {
                            return ''
                        }
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
                if (data.active == "false") {
                    $(row).addClass("user_inactive")
                }
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        $("#user-role").on("change", function() {
          
            let role = $(this).val();

            if (role != "manager") {
                $(".services-content").addClass("d-none")
            }
            else {
                $(".services-content").removeClass("d-none")
            }
        })

        $("#btn-save_user").on('click', function() {
            let formData = new FormData();
            formData.append('role', $("#user-role").val());
            formData.append('full_name', $("#user-full_name").val());
            formData.append('email', $("#user-email").val());
            formData.append('password', $("#user-password").val());

            fetch(`/workers`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                    })
                }
            })
        })

        $(document).on("click", ".btn-delete-user", function(e) {
            let id = $(this).parent().parent().data("element_id")

            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous supprimer cet utilisateur?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/workers/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                               $(`#table-equipe tr[data-element_id='${id}']`).remove()
                            })
                        }
                    })
                }
            }).then((result) => {})
        })
        
    }
})