const { duration } = require("moment")

document.addEventListener("turbolinks:load", function() {
    if(($("body").data("controller") == "home" || $("body").data("controller") == "calendar" ) && ($("body").data("user_role") == "manager" || $("body").data("user_role") == "admin" || ($("body").data("user_role") == "super_admin"))) {

        let table_all;
        let table_pending;
        let table_inprogress;
        let table_accepted;
        let table_client_rates;
        let table_canceled;
        let table_rejected;
        let table_completed;
        let table_rates;

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_all != null)
                table_all.destroy();
            if (table_pending != null)
                table_pending.destroy();
            if (table_inprogress != null)
                table_inprogress.destroy();
            if (table_accepted != null)
                table_accepted.destroy();
            if (table_canceled != null)
                table_canceled.destroy();
            if (table_rejected != null)
                table_rejected.destroy();
            if (table_completed != null)
                table_completed.destroy();
            if (table_rates != null)
                table_rates.destroy();
            if (table_client_rates != null)
                table_client_rates.destroy();

        })

        var rater = require("rater-js");
        var myRater;
        let service_rater;
        let appointment_id;
        let service_id;
        let modal_new_car
     
        $("#new-appoint-phone").mask('(216) 99 999999');

        $("#calendar-services").on("change", function() {
            let service_id = $(this).val()
            
            window.location = "/calendar?service=" + service_id
        })
        
        if ($("#selected_service").val() != "")
            $("#calendar-services").val($("#selected_service").val())
        else 
            $("#calendar-services").val($("#calendar-services option:first").val())

        
        $('#list_clients').select2({
            dropdownParent: $('#modal-new_appointment'),
            minimumResultsForSearch: '',
            theme: 'bootstrap-5',
            width: '100%',
            placeholder: "Rechercher un client",
            ajax: {
                url: '/workers/search',
                dataType: 'json',
                cache: false,
                data: function (params) {
                    $(".select2-results__options").append("<img src='/assets/loader'>")
                    var query = {
                      search: params.term,
                      type: 'public',
                      date: $("#new-appoint-date").val()
                    }
                    return query;
                },
                processResults: function (data, params) {
                    
                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                }
            },
            cache: false,
            minimumInputLength: 2,
            templateResult: function (repo) {
                if (repo.loading) {
                    return repo.name;
                }
                
                var $container = $(
                    "<div class='select2-result-repository clearfix'>" +
                        "<div class='select2-result-repository__meta'>" +
                            "<div class='select2-result-repository__title'></div>" +
                        "</div>" +
                    "</div>"
                );
                
                $container.find(".select2-result-repository__title").text(repo.name);
                
                return $container;
            },
            templateSelection: function(repo) {
                if (repo != undefined){

                    $("#new-appoint-full_name").val(repo.name)
                    $("#new-appoint-phone").val(repo.phone)
                    $("#new-appoint-email").val(repo.email)
                    $("#new-appoint-matricule").html("")

                    // check car dispnibility
                    fetch(`/workers/check-dispo-cars/${$("#new-appoint-date").val()}/${repo.id}`, {
                        method: 'GET',
                        mode: 'cors',
                        cache: 'default',
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {

                                $("#btn-new-car").removeClass("d-none")
                                for(var i=0 ; i<repo.cars.length ; i++) {

                                    let disabled = ""
                                    if (data.find(({ car_id, date }) => car_id === repo.cars[i].id && $("#new-appoint-date").val() === date)) {
                                        disabled = "disabled"
                                    }

                                    if ($(`#new-appoint-matricule option[value='${repo.cars[i].id}']`).length == 0) {
                                    $("#new-appoint-matricule").append(`
                                        <option data-km='${repo.cars[i].brand.km}' value='${repo.cars[i].id}' ${disabled} ${repo.cars_pending.includes(repo.cars[i].id) ? 'disabled' : ''}>${repo.cars[i].brand.brand} ${repo.cars[i].model.name} (${repo.cars[i].car_number})</option>
                                    `)
                                    }

                                    // $("#new_km").html('')
                                    // // km
                                    // for(var j=1 ; j<34 ; j++ ) {
                                    //     $("#new_km").append(`<option value='${j * repo.cars[i].brand.km}'>${j * repo.cars[i].brand.km}</option>`)
                                    // }

                                }

                               $("#new-appoint-matricule").trigger("change")
                            })
                        }
                    })

                    // if (repo.cars != null ) {
                    //     for(var i=0 ; i<repo.cars.length ; i++) {
                    //         if ($(`#new-appoint-matricule option[value='${repo.cars[i].id}']`).length == 0) {
                               
                    //             if (repo.cars[i].active === true) {
                    //                 let disabled = ""
                    //                 if (repo.appointments.find(({ car_id, date }) => car_id === repo.cars[i].id && $("#new-appoint-date").val() === date)) {
                    //                     disabled = "disabled"
                    //                 }

                    //                 $("#new-appoint-matricule").append(`
                    //                     <option value='${repo.cars[i].id}' ${disabled} >${repo.cars[i].model} (${repo.cars[i].car_number})</option>
                    //                 `)
                    //             }
                    //         }
                    //     }
                    // }
                    
                    $("#new-appoint-client_id").val(repo.id)

                    // if (repo.name.includes("(no subscription)") || repo.name.includes("(expired)") || repo.name.includes("(canceled)")) {
                    //     $("#btn-save-booking").addClass("disabled")
                    //     $("#btn-save-booking").attr("disabled", true)
                    //     $(".error-member-state").html("<b>Unable to save because the member subscription is not active!</b>")
                    // }
                    // else {
                    //     $("#btn-save-booking").removeClass("disabled")
                    //     $("#btn-save-booking").attr("disabled", false)
                    //     $(".error-member-state").html("")
                    // }

                }
        
                return repo.name || repo.text;
                
            }
        })

        $(document).on("change", "#new-appoint-matricule", function() {
            let km = $("#new-appoint-matricule option:selected").attr("data-km")

            $("#new_km").html('')
            $("#new_km").append("<option selected disabled>km</option>")
            for(var j=1 ; j<34 ; j++ ) {
                $("#new_km").append(`<option value='${j * km}'>${j * km}</option>`)
            }
        })

        $('#list_client_rates').select2({
            dropdownParent: $('#modal-see_rates'),
            minimumResultsForSearch: '',
            theme: 'bootstrap-5',
            width: '100%',
            placeholder: "Rechercher un client",
            ajax: {
                url: '/workers/search',
                dataType: 'json',
                data: function (params) {
                    $(".select2-results__options").append("<img src='/assets/loader'>")
                    var query = {
                      search: params.term,
                      type: 'public'
                    }
                    return query;
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                }
            },
            cache: true,
            minimumInputLength: 2,
            templateResult: function (repo) {
                if (repo.loading) {
                    return repo.name;
                }
                
                var $container = $(
                    "<div class='select2-result-repository clearfix'>" +
                        "<div class='select2-result-repository__meta'>" +
                            "<div class='select2-result-repository__title'></div>" +
                        "</div>" +
                    "</div>"
                );
                
                $container.find(".select2-result-repository__title").text(repo.name);
                
                return $container;
            },
            templateSelection: function(repo) {
                if (repo != undefined && service_id != undefined){
                    fetch(`/appointments/get-client-rates/${repo.id}/${service_id}`, {
                        method: 'GET',
                        mode: 'cors',
                        cache: 'default',
                    }).then(function(response) {
                        response.json().then(function(data) {
                            table_client_rates.rows().clear().draw();
                            
                            jQuery.each(data, function (i, val) {
                                
                                let el = document.createElement('div')
                                el.setAttribute("id", `client_rating-${val.id}`)
                                
                                let client_rating = rater({element: el, rateCallback: function rateCallback(rating, done) {
                                    client_rating.setRating(rating);
                                    done();
                                }, 
                                    starSize:32,
                                    step: 0.5,
                                    rating: val.star,
                                });
                                client_rating.disable()

                                let newRow = table_client_rates.row.add([
                                    val.date,
                                    val.name,
                                    el.outerHTML,
                                    val.note
                                ] ).draw( false ).node();
            
                                $(newRow).attr("data-element_id", data.id);
                            });
                        })
        
                    });
                }
        
                return repo.name || repo.text;
            }
        })

        table_all = $('#table-all_appointments').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-all_appointments').data('source'),
                data: function(e) {
                    e.service_id = $("#filter-services option:selected").val()
                    e.workshop_id = $("#filter-workshops option:selected").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "service"},
                {"data": "workshop"},
                {"data": "date"},
                {"data": "time"},
                {"data": "user_name"},
                {"data": "car_number"},
                {"data": "state"},
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="javascript:void(0)" class='btn-see-appointment' data-method='update' data-bs-effect="effect-scale" title="Voir"><i class="fa-solid fa-eye" aria-hidden="true"></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "columnDefs": [
                {
                    "width": '200px', targets: [0],
                    "width": '300px', targets: [1],
                    "width": '100px', targets: [2,3],
                    "width": '60px', targets: [6],
                }
            ],
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            "bAutoWidth": false,
            'order' : [[2,'desc']]
        });

        table_pending = $('#table-pending').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-pending').data('source'),
                data: function(e) {
                    e.service_id = $("#filter-services option:selected").val()
                    e.workshop_id = $("#filter-workshops option:selected").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "service"},
                {"data": "workshop"},
                {"data": "date"},
                {"data": "time"},
                {"data": "user_name"},
                {"data": "car_number"},
                {"data": "state"},
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="javascript:void(0)" class='btn-see-appointment' data-method='update' data-bs-effect="effect-scale" title="Voir"><i class="fa-solid fa-eye" aria-hidden="true"></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            "bAutoWidth": false,
            'order' : [[2,'desc']]
        });
        
        table_inprogress = $('#table-inprogress').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-inprogress').data('source'),
                data: function(e) {
                    e.service_id = $("#filter-services option:selected").val()
                    e.workshop_id = $("#filter-workshops option:selected").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "service"},
                {"data": "workshop"},
                {"data": "date"},
                {"data": "time"},
                {"data": "user_name"},
                {"data": "car_number"},
                {"data": "state"},
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="javascript:void(0)" class='btn-see-appointment' data-method='update' data-bs-effect="effect-scale" title="Voir"><i class="fa-solid fa-eye" aria-hidden="true"></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            "bAutoWidth": false,
            'order' : [[2,'desc']]
        });

        table_accepted = $('#table-accepted').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-accepted').data('source'),
                data: function(e) {
                    e.service_id = $("#filter-services option:selected").val()
                    e.workshop_id = $("#filter-workshops option:selected").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "service"},
                {"data": "workshop"},
                {"data": "date"},
                {"data": "time"},
                {"data": "user_name"},
                {"data": "car_number"},
                {"data": "state"},
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="javascript:void(0)" class='btn-see-appointment' data-method='update' data-bs-effect="effect-scale" title="Voir"><i class="fa-solid fa-eye" aria-hidden="true"></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            "bAutoWidth": false,
            'order' : [[2,'desc']]
        });

        table_completed = $('#table-completed').DataTable({
            "responsive": true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-completed').data('source'),
                data: function(e) {
                    e.service_id = $("#filter-services option:selected").val()
                    e.workshop_id = $("#filter-workshops option:selected").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "service"},
                {"data": "workshop"},
                {"data": "date"},
                {"data": "time"},
                {"data": "user_name"},
                {"data": "car_number"},
                {"data": "state"},
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="javascript:void(0)" class='btn-see-appointment' data-method='update' data-bs-effect="effect-scale" title="Voir"><i class="fa-solid fa-eye" aria-hidden="true"></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            "bAutoWidth": false,
            'order' : [[2,'desc']]
        });  
        $(table_completed).parent().parent().removeClass("active").removeClass("show")

        table_canceled = $('#table-canceled').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-canceled').data('source'),
                data: function(e) {
                    e.service_id = $("#filter-services option:selected").val()
                    e.workshop_id = $("#filter-workshops option:selected").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "service"},
                {"data": "workshop"},
                {"data": "date"},
                {"data": "time"},
                {"data": "user_name"},
                {"data": "car_number"},
                {"data": "state"},
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="javascript:void(0)" class='btn-see-appointment' data-method='update' data-bs-effect="effect-scale" title="Voir"><i class="fa-solid fa-eye" aria-hidden="true"></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            "bAutoWidth": false,
            'order' : [[2,'desc']]
        });
        $(table_canceled).parent().parent().removeClass("active").removeClass("show")

        table_rejected = $('#table-rejected').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-rejected').data('source'),
                data: function(e) {
                    e.service_id = $("#filter-services option:selected").val()
                    e.workshop_id = $("#filter-workshops option:selected").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "service"},
                {"data": "workshop"},
                {"data": "date"},
                {"data": "time"},
                {"data": "user_name"},
                {"data": "car_number"},
                {"data": "state"},
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="javascript:void(0)" class='btn-see-appointment' data-method='update' data-bs-effect="effect-scale" title="Voir"><i class="fa-solid fa-eye" aria-hidden="true"></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            "bAutoWidth": false,
            'order' : [[2,'desc']]
        });

        $(table_rejected).parent().parent().removeClass("show").removeClass("active")

        table_client_rates = $('#table-client_rates').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bAutoWidth": false,
            'order' : [[1,'desc']]
        });
      
        table_rates = $('#table-rates').DataTable({
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-rates').data('source'),
                data: function(e) {
                    e.service_id = $("#filter-rate_services").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "service_name"},
                {"data": "stars",
                    "render": function(data, display, record) {
                        service_rater = null;
                        let el = document.createElement('div')
                        el.setAttribute("id", `service_rating-${record.id}`)
                        
                        service_rater = rater({element: el, rateCallback: function rateCallback(rating, done) {
                            service_rater.setRating(rating);
                            done();
                        }, 
                            starSize:32,
                            step: 0.5,
                            rating: parseFloat(record.stars),
                        });
                        service_rater.disable()

                        return  el.outerHTML
                    }
                },
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="javascript:void(0)" class='btn-see-rates' data-service_id='${record.id}' data-method='update' data-bs-effect="effect-scale" title="Voir"><i class="fa-solid fa-eye" aria-hidden="true"></i></a>`
                    }
                }
            ],
            "columnDefs": [
                {"orderable": false, "targets": [0, 1, 2]}
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
                if (document.querySelector(`#service_rating-${data.id}`) != null) {
                    
                   /* $(`#service_rating-${data.id}`).html("")
        
                    service_rater = null;
                    service_rater = rater({element: document.querySelector(`#service_rating-${data.id}`), rateCallback: function rateCallback(rating, done) {

                        service_rater.setRating(rating);
                        done();
                    }, 
                        starSize:32,
                        rating: parseInt(data.stars),
                    });

                    service_rater.disable()
                    $(`#service_rating-${data.id}`).html()*/
                }
            },
            "initComplete":function( settings, json){
           
               /* jQuery.each(json.data, function (i, val) {
                    service_rater = null;
                    service_rater = rater({element: document.querySelector(`#service_rating-${val.id}`), rateCallback: function rateCallback(rating, done) {

                        service_rater.setRating(rating);
                        done();
                    }, 
                        starSize:32,
                        rating: parseInt(val.stars),
                    });

                    service_rater.disable()
                })*/
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "bAutoWidth": false,
            'order' : [[1,'desc']]
        });


        $(".appointments-tables").on('click', ".btn-see-appointment", function(e) {
            appointment_id = $(e.currentTarget).parent().parent().data('element_id')
          
            fetch(`/appointments/${appointment_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $(`.appointment-${$(e.currentTarget).data('appointment_id')} .indicator`).remove()
                        $('#see_appointment_id').val(data.id)
                        $("#see_workshop").val(data.workshop.name)
                        $("#see_service").val(`${data.service.name} (${data.duration} ${Pluralize( 'minute', data.duration )})`)
                        $("#see_date").val(moment(data.date).locale("fr").format('dddd DD MMM YYYY'))
                        $("#see_periode").val(`${data.time} à ${ moment(new Date(`2024-08-27 ${data.time}`)).add(data.duration, "minutes").format("k:mm")}`);
                        //$("#see_periode").val(`${data.time} à ${parseInt(data.time.replace('h:00','')) + data.service.duration}h:00`);
                        $("#see_full_name").val(data.user.full_name)
                        $("#see_phone").val(data.phone)
                        $("#see_email").val(data.email);
                        $("#see_car_number").val(`${data.brand.brand} ${data.car.model.name} (${data.car.car_number})`);
                        $("#see_vin").val(`${data.car.vin}`);
                        $("#see_km").val(data.km);
                        $("#see_notes").val(data.notes);
                        $("#see_description").val(data.description);
                        $("#see_note_intern").val(data.note_intern);
                        $("#see_note_client").val(data.note_client);

                        if (data.state == "completed" || data.state == "rejected" || data.state == "canceled")
                            $("#see_state option").remove();
                        else {
                            $("#see_state option").remove();
                            $("#see_state").append(`<option value='accepted'>Accepté</option>`);
                            $("#see_state").append(`<option value='canceled'>Annulé</option>`);
                            $("#see_state").append(`<option value='rejected'>Rejeté</option>`);
                            $("#see_state").append(`<option value='completed'>Terminé</option>`);
                            $("#see_state").append(`<option value='pending'>En attente</option>`);
                            $("#see_state").append(`<option value='in_progress'>En cours d'entretien</option>`);
                            $("#see_state").append(`<option value='missed'>Client ne s'est pas présenté</option>`);
                        }

                        if (data.state == "completed") {
                            $("#see_state").append(`<option value='${data.state}'>Terminé</option>`);
                            $(".invoice_container").removeClass("d-none")
                            $("#see_invoice").val(data.invoice)
                        }
                        else {
                            $(".invoice_container").addClass("d-none")
                            $("#see_invoice").val("")
                        }

                        if (data.state == "canceled") 
                            $("#see_state").append(`<option value='${data.state}'>Annulé</option>`);
                        if (data.state == "rejected") 
                            $("#see_state").append(`<option value='${data.state}'>Rejeté</option>`);
                        

                        if (data.service.slug == 'carrosserie') {
                            $(".carrosserie").removeClass('visually-hidden')
                        }
                        else {
                            $(".carrosserie").addClass('visually-hidden')
                        }
                        $(`#see_state option[value='${data.state}']`).prop("selected", true)
                        
                        // rating
                        if (data.rating != null) {
                            
                            myRater = null
                            $("#rater").html("")

                            myRater = rater({element: document.querySelector("#rater"), rateCallback: function rateCallback(rating, done) {
                                myRater.setRating(rating);
                                done();
                            }, 
                                starSize:50,
                                step: 0.5,
                                rating: data.rating.star,
                            });

                            myRater.disable()

                            $("#nav-rating-tab").removeClass("d-none")

                            $("#rate_note").val(data.rating.note)
                        }
                        else {
                            $("#nav-rating-tab").addClass("d-none")
                        }

                        modal_appointment = new bootstrap.Modal(document.getElementById("modal-see_appointment"), {backdrop: 'static', keyboard: false});
                        modal_appointment.show()
                    })
                }
            })
        })

        $("#table-rates").on('click', ".btn-see-rates", function(e) {
            service_id = $(this).data('service_id')

            $(".service_name").text($(`#table-rates tr[data-element_id='${service_id}'] > td:first`).text())

            modal_rates = new bootstrap.Modal(document.getElementById("modal-see_rates"), {backdrop: 'static', keyboard: false});
            modal_rates.show()
            
            /*fetch(`/appointments/${appointment_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {

                    })
                }
            })*/
        })

        if ($("body").data("controller") == "calendar" ){
            getAppointments()
        }

        $("#btn-save_appointment").on('click', function(e) {
            appointment_id = $("#see_appointment_id").val()

            let formData = new FormData();
            formData.append('state', $("#see_state option:selected").val());
            formData.append('note_intern', $("#see_note_intern").val());
            formData.append('note_client', $("#see_note_client").val());

            let state = ""
            let invoice;

            if ($("#see_state option:selected").val() == "accepted")
                state = "Accepté?"
            if ($("#see_state option:selected").val() == "canceled")
                state = "Annulé?"
            if ($("#see_state option:selected").val() == "rejected")
                state = "Rejeté?"
            if ($("#see_state option:selected").val() == "completed") {
                state = "Terminé?"
                invoice = $("#see_invoice").val()
            }
            else {
                invoice = null
            }
            
            if ($("#see_state option:selected").val() == "pending")
                state = "En attente?"
            if ($("#see_state option:selected").val() == "in_progress") {
                state = "En cours d'entretien?"
            }

            formData.append('invoice', invoice);

            Swal.fire({
                title: state,
                text: "Voulez-vous vraiment valider?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/appointments/update-appointment/${appointment_id}`, {
                        method: 'PUT',
                        mode: 'cors',
                        cache: 'default',
                        body: formData,
                    }).then(function(response) {
                        if (response.ok) {

                            $("#btn-cancel-see_appointment").attr("disabled", true)
                            $("#btn-save_appointment").attr("disabled", true)
                            $("#btn-save_appointment .spinner-border ").removeClass("d-none")

                            response.json().then(function(data) {
                                Swal.fire({
                                    title: "Mis à jour !",
                                    icon: "success",
                                    text: `Réservation de ${data.brand.brand} ${data.car.model.name} ("${data.car.car_number})" à été mis à jour!`,
                                    showDenyButton: false,
                                    showCancelButton: false,
                                    confirmButtonText: "Save",
                                    denyButtonText: `Don't save`
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                        //window.location.reload()
                                    }
                                });

                                table_inprogress.ajax.reload()
                                table_all.ajax.reload()
                                table_pending.ajax.reload()
                                table_accepted.ajax.reload()
                                table_canceled.ajax.reload()
                                table_rejected.ajax.reload()
                                table_completed.ajax.reload()
                                
                           
                                $("#btn-cancel-see_appointment").attr("disabled", false)
                                $("#btn-save_appointment").attr("disabled", false)
                                $("#btn-save_appointment .spinner-border ").addClass("d-none")

                                $("#modal-see_appointment").hide();
                                $(".modal-backdrop.fade.show").remove()
                                //$("#modal-see_appointment").modal("hide")

                                if (data.state == "completed") {
                                    state = "<span class='badge text-bg-success'>Fini</span>"

                                    $(`.rdv.appointment-${data.id} .badge`).remove()
                                    $(`.rdv.appointment-${data.id}`).prepend(state)
                                }
                                else if (data.state == "missed") {
                                    state = "<span class='badge text-bg-danger'>Client ne s'est pas présenté</span>"

                                    $(`.rdv.appointment-${data.id} .badge`).remove()
                                    $(`.rdv.appointment-${data.id}`).prepend(state)
                                }
                                else if (data.state == "rejected") {
                                    let parent = $(`.rdv.appointment-${data.id}`).parent().parent()

                                    $(`.rdv.appointment-${data.id}`).parent().remove()

                                    if ($(`.date_${data.date}_${parseInt(data.time.replace('h:00',''))}`).length == 0)  {
                                        $(`.table_${data.date}_${parseInt(data.time.replace('h:00',''))}`).remove()
                                    }
                                }

                                getAppointment()
                            })
                        }
                    })
                }
            })
        })

        $("#new-appoint-service").on("change", function() {
            getService($(this).val())
        })

        $(document).on("change", "#see_state", function() {
            if ($(this).val() == "completed")
                $(".invoice_container").removeClass("d-none")
            else
                $(".invoice_container").addClass("d-none")
        })
        
        function getAppointment() {

            fetch(`/appointments/get-appointments/${$("#selected_service").val()}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {

                        for(appointment of data) {
                            //$(`.appointment-${appointment.id} .state`).html(appointment.decorate_calendar_state)

                            $(`.appointment-${appointment.id}`).on("click", function(e) {
								fetch(`/appointments/${$(e.currentTarget).data('appointment_id')}`, {
									method: 'GET',
									mode: 'cors',
									cache: 'default',
								}).then(function(response) {
									if (response.ok) {
										response.json().then(function(data) {
                                           
											$(`.appointment-${$(e.currentTarget).data('appointment_id')} .indicator`).remove()
                                            
											$('#see_appointment_id').val(data.id)
											$("#see_service").val(`${data.service.name} (${data.service.duration} ${Pluralize( 'heure', data.service.duration )})`)
											$("#see_date").val(moment(data.date).locale("fr").format('dddd DD MMM YYYY'))
											$("#see_periode").val(`${data.time} à ${parseInt(data.time.replace('h:00','')) + data.service.duration}h:00`);
											$("#see_full_name").val(data.user.full_name)
											$("#see_phone").val(data.phone)
											$("#see_email").val(data.email);
											$("#see_car_number").val(data.car.car_number);
                                            $("#see_vin").val(`${data.car.vin}`);
											$("#see_km").val(data.km);
											$("#see_notes").val(data.notes);
											$("#see_description").val(data.description);
											$("#see_note_intern").val(data.note_intern);
											$("#see_state").val(data.state);

                                            if (data.state == "completed")  {
                                                $(".invoice_container").removeClass("d-none")
                                                $("#see_invoice").val(data.invoice)
                                            }
                                            else {
                                                $(".invoice_container").addClass("d-none")
                                                $("#see_invoice").val("")
                                            }

											if (data.service.slug == 'carrosserie') {
												$(".carrosserie").removeClass('visually-hidden')
											}
											else {
												$(".carrosserie").addClass('visually-hidden')
											}
     
                                            modal_appointment = new bootstrap.Modal(document.getElementById("modal-see_appointment"), {backdrop: 'static', keyboard: false});
											modal_appointment.show()
										})
									}
								})
							})

                            //$(`.${appointment.date} .state`).html(appointment.decorate_calendar_state)
                            /*$(`.${appointment.date}`).html("")

							let color
							let new_appointment = ''

							if (appointment.service.slug == 'entretien-périodique')
								color = 'primary'
							else if (appointment.service.slug == 'diagnostic-mécanique')
								color = 'success'
							else if (appointment.service.slug == 'carrosserie')
								color = 'secondary'

							if (appointment.new)
								new_appointment = `<span class="indicator position-absolute start-0 translate-middle p-2 bg-danger border border-light rounded-circle"><span class="visually-hidden"></span></span>`

							$(`.${appointment.date}`).append(`<a href='javascript:' class='one-appointment -appointment-${appointment.id} position-relative' data-appointment_id='${appointment.id}'>
								<span class="badge text-bg-${color}" style='border: 1px solid white;'>${appointment.service.name} <br>
									${appointment.time} - ${parseInt(appointment.time.replace('h:00','')) + appointment.service.duration}h:00<br>
									(${appointment.user.full_name})
								</span>${new_appointment}
								${appointment.decorate_calendar_state}
								
								</a>`)
							$(`.${appointment.date}`).attr('data-appointment_id', appointment.id)

							$(`.appointment-${appointment.id}`).on("click", function(e) {
								fetch(`/appointments/${$(e.currentTarget).data('appointment_id')}`, {
									method: 'GET',
									mode: 'cors',
									cache: 'default',
								}).then(function(response) {
									if (response.ok) {
										response.json().then(function(data) {
                                           
											$(`.appointment-${$(e.currentTarget).data('appointment_id')} .indicator`).remove()
                                            
											$('#see_appointment_id').val(data.id)
											$("#see_service").val(`${data.service.name} (${data.service.duration} ${Pluralize( 'heure', data.service.duration )})`)
											$("#see_date").val(moment(data.date).locale("fr").format('dddd DD MMM YYYY'))
											$("#see_periode").val(`${data.time} à ${parseInt(data.time.replace('h:00','')) + data.service.duration}h:00`);
											$("#see_full_name").val(data.user.full_name)
											$("#see_phone").val(data.phone)
											$("#see_email").val(data.email);
											$("#see_car_number").val(data.car.car_number);
											$("#see_km").val(data.km);
											$("#see_notes").val(data.notes);
											$("#see_description").val(data.description);
											$("#see_note_intern").val(data.note_intern);
											$("#see_state").val(data.state);

											if (data.service.slug == 'carrosserie') {
												$(".carrosserie").removeClass('visually-hidden')
											}
											else {
												$(".carrosserie").addClass('visually-hidden')
											}
     
                                            //modal_appointment = new bootstrap.Modal(document.getElementById("modal-see_appointment"), {backdrop: 'static', keyboard: false});
											//modal_appointment.show()
										})
									}
								})
							})*/
						}
                    })
                }
            })
        }

        function getService(service_id) {
            let workshop_id = $("#new-appoint-workshop").val()

            let url = `/workshops/services/${workshop_id}`

            if (service_id != null) {
                url = `/workshops/services/${workshop_id}/${service_id}`
            }

            fetch(`${url}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {

                        if (service_id != null) {
                            // let time = moment(new Date(`2024-09-02 ${data.times[0].morning_start.slice(0, -3)}`))
                            // let end = moment(new Date(`2024-09-02 ${data.times[0].morning_end.slice(0, -3)}`)).get('H')

                            $("#new-appoint-time").html(``)
                            
                            data.times.forEach(function(time) {
                                $("#new-appoint-time").append(`<option value='${time}'>${time}</option>`)
                            })

                            // data.
                            // $("#new-appoint-time").append(``)
                        }
                        else {
                            $("#new-appoint-service").html("")
                            data.services.forEach(function(service) {
                                $("#new-appoint-service").append(`<option value='${service.service.id}'>${service.service.name}</option>`)
                            })

                            getService($("#new-appoint-service option:selected").val())
                        }

                    })
                }
            })
        }
        
        //getService()
        
        $("#new-appoint-workshop").on("change", function() {
            workshop_id = $(this).val()
            
            fetch(`/workshops/periode/${workshop_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        
                        $("#new-appoint-time").html("")
                        for(var i=0 ; i< data.times.length ; i++) {
                            $("#new-appoint-time").append(`<option value="${data.times[i]}">${data.times[i]}</option>`)
                        }
                        
                        $("#new-appoint-service").html("")
                        jQuery.each(data.services, function (i, service) {
                            $("#new-appoint-service").append(`<option value="${service.service.id}">${service.service.name}</option>`)
                        })
                    })
                }
            })

        })

        $("#new-appoint-workshop").trigger("change")

        $("#filter-services").on("change", function() {
            table_all.ajax.reload()
            table_inprogress.ajax.reload()
            table_pending.ajax.reload()
            table_accepted.ajax.reload()
            table_canceled.ajax.reload()
            table_rejected.ajax.reload()
            table_completed.ajax.reload()
        })

        $("#filter-workshops").on("change", function() {
            table_all.ajax.reload()
            table_pending.ajax.reload()
            table_accepted.ajax.reload()
            table_canceled.ajax.reload()
            table_rejected.ajax.reload()
            table_completed.ajax.reload()
        })

        $("#filter-rate_services").on("change", function() {
            table_rates.ajax.reload()
        })


        $("#btn-save-appointment").on("click", function(event) {
            const forms = document.querySelectorAll('#modal-new_appointment .needs-validation')
            let isValid = true

            $(".error-appointement").addClass("visually-hidden")
            $(".error-appointement").text("")

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                $("#btn-cancel-appointment").attr("disabled", true)
                $("#btn-save-appointment").attr("disabled", true)
                $("#btn-save-appointment").children().first().removeClass("d-none")

                let formData = new FormData();
                formData.append('user_id', $("#new-appoint-client_id").val());
                formData.append('km', $("#new_km").val());
                formData.append('car_id', $("#new-appoint-matricule").val());
                formData.append('note_intern', $("#new_notes").val());
                formData.append('workshop_id', $("#new-appoint-workshop").val())
                formData.append('service_id', $("#new-appoint-service").val())
                formData.append('date', $("#new-appoint-date").val())
                formData.append('time', $("#new-appoint-time").val())
                formData.append('phone', $("#new-appoint-phone").val())
                formData.append('email', $("#new-appoint-email").val())

                fetch(`/appointments/create-appointment`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            if (data.info != null) {
                                $(".error-appointement").removeClass("visually-hidden")
                                $(".error-appointement").text(data.info)

                                $("#btn-cancel-appointment").attr("disabled", false)
                                $("#btn-save-appointment").attr("disabled", false)
                                $("#btn-save-appointment").children().first().addClass("d-none")
                            }
                            else {
                                // Swal.fire(
                                //     'Mis à jour !',
                                //     `Rendez-vous réservé!`,
                                //     'success'
                                // )

                                Swal.fire({
                                    title: "Mis à jour !",
                                    icon: "success",
                                    text: `Rendez-vous réservé!`,
                                    showDenyButton: false,
                                    showCancelButton: false,
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                        window.location.reload()
                                    }
                                });
                            }
                            
                        })
                    }
                })
            }
        })

        $("#btn-new-car").on("click", function() {
            modal_new_car = new bootstrap.Modal(document.getElementById("modal-new_car"), {backdrop: 'static', keyboard: false});
            modal_new_car.show()
        })

        $("#btn-cancel-new_car").on("click", function() {
            modal_new_car.hide()
            $("#new_car_number").val("")
            $("#new_car_model").val("")
        })

        $("#btn-save-new_car").on("click", function() {
            const forms = document.querySelectorAll('#form-new_car')
            let isValid = true

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if ($("#form-new_car").valid()) {
                $(".alert-zone").addClass("d-none")
                $(".alert-zone .alert").text("")

                $("#btn-cancel-new_car").attr("disabled", true)
                $(this).attr("disabled", true)
                $(this).children().first().removeClass("d-none")

                const formData = new FormData();
                formData.append('car_number', $("#new_car_number").val());
                formData.append('brand_model_id', $("#new_car_model option:selected").val());
                formData.append('model', $("#new_car_model option:selected").text());
                formData.append('vin', $("#new_vin").val());
                formData.append('user_id', $("#new-appoint-client_id").val());

                fetch(`/profile/create-car`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    
                    if (response.ok) {
                        response.json().then(function(data) {
                          
                            modal_new_car.hide();
                            $("#btn-cancel-new_car").attr("disabled", false)
                            $("#btn-save-new_car").attr("disabled", false)
                            $("#btn-save-new_car").children().first().addClass("d-none")

                            $("#new-appoint-matricule").append(`<option value='${data.id}'>${data.model.name}(${data.car_number})</option>`)
                            
                            $("#new-appoint-matricule").val(data.id);

                            $("#new_car_number").val("")
                            $("#new_car_model").val("")
                            $("#new_brand").val("")
                            $("#new_vin").val("")
                        })
                    }
                    else {
                        response.json().then(function(data) {
                            $(".new_car_alert-zone").removeClass("d-none")
                            $(".new_car_alert-zone .alert").text(data.error.join(', '))

                            $("#btn-cancel-new_car").attr("disabled", false)
                            $("#btn-save-new_car").attr("disabled", false)
                            $("#btn-save-new_car .spinner-border ").addClass("d-none")

                            $(".alert-zone").removeClass("d-none")
                            $(".alert-zone .alert").text(data.error.join(', '))
                        })
                    }
                })

            }
        })

        $("#new_brand").on("click", function() {
            $(".spinner-border").removeClass("d-none")

            let brand_id = $(this).val()
            
            fetch(`/clients/brand-models/${brand_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                response.json().then(function(data) {
                    $(".spinner-border").addClass("d-none")
      
                    $("#new_car_model").html("")
                    $("#new_car_model").append("<option selected disabled>Selectionner le modèle</option>")

                    $("#new_car_model").attr("disabled", false)

                    jQuery.each(data, function (i, val) {
                        $("#new_car_model").append(`<option value='${val.id}'>${val.name}</option>`)
                    });
                })
            });
        })

        function getAppointments() {

            fetch(`/appointments/get-appointment-dates/${$("#selected_service").val()}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {

                        let unavailableDates = data
                        $('#myCalendar').availabilityCalendar(unavailableDates, {locale: 'fr', defaultView: 'listMonth'})

                        // const calendar = new FullCalendar.Calendar(document.getElementById("myCalendar"), {
                        //     initialView: 'dayGridMonth',
                        //     themeSystem: 'bootstrap',
                        //     headerToolbar: { center: 'dayGridMonth,timeGridWeek' },
                        //     dayMaxEventRows: true, // for all non-TimeGrid views
                        //     views: {
                        //       timeGrid: {
                        //         dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
                        //       }
                        //     },
                        // });
                    })
                }
            })

            // unavailableDates = [
            //     {start: '2022-10-27', end: '2022-10-27'},
            //     {start: '2022-10-31', end: '2022-10-31'},
            //     {start: '2022-10-28', end: '2022-10-28'},
            //     {start: '2022-11-02', end: '2022-11-02'}
            // ];
        }

        if (document.getElementById("modal-new_appointment") != null) {
            document.getElementById("modal-new_appointment").addEventListener('hidden.bs.modal', function (event) {
                appointment_id = ""
                $("#list_clients").val("").trigger("change")
                $('#new_appointment_id').val("")
                $("#new_service").val("")
                $("#new_full_name").val("")
                $("#new_date").val("")
                $("#new_time").val("")
                $("#new_phone").val("")
                $("#new_email").val("");
                $("#new_car_number").val("")
                $("#new_km").val("")
                $("#new_notes").val("");
                $("#new_description").val("")
                $("#new_note_intern").val("")
                $('#new_note_client').val("")
            })
        }

        document.getElementById("modal-see_appointment").addEventListener('hidden.bs.modal', function (event) {
            appointment_id = ""
            $('#see_appointment_id').val("")
            $("#see_service").val("")
            $("#see_full_name").val("")
            $("#see_date").val("")
            $("#see_time").val("")
            $("#see_phone").val("")
            $("#see_email").val("");
            $("#see_car_number").val("")
            $("#see_km").val("")
            $("#see_notes").val("");
            $("#see_description").val("")
            $("#see_note_intern").val("")
            $('#see_note_client').val("")
            $("#see_state").val('pending')

            $("#modal-see_appointment .nav-link").removeClass('active')
            $("#modal-see_appointment .tab-pane").removeClass('show active')
            $("#modal-see_appointment #nav-details-tab").addClass('active')
            $("#modal-see_appointment #nav-details").addClass('show active')

            $("#rate_note").val("")
        })

        if ($("#modal-see_rates").length > 0) {
            document.getElementById("modal-see_rates").addEventListener('hidden.bs.modal', function (event) {
                service_id = ""
                //$('#list_client_rates').val("").trigger('change')
                table_client_rates.rows().clear().draw();
            })

            // document.getElementById("modal-see_rates").addEventListener('show.bs.modal', function (event) {
                
            // })
        }
            
  
        $("#toggle_calendar").on("click", function() {
            $("#myCalendar").removeClass("d-none")
            $("#myCalendarList").addClass("d-none")
        })
    }
})