document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "appointments") {
        let date;
        let time;
        let date_id;
        let method;
        let appointment_id;
        let new_appointment;
        let modal_appointment
        let modal_verify;
        let txt_button;
        let modal_new_car
        let selected_car_id = $("#selected_car_id").val();
        var map;
        let markers;
        let all_markers = new Array();

        $('#edit_phone').mask('(216) 99 999999');
        $('#new_phone').mask('(216) 99 999999');

        if (document.getElementById("modal-appointment") != null) {
            modal_appointment = new bootstrap.Modal(document.getElementById("modal-appointment"), {backdrop: 'static', keyboard: false});
        }
        
        $(".btn-next-step").on('click', function(e) {
            $('.step-1').addClass('visually-hidden')
            $('.step-2').removeClass('visually-hidden')

            $("#service_choosen").val($(this).data('service_slug'))
        })

        $(".btn-back-step").on('click', function(e) {
            $('.step-1').removeClass('visually-hidden')
            $('.step-2').addClass('visually-hidden')
        })
        
        $(".btn-back-step").on('click', function(e) {
            $('.step-1').removeClass('visually-hidden')
            $('.step-2').addClass('visually-hidden')
            $('.workshop-container').addClass('visually-hidden')
            $('.workshop-container').html('')
        })
        
        $("#workshop-governorate").on("change", function() {
            $(".workshop-container").html("")
            $('.workshop-container').removeClass('visually-hidden')
            if ($(this).val() != '') {
                getWorkshops($(this).val())
            }
        })

        // create new appointement
        $(".dispo").on("click", function(e) {
            $("#btn-close-appointment").addClass("d-none")
            $("#btn-return-appointment").addClass("d-none")
            $("#btn-cancel-appointment").removeClass("d-none")

            createAppointment(e.currentTarget)
        })

        $("#btn-cancel-appointment").on("click", function() {
            if (method === 'new') {

                Swal.fire({
                    title: 'Annuler?',
                    text: "Voulez-vous annuler ce rendez-vous?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Annuler',
                    confirmButtonText: 'Oui',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showLoaderOnConfirm: true,
                    
                    preConfirm: () => {
                        
                        JsLoadingOverlay.show({
                            'overlayBackgroundColor': '#666666',
                            'overlayOpacity': 0.6,
                            'spinnerIcon': 'ball-atom',
                            'spinnerColor': '#000',
                            'spinnerSize': '2x',
                            'overlayIDName': 'overlay',
                            'spinnerIDName': 'spinner',
                            'spinnerZIndex':99999,
                            'overlayZIndex':99998,
                            'containerID': "table-dates"
                        });
                        
                        return fetch(`/appointments/${appointment_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        }).then(function(response) {
                            if (response.ok) {
                                JsLoadingOverlay.hide()
                                
                                $(`td[data-date_id=${date_id}] > div`).addClass("dispo")
                                $(`td[data-date_id=${date_id}] > div`).html("<i class='fa-solid fa-plus'></i>")
                                appointment_id = null;

                                modal_appointment.hide()
                            }
                        })
                    }
                }).then((result) => {})
            }
            else {
                appointment_id = null;

                modal_appointment.hide()
            }

            $(".error-appointement").addClass("visually-hidden")
            $(".error-appointement").text("")
            //$("#appointment_matricule").val('');
        })

        $("#btn-cancel-edit_appointment").on("click", function() {

            modal_appointment.hide()
            
            $("#edit_notes, #edit_phone, #edit_email, #edit_km").val("")
            $("#edit_car_number").val("")

            $(`.rdv > div[data-appointment_id='${appointment_id}']`).html(txt_button)

            appointment_id = null;
        })
        
        $("#btn-new-car").on("click", function() {
            modal_new_car = new bootstrap.Modal(document.getElementById("modal-new_car"), {backdrop: 'static', keyboard: false});
            modal_new_car.show()
        })
        
        $("#btn-cancel-new_car").on("click", function() {
            modal_new_car.hide()
            $("#new_car_number").val("")
            $("#new_car_model").val("")
        })

        if ($("#service_slug").val() == "entretien-périodique" && $("#active_change_duration").val() == "true") {
            if ($("#km").val() == "") {
                //if ($("#cars_dipo").val() != "" || $("#rdv_exists").val() =="false") {
                    modal_car = new bootstrap.Modal(document.getElementById("modal-car"), {backdrop: 'static', keyboard: false});

                    $("#btn-cancel-appointment").addClass("d-none")
                    $("#btn-return-appointment").removeClass("d-none")
                    $("#btn-close-appointment").addClass("d-none")

                    modal_car.show()
                //}
            }
        }

        $("#car-brand").on("change", function() {

            $(".spinner-border").removeClass("d-none")

            brand_id = $(this).val()
            
            fetch(`/clients/brand-models/${brand_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                response.json().then(function(data) {
                    $(".spinner-border").addClass("d-none")
      
                    $("#car-model").html("")
                    $("#car-model").append("<option selected disabled>Selectionner le modèle</option>")

                    $("#car-model").attr("disabled", false)

                    jQuery.each(data, function (i, val) {
                        $("#car-model").append(`<option value='${val.id}'>${val.name}</option>`)
                    });
                })
            });
        })

        $("#btn-save-new_car").on("click", function() {
            const forms = document.querySelectorAll('#form-new_car')
            let isValid = true

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if ($("#form-new_car").valid()) {
                $(".alert-zone").addClass("d-none")
                $(".alert-zone .alert").text("")

                $("#btn-cancel-new_car").attr("disabled", true)
                $(this).attr("disabled", true)
                $(this).children().first().removeClass("d-none")

                const formData = new FormData();
                formData.append('brand_model_id', $("#car-model").val());
                formData.append('model', $("#car-model option:selected").text());
                formData.append('car_number', $("#car-car_number").val());
                formData.append('vin', $("#car-vin").val());

                fetch(`/profile/create-car`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    
                    if (response.ok) {
                        response.json().then(function(data) {
                          
                            modal_new_car.hide();
                            
                            $("#btn-cancel-new_car").attr("disabled", false)
                            $("#btn-save-new_car").attr("disabled", false)
                            $("#btn-save-new_car").children().first().addClass("d-none")

                            $("#appointment_matricule").append(`<option value='${data.id}'>${data.brand_model.brand.brand} ${data.model.name}(${data.car_number})</option>`)
                            $("#edit_car_number").append(`<option value='${data.id}'>${data.brand_model.brand.brand} ${data.model.name} (${data.car_number})</option>`)
                            
                            $("#appointment_matricule").val(data.id);

                            $("#car-car_number").val("")
                            $("#car-vin").val("")
                            
                        })
                    }
                    else {
                        response.json().then(function(data) {
                            $(".new_car_alert-zone").removeClass("d-none")
                            $(".new_car_alert-zone .alert").text(data.error.join(', '))

                            $("#btn-cancel-new_car").attr("disabled", false)
                            $("#btn-save-new_car").attr("disabled", false)
                            $("#btn-save-new_car .spinner-border ").addClass("d-none")

                            $(".alert-zone").removeClass("d-none")
                            $(".alert-zone .alert").text(data.error.join(', '))
                        })
                    }
                })

            }
        })

        $(".btn-change-km").on("click", function() {
            modal_car = new bootstrap.Modal(document.getElementById("modal-car"), {backdrop: 'static', keyboard: false});

            $("#btn-cancel-appointment").addClass("d-none")
            $("#btn-return-appointment").addClass("d-none")
            $("#btn-close-appointment").removeClass("d-none")

            if ($(".rdv").length > 0) {
                $("#new_km").attr("disabled", true)
            }
            else {
                $("#new_km").attr("disabled", false)
            }

            $("#appointment_matricule2").attr("disabled", false)

            $("#change_km").val("true")

            modal_car.show()
        })

        $("#appointment_matricule2").on("change", function() {
            if ($("#service_slug").val() == "entretien-périodique") {
                fetch(`/appointments/check_km/${$(this).val()}`, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'default',
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {

                            if ($("#new_km option:selected").val() < data.km) {
                                $("#new_km option[value='']").prop("selected", true)
                            }

                            $("#new_km2 option").attr("disabled", false)
                            $('#new_km2').attr("disabled", false)
                            
                            $("#new_km2 option").each(function() {
                                if (data.appointment != null) {
                                    if (data.appointment.state == "pending" || data.appointment.state == "accepted" || data.appointment.state == "in_progress") {
                                        $('#new_km2').attr("disabled", false)
                                        $(`#new_km2 option[value='${data.appointment.km}']`).prop("selected", true)
                                        $('#new_km2').attr("disabled", true)
                                    }
                                }

                                if (this.value <= data.km) {
                                    $(this).attr("disabled", true)
                                }
                                
                            });
                        })
                    }
                })
            }
        })

        $("#appointment-car-selected").on("change", function() {
            $(".appointment-service-available").html(`<img src='/assets/loader2' class='loader-workshops text-center' style='width:100px'>`)
            
            let brand_id = $("#appointment-car-selected option:selected").val()

            $(".step-2").addClass("d-none")
            $(".workshop-container").html("")
            $(".select-gov").addClass("d-none")

            fetch(`/workshops/brand-services/${brand_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $(".appointment-service-available").html('')

                        if (data.services.length == 0 ) {
                            let brand = $("#appointment-car-selected option:selected").attr("data-brand")
                            $(".appointment-service-available").html(`<div class="m-3"><div class='alert alert-warning '>Aucun atelier n'est disponible pour <b>${brand}</b> pour le moment.</div></div>`)
                        }

                        data.services.forEach(function(service) {
                            
                            let img = ""
                            if (service.image.url != null)
                                img = `<div><img src="${service.image.url}" class="m-2" width="128"></div>`

                            $(".appointment-service-available").append(`
                                <div class='col-lg-4 col-md-5 mb-2 col-sm-6 '>
                                    <div class='card3 service-container' style="">
                                        <h5>${service.name.toUpperCase()}</h5>
                                        <hr class="hr2">
                                        <p>${service.description}</p>
                                        <a href="javascript:void(0)" class="select-service" data-service='${service.name.toUpperCase()}' data-service_id='${service.id}' data-service_slug='${service.slug}'>
                                            ${img}
                                            <button type="button" class="btn btn-primary btn-next-step mt-3 mb-3" data-service_slug='${service.slug}'>Choisir ce service <i class="fa-solid fa-calendar"></i></button></a>
                                    </div>
                                </div>
                            `)
                        })

                        $(".step-2").removeClass("d-none")
                    })
                }
                else {
                    $('.loader-workshops').addClass('visually-hidden')
                    response.json().then(function(data) {
                        
                    })
                }
            })
        })

        $(document).on("click", ".select-service", function(e) {
            let service = $(this).attr("data-service")
            let service_slug = $(this).attr("data-service_slug")
            let service_id = $(this).attr("data-service_id")
            let brand_id = $("#appointment-car-selected option:selected").val()
            
            $(".appointment-service-available").html('')
            
            fetch(`/workshops/governorates/${service_id}/${brand_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        
                        $(".selected-service").text(service)
                        $(".selected-service").attr("data-service_slug", service_slug)
                        $(".selected-service").attr("data-service_id", service_id)

                        $("#workshop-governorate").html('<option selected disabled>Gouvernorat</option>')

                        data.forEach(function(gov) {
                            $("#workshop-governorate").append(`
                                <option value='${gov}'>${gov}</option>
                            `)
                        })

                        //service_map(service_slug)

                        $(".select-gov").removeClass("d-none")
                    })
                }
            })
        })

        // choose vehicule
        $("#btn-save-appointment_car").on('click', function(event) {

            const forms = document.querySelectorAll('#modal-car .needs-validation')
            let isValid = true

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                if ($("#active_change_duration").val() == "true") {
                    if ($("#service_slug").val() == "entretien-périodique" && $("#choosen_time").val() == "false") {

                        $("#btn-return-appointment").attr("disabled", true)
                        $("#btn-save-appointment_car").attr("disabled", true)
                        $("#btn-save-appointment_car").children().first().removeClass("d-none")
                        
                        var url = new URL(window.location.href);
    
                        url.searchParams.append('km', $("#new_km2 option:selected").val());
                        url.searchParams.append('car', $("#appointment_matricule2").val())
    
                        window.location = url
                    }
                    else if ($("#service_slug").val() == "entretien-périodique" && $("#choosen_time").val() == "false" && $("#change_km").val() == "true") { 
    
                    }
                    else {
                        $("#btn-cancel-appointment").attr("disabled", true)
                        $("#btn-save-appointment_car").attr("disabled", true)
                        $("#btn-save-appointment_car").children().first().removeClass("d-none")
        
                        const formData = new FormData();
                        formData.append('workshop_id', $("#workshop_id").val());
                        formData.append('service_id', $("#new-appoint-service").val() != null ? $("#new-appoint-service").val() : $("#service_id").val());
                        formData.append('phone', $("input[name='phone']").val());
                        formData.append('email', $("input[name='email']").val());
                        formData.append('car_id', $("select[name='car_number'] option:selected").val());
                        formData.append('km', $("select[name='km'] option:selected").val());
                        formData.append('notes', $("textarea[name='notes']").val());
                        formData.append('description', $("textarea[name='description']").text());
                        formData.append('date', date);
                        formData.append('time', time);

                        $("input, select, button, textarea").attr("disabled", true)
                        $(this).html("<img src='/assets/loader' width='24'>")

                        fetch(`/appointments/${appointment_id}`, {
                            method: 'PUT',
                            mode: 'cors',
                            cache: 'default',
                            body: formData,
                        }).then(function(response) {
                            $("input, select, button, textarea").attr("disabled", false)
                            $("#btn-save-appointment_car").html("Terminer")
                            
                            if (response.ok) {
                                response.json().then(function(data) {
                                    
                                    let val = parseInt($("#total_appointments").val()) +1
                                    $("#total_appointments").val(val)

                                    let service_duration = $("#service_duration").val();

                                    new_appointment = data

                                    $(`td[data-date_id=${date_id}]`).addClass(`day_reserved-${val}`)
                                    $(`td[data-date_id=${date_id}] > div`).remove()
                                    $(`td[data-date_id=${date_id}]`).append(`
                                        <div class='rdv rdv-time'><div class='badge text-bg-warning' data-appointment_id='${new_appointment.id}'>En attente de confirmation</div></div>
                                    `)
                                    
                                    $(`td[data-date_id=${date_id}] .rdv > div`).on('click', function(e) {
                                        seeAppointment(e.currentTarget)
                                    })
                                    
                                    $(`#appointment_matricule2 option[value='${data.car.id}']`).attr('disabled', true)

                                    $("#new_km2").val("");
                                    $("#new_notes").val("");
                                    $("#appointment_matricule2").val("")
                                    $("#new_description").val("")

                                    $("input[name='code']").val("")
                                    
                                    new_appointment = null
                                     
                                    let hour = date_id.slice(date_id.indexOf('-')+1)
                                    let index_date = $(`td[data-date_id=${date_id}]`).data("index_date")
                
                                    $(".reserved_date").html(`<h6 style="color:#0cc703" class='mt-1'>Rendez-vous réservé pour le ${moment(data.date).format('dddd D MMMM')} à ${data.time}</h6>`)
                                    
                                    Swal.fire(
                                        'Merci !',
                                        `Votre rendez-vous de "${data.brand.brand} ${data.car.model.name} (${data.car.car_number})" est réservé pour le ${moment(data.date).format('dddd D MMMM YYYY')} à ${data.time}`,
                                        'success'
                                    )
                                    modal_appointment.hide();
                                    
                                    $("#btn-cancel-appointment").attr("disabled", false)
                                    $("#btn-save-appointment_car").attr("disabled", false)
                                    $("#btn-save-appointment_car").children().first().addClass("d-none")

                                    $(".btn-change-km").parent().remove()
                                })
                            }
                            else {
                                response.json().then(function(data) {
                                    $(".error-appointement").removeClass("visually-hidden")
                                    $(".error-appointement").text(data.error)
                                })
                            }
                        })
                    }
                }
            }
        })

        // update appointement details
        $("#btn-save-appointment").on('click', function(event) {
            const forms = document.querySelectorAll('#modal-appointment .needs-validation')
            let isValid = true

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                $("#btn-cancel-appointment").attr("disabled", true)
                $(this).attr("disabled", true)
                $(this).children().first().removeClass("d-none")

                const formData = new FormData();
                formData.append('workshop_id', $("#workshop_id").val());
                formData.append('service_id', $("#new-appoint-service").val() != null ? $("#new-appoint-service").val() : $("#service_id").val());
                formData.append('phone', $("input[name='phone']").val());
                formData.append('email', $("input[name='email']").val());
                formData.append('car_id', selected_car_id);
                //formData.append('car_id', $("select[name='car_number'] option:selected").val());
                formData.append('km', $("select[name='km'] option:selected").val());
                formData.append('notes', $("textarea[name='notes']").val());
                formData.append('description', $("textarea[name='description']").text());
                formData.append('date', date);
                formData.append('time', time);

                $("input, select, button, textarea").attr("disabled", true)
                $(this).html("<img src='/assets/loader' width='24'>")

                fetch(`/appointments/${appointment_id}`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    $("input, select, button, textarea").attr("disabled", false)
                    $("#btn-save-appointment").html("Terminer")
                    
                    if (response.ok) {
                        response.json().then(function(data) {
                            
                            let val = parseInt($("#total_appointments").val()) +1
                            $("#total_appointments").val(val)

                            let service_duration = $("#service_duration").val();

                            new_appointment = data
                            
                            let status = "Réservé"
                            let status_bg = "success"

                            if (data.state == "pending") {
                                status = "En attente"
                                status_bg = "warning"
                            }
                            
                            $(`td[data-date_id=${date_id}]`).addClass(`day_reserved-${val}`)
                            $(`td[data-date_id=${date_id}] > div`).remove()
                            $(`td[data-date_id=${date_id}]`).append(`
                                <div class='rdv rdv-time'><div class='badge text-bg-${status_bg}' data-appointment_id='${new_appointment.id}'>${status}</div></div>
                            `)
                            
                            $(`td[data-date_id=${date_id}] .rdv > div`).on('click', function(e) {
                                seeAppointment(e.currentTarget)
                            })
                            
                            $(`#appointment_matricule option[value='${data.car.id}']`).attr('disabled', true)
                            
                            $("#new_km").val("");
                            $("#new_notes").val("");
                            $("#appointment_matricule").val("")
                            $("#new_description").val("")

                            $("input[name='code']").val("")
                            
                            new_appointment = null
                            
                            let hour = date_id.slice(date_id.indexOf('-')+1)
                            let index_date = $(`td[data-date_id=${date_id}]`).data("index_date")
        
                            // set duration by removing hours from calendar
                            /*for (var i=0 ; i < service_duration ; i++) {
                                hour++
                                $(`td[data-date_id=${index_date}-${hour}]`).find('.dispo').remove()
                                $(`td[data-date_id=${index_date}-${hour}]`).addClass(`day_reserved-${val}`)
                            }

                            hour = date_id.slice(date_id.indexOf('-')+1)
                            for (var i=0 ; i < service_duration ; i++) {
                                hour--
                                $(`td[data-date_id=${index_date}-${hour}]`).find('.dispo').remove()
                            }*/

                            //let elem = $(`td[data-date_id=${date_id}]`)
                            //elem.siblings().eq(elem.index()).find('.dispo').remove()

                            //$(`td[data-date_id=${date_id}]`).next().find('.dispo').remove()
                            //$(`td[data-date_id=${date_id}]`).next().find('.dispo').remove()
                            //$(`td[data-date_id=${date_id}]`).next("div").next("div").remove()

                            Swal.fire(
                                'Merci !',
                                `Votre rendez-vous de "${data.brand.brand} ${data.car.model.name} (${data.car.car_number})" est réservé pour le ${moment(data.date).format('dddd D MMMM YYYY')} à ${data.time}`,
                                'success'
                            )
                            modal_appointment.hide();
                            
                            $("#btn-cancel-appointment").attr("disabled", false)
                            $("#btn-save-appointment").attr("disabled", false)
                            $("#btn-save-appointment").children().first().addClass("d-none")

                            //merge()

                            //modal_verify = new bootstrap.Modal(document.getElementById("modal-validation_code"), {backdrop: 'static', keyboard: false});
                            
                            //modal_verify.show();

                            //$("input[name='code']").focus()
                        })
                    }
                    else {
                        response.json().then(function(data) {
                            if (data.invisible != null) {
                                if (data.invisible == true) {
                                    window.location = "/appointments/"
                                }
                            }
                            else {
                                $(".error-appointement").removeClass("visually-hidden")
                                $(".error-appointement").text(data.error)
                            }
                        })
                    }
                })
            }
        })

        // verify code
        $('#btn-varify_code').on('click', function(event) {
            
            const forms = document.querySelectorAll('#modal-validation_code .needs-validation')
            let isValid = true
            
            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {
                $("input, button").attr("disabled", true)
                $(this).html("<img src='/assets/loader' width='24'>")

                const formData = new FormData();
                formData.append('code', $("input[name='code']").val());
                
                fetch(`/appointments/verify/${new_appointment.id}`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    $("input, button").attr("disabled", false)
                    $("#btn-varify_code").html("Verify")
                    
                    if (response.ok) {
                        response.json().then(function(data) {

                            $(`td[data-date_id=${date_id}] > div`).remove()
                            $(`td[data-date_id=${date_id}]`).append(`
                                <div class='rdv rdv-time'><div class='badge text-bg-success' data-appointment_id='${new_appointment.id}'>Réservé</div></div>
                            `)
                            
                            $(`td[data-date_id=${date_id}] .rdv > div`).on('click', function(e) {
                                seeAppointment(e.currentTarget)
                            })
                            
                            $(`#appointment_matricule option[value='${data.car.id}']`).attr('disabled', true)

                            $("#new_km").val("");
                            $("#new_notes").val("");
                            $("#appointment_matricule").val("")
                            $("#new_description").val("")

                            $("input[name='code']").val("")
                            
                            new_appointment = null

                            modal_verify.hide();
                            modal_appointment.hide();

                            Swal.fire(
                                'Merci !',
                                `Votre réservation de "${data.brand.name} ${data.car.model.name}(${data.car.car_number})" est complète!`,
                                'success'
                            )
                        })
                    }
                    else {
                        response.json().then(function(data) {
                            $(".alert-verification").removeClass('visually-hidden')
                            $(".alert-verification").text(data.error)
                        })
                    }
                })
            }
        })

        // see an appointent
        $('#table-dates').on('click', '.rdv > div', function(e) {
            
            txt_button = $(this).html()
            seeAppointment(e.currentTarget)
        })

        $(document).on('click', "#btn-delete_appointment", function() {

            Swal.fire({
                title: 'Annuler?',
                text: "Voulez-vous vraiment annuler ce rendez-vous?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/appointments/${appointment_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                let service_duration = $("#service_duration").val();
                                
                                $(`td[data-date_id=${date_id}]`).html("<div class='dispo active'><i class='fa-solid fa-plus'></i></div>")

                                $(`td[data-date_id=${date_id}] > .dispo`).on("click", function(e) {
                                    createAppointment(e.currentTarget)
                                })

                                $(`#appointment_matricule option[value='${data.car.id}']`).attr('disabled', false)

                                let hour = date_id.slice(date_id.indexOf('-')+1)
                                let index_date = $(`td[data-date_id=${date_id}]`).data("index_date")

                                
                                appointment_id = null;

                                modal_appointment.hide()

                                window.location.reload()
                            })
                        }
                        else {
                            response.json().then(function(data) {
                                modal_appointment.hide()
                                Swal.fire({
                                    icon: "error",
                                    title: "Annulation impossible",
                                    text: data.error,
                                    preConfirm: () => {
                                        window.location.reload()
                                    }
                                });

                           
                            })
                        }
                    })
                }
            }).then((result) => {})
        })
        
        const buttonRight = document.getElementById('slideRight');
        const buttonLeft = document.getElementById('slideLeft');

        if (buttonRight != null) {
            buttonRight.onclick = function () {
                document.getElementById('date-appoint-container').scrollLeft += 20;
            };

            buttonLeft.onclick = function () {
                document.getElementById('date-appoint-container').scrollLeft -= 20; 
            };
        }
        
        //$("#slideRight").height($("#table-dates").height())

        $('.date-appoint-container').scroll(function() {
            
            var currY = $(this).scrollLeft();
            var postHeight = $(this).width();
            var scrollHeight = $('#table-dates').width();
            var scrollPercent = (currY / (scrollHeight - postHeight)) * 100;

            if (scrollPercent <= 0) {
                $('#slideLeft').css("visibility", "hidden")
            }
            else {
                $('#slideLeft').css("visibility", "visible")
            }

            if (scrollPercent >= 100) {
                $('#slideRight').css("visibility", "hidden")
            }
            else {
                $('#slideRight').css("visibility", "visible")
            }
        });
        
        if ($('.date-appoint-container').width() == $('#table-dates').width()) {
            $("#slideRight").css("visibility", "hidden")
        }

        $("#car-vin").on("change", function() {
            let vin = $(this).val()

            checkVin(vin)
        })

        function checkVin(vin) {
            if (isNaN(vin.slice(-5, 17)) == true) {
                $(".alert-zone").removeClass("d-none")
                $(".alert-zone .alert").text("Numéro de châssis est invalide!")

                return false
            }
            else {
                $(".alert-zone").addClass("d-none")
                $(".alert-zone .alert").text("")

                return true
            }
        }

        // *** FUNCTIONS 
        function getWorkshops(gov) {
            //$('.workshop-container').html('')
            $('.loader-workshops').removeClass('visually-hidden')

            fetch(`/workshops/search/${gov}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $('.loader-workshops').addClass('visually-hidden')
                        for (val of data) {
                            $('.workshop-container').append(`
                                <div class="col-lg-5 col-md-5 col-sm-6 ">
                                    <div class='card3 workshop-content m-0'>
                                        <b class='workshop-name'>${val.name}</b>
                                        <hr class='mb-2 mt-1'>
                                        <p class='workshop-address'><span style="color:#fff"><i class="fa-solid fa-location-dot"></i></span>
                                        <span style=''>${val.address}</span></p>
                                        <a href="/appointments/new?service=${$(".selected-service").attr("data-service_slug")}&workshop=${val.id}&car=${$('#appointment-car-selected option:selected').attr('data-car_id')}"><button class='btn btn-primary'><i class="fa-solid fa-shop"></i> Choisir cet atelier</button></a>
                                    </div>
                                </div>
                            `)
                        }
                    })
                }
                else {
                    $('.loader-workshops').addClass('visually-hidden')
                    response.json().then(function(data) {
                        
                    })
                }
            })
        }

        function createAppointment(date_dispo) {
            const formData = new FormData();
            const selected_date = date_dispo

            method = 'new'
            date = $(date_dispo).parent().data('date')
            time = $(date_dispo).parent().data('time')
            date_id = $(date_dispo).parent().data('date_id')

            $(date_dispo).html("<img src='/assets/loader2' width='24'>")
            $(date_dispo).removeClass("dispo")

            formData.append('date', date);
            formData.append('time', time);
            formData.append('workshop_id', $("#workshop_id").val());
            formData.append('service_id', $("#service_id").val());
            formData.append('car', selected_car_id);

            $(".error-new_appointement").addClass("visually-hidden")
            $(".error-new_appointement").text("")

            $(".row-notes").removeClass("d-none")

            if ($("#active_change_duration").val() == "true") {
                selected_car_id = $("#car").val()
                $("#appointment_matricule").val($("#car").val())
                $("#appointment_matricule").attr("disabled", true)
            }

            fetch(`/appointments`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        if (data.type == "min_time_prior_booking") {

                            $(date_dispo).html("")
                            $(date_dispo).removeClass("dispo")

                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: data.error,
                            });

                            return
                        }

                        if (data.type == "attempt_limit") {

                            $(date_dispo).html("<i class='fa-solid fa-plus'></i>")
                            $(date_dispo).addClass("dispo")

                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: data.error,
                                footer: `Il reste ${data.remains} minute(s)!`
                            });

                            return
                        }

                        
                        modal_appointment = new bootstrap.Modal(document.getElementById("modal-appointment"), {backdrop: 'static', keyboard: false});

                        appointment_id = data.appointment.id

                        const details_text = `Vous avez choisi de réserver un rendez-vous pour ${data.appointment.service.name} à <b>${time}</b> le <b>${moment(date).format('dddd D MMMM YYYY')}</b>.<br>
                                              Veuillez insérer vos coordonnées dans le formulaire ci-dessous pour enregistrer la réservation.`
                        
                        $(".details-text").html(details_text)
                  
                        $(`#appointment_matricule option`).attr("disabled", false)

                        if (data.appointment_date.length > 0) {
                            data.appointment_date.forEach(function(appointment) {
                                if (appointment.state == "accepted" || appointment.state == "pending" || appointment.state == "in_progress"){
                                    $(`#appointment_matricule option[value='${appointment.car_id}']`).attr("disabled", true)
                                }
                            })
                            
                        }
                     
                        modal_appointment.show();
                    })
                }
                else {
                    response.json().then(function(data) {
                        if (data.invisible != null) {
                            if (data.invisible == true) {
                                window.location = "/appointments/"
                            }
                        }
                        else {
                            $(".error-new_appointement").removeClass("visually-hidden")
                            $(".error-new_appointement").text(data.error)
                            $(selected_date).remove()
                        }
                    })
                }
            })
        }

        function seeAppointment(appointment) {
            method = 'edit'

            $(appointment).html("<img src='/assets/loader' width='20'>")
            date = $(appointment).parent().parent().parent().data('date')
            time = $(appointment).parent().parent().data('time')
            date_id = $(appointment).parent().parent().data('date_id')

            appointment_id = $(appointment).data('appointment_id')

            modal_appointment = new bootstrap.Modal(document.getElementById("modal-edit_appointment"), {backdrop: 'static', keyboard: false});

            
            fetch(`/appointments/${appointment_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $("#edit_phone").val(data.phone)
                        $("#edit_email").val(data.email);
                        $("#edit_car_number").val(data.car.id);
                        $("#edit_km").val(data.km);
                        $("#edit_notes").val(data.notes);
                        $("#edit_note_client").removeClass("hidden");
                        $("#edit_note_client").val(data.note_client);
                        $("#edit_description").val(data.description);

                        const details_text = `Vous avez choisi de réserver un rendez-vous pour ${data.service.name} à <b>${time}</b> le <b>${moment(date).format('dddd D MMMM YYYY')}</b>.<br>
                                                Veuillez insérer vos coordonnées dans le formulaire ci-dessous pour enregistrer la réservation.`

                        $(".details-text").html(details_text)

                        if (data.state == "accepted" || data.state == "completed" || data.state == "canceled" || data.state == "rejected" || data.state == "missed" || data.state == "in_progress") {
                            $("#btn-delete_appointment").remove()
                        }
                        else {
                            if ($("#btn-delete_appointment").length == 0 ) {
                                $("#modal-edit_appointment .modal-footer").append(`
                                    <button type="button" id="btn-delete_appointment" class="btn btn-danger"><i class="fa-solid fa-xmark"></i> Annuler ce rendez-vous</button>
                                `)
                            }
                        }
                        
                        $("#edit_car_number").attr("disabled", true)

                        modal_appointment.show()
                    })
                }
            })
        }

        $("#split").on("click", function() {
            split(".day_reserved-1")
        })

        function merge() {
            var table = new Table(document.getElementById('table-dates'));
            var total_appointments = $("#total_appointments").val();

            for(var i=1 ; i<=total_appointments ; i++) {
                var cells = table.element.querySelectorAll(`.day_reserved-${i}`);

                table.merge(cells, function(colspan, rowspan, kept, removed){
                    var content = kept.innerHTML;
                    for(var i=0;i<removed.length;i++){
                        content += " " + removed[i].innerHTML;
                    }
                    kept.innerHTML = content;
                })
            }
        }

        function split(class_name) {
            var table = new Table(document.getElementById('table-dates'));
           // var total_appointments = $("#total_appointments").val(class_name);

            var cells = table.element.querySelectorAll(class_name);
            table.split(cells, function(newcell){
                newcell.innerHTML = "<div class='dispo active'><i class='fa-solid fa-plus'></i></div>";
               // newcell.classList.add("selected");
            })
            
        }

        function service_map(service_slug) {
            fetch(`/workshops/locations/${service_slug}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                response.json().then(function(data) {
                    $(".spinner-border").addClass("d-none")
              
                    if (map == null) {
                        var element = document.getElementById('osm-map');
                        element.style = 'height:500px;'; 

                        map = L.map(element);
                            L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            }).addTo(map);

                             // Target's GPS coordinates.
                        var target = L.latLng('36.8082', '10.2365');
                        
                        map.setView(target, 9);

                  
                    }
                    else {

                        for(i=0;i<all_markers.length;i++) {
                            map.removeLayer(all_markers[i]);
                        }
                    }

                    markers = L.markerClusterGroup(
                        {
                            spiderfyShapePositions: function(count, centerPt) {
                                var distanceFromCenter = 35,
                                    markerDistance = 45,
                                    lineLength = markerDistance * (count - 1),
                                    lineStart = centerPt.y - lineLength / 2,
                                    res = [],
                                    i;
                
                                res.length = count;
                
                                for (i = count - 1; i >= 0; i--) {
                                    res[i] = new Point(centerPt.x + distanceFromCenter, lineStart + markerDistance * i);
                                }
                
                                return res;
                            }
                        }
                    );
        

                    data.forEach(function(elem) {
                        var marker = L.marker([elem.workshop.lat, elem.workshop.lng]).bindPopup(`
                            <div class='mb-1'>
                                <b style="font-size: 18px;">${elem.workshop.name}</b><br>${elem.workshop.address}
                            </div>
                            <br>
                            <button class='form-control btn btn-primary btn-choose-workshop'>CHOISIS CET ATELIER</button>`, 
                            {closeOnEscapeKey: false, closeButton: false, autoClose:false, closeOnClick: false, closePopupOnClick: false}) //.openPopup();
                        
                            all_markers.push(marker);
                            markers.addLayer(marker)
                    })

                    map.addLayer(markers);
                    // var marker = L.marker([36.9104, 10.0813]).addTo(map).bindPopup('A pretty CSS popup.<br> Easily customizable.', {autoClose:false}).openPopup();

                })
            });
        
        }

        merge()
    }
})