document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "registrations" || $("body").data("controller") == "clients" || $("body").data("controller") == "sessions" ) {

        $('#btn-register').on("click", function(e) {
            e.preventDefault()
            
            const forms = document.querySelectorAll('#new_user')
            let isValid = false
        
            $("#new_user").validate()
            
            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (checkVin($("#vin").val()) == false) {
                return false
            }

            if ($("#new_user").valid()) {
                $(".car_alert-zone").addClass("d-none")
                $(".car_alert-zone .alert").text("")
                $(".errors").html("")

                $("#btn-register").attr("disabled", true)
                $(".spinner-register").removeClass("d-none")

                // check car validity
                let formData = new FormData();
                formData.append('brand_model_id', $("#car_model").val());
                formData.append('car_number', $("#car_number").val());
                formData.append('vin]', $("#vin").val());
               
                $("#btn-save-profil").attr("disabled", true)
                $("#btn-save-profil .spinner-border ").removeClass("d-none")

                fetch(`/clients/check-car`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {

                            if (($("#user_password").val() != $("#user_password_confirmation").val()) || $("#user_password").val().length < 8 ) {
                                $(".errors").html(`
                                    <div id="error_explanation">
                                        <div class="alert alert-danger text-sm-start ">
                                            <ul>
                                                <li>Le Mot de passe est incorrecte!</li>
                                                <li>Le Mot de passe doit contenir au moins 8 caractères!</li>
                                            </ul>
                                        </div>
                                    </div>
                                `)

                                $("#btn-register").attr("disabled", false)
                                $(".spinner-register").addClass("d-none")

                                return
                            }
                            else {
                                document.getElementById("new_user").submit()
                            }
                        })
                    }
                    else {
                        response.json().then(function(data) {
                            $("#btn-register").attr("disabled", false)
                            $(".spinner-register").addClass("d-none")

                            $(".car_alert-zone").removeClass("d-none")
                            $(".car_alert-zone .alert").text(data.error)

                            return
                        })
                    }
                });
            }

        })
        
        $('#user_phone').mask('(216) 99 999999');

        $('#user_login_phone').mask('(216) 99 999999');


        $("#user_login_phone").on("click", function() {
            let tel = document.getElementById("user_login_phone");
            tel.focus()
            tel.setSelectionRange(6, 6);
        })
      
        $("#user_phone").on("click", function() {
            let tel = document.getElementById("user_phone");
            tel.focus()
            tel.setSelectionRange(6, 6);
        })

        $("#vin").on("change", function() {
            let vin = $(this).val()

            checkVin(vin)
        })

        function checkVin(vin) {
            if (isNaN(vin.slice(-5, 17)) == true) {
                $(".car_alert-zone").removeClass("d-none")
                $(".car_alert-zone .alert").text("Numéro de châssis est invalide!")

                return false
            }
            else {
                $(".car_alert-zone").addClass("d-none")
                $(".car_alert-zone .alert").text("")

                return true
            }
        }
    }
})