!(function (factory) {
    "function" == typeof define && define.amd ? define(["jquery", "moment"], factory) : "object" == typeof exports ? (module.exports = factory(require("jquery"), require("moment"))) : factory(jQuery, moment);
})(function ($, moment) {
    var PagesCalendar = function (element, options) {
        (this.$element = $(element)),
            (this.$html = '<div class="calendar">'),
            (this.$html += "\x3c!-- START CALENDAR HEADER--\x3e"),
            (this.$html += '<div class="calendar-header">'),
            (this.$html += '<div class="drager">'),
            (this.$html += '<div class="years" id="years"></div>'),
            (this.$html += "</div>"),
            (this.$html += "</div>"),
            (this.$html += '<div class="options">'),
            (this.$html += '<div class="months-drager drager">'),
            (this.$html += '<div class="months" id="months"></div>'),
            (this.$html += "</div>"),
            (this.$html += '<h4 class="semi-bold date" id="currentDate">&amp;</h4>'),
            (this.$html += '<div class="drager week-dragger">'),
            (this.$html += '<div class="weeks-wrapper" id="weeks-wrapper">'),
            (this.$html += " </div>"),
            (this.$html += "</div>"),
            (this.$html += "</div>"),
            (this.$html += "\x3c!-- START CALENDAR GRID--\x3e"),
            (this.$html += '<div id="calendar" class="calendar-container">'),
            (this.$html += "</div>"),
            (this.$html += "\x3c!-- END CALENDAR GRID--\x3e"),
            (this.$html += "</div>"),
            this.$element.append(this.$html);
        var plugin = this;
        plugin.settings = $.extend(!0, {}, $.fn.pagescalendar.defaults, options);
        var EventManager = EventManager || {},
            yearSelector =
                ((EventManager = {
                    getEventByID: function (id) {
                        for (var i = 0; i < this.events.length; i++) if (Calendar.settings.events[i]._id == id) return setting.events[i];
                        return null;
                    },
                    getEventsByDate: function (date) {
                        date = moment(date).format("YYYY-MM-DD");
                        for (var i = 0; i < this.events.length; i++) {
                            var eventStartDate = moment(Calendar.settings.events[i].start).format("YYYY-MM-DD");
                            if (0 == date.localeCompare(eventStartDate)) return setting.events[i];
                        }
                        return null;
                    },
                    getAllEvents: function () {
                        return this.events;
                    },
                    addEvent: function () {
                        this.events.push(event);
                    },
                    lazyFetch: function () {},
                    byDateRange: function (startDate, endDate) {
                        for (
                            var start = moment(startDate).format("YYYY-MM-DD"), end = moment(endDate).add(24, "hours").format("YYYY-MM-DD"), range = moment.range(start, end), disableDatesInRange = [], i = 0;
                            i < Calendar.settings.disableDates.length;
                            i++
                        ) {
                            (when = moment(Calendar.settings.disableDates[i])).within(range) && disableDatesInRange.push(Calendar.settings.disableDates[i]);
                        }
                        var eventIndex = [];
                        for (i = 0; i < Calendar.settings.events.length; i++) {
                            var when;
                            if ((when = moment(Calendar.settings.events[i].start)).within(range)) {
                                for (var disable = !1, j = 0; j < disableDatesInRange.length; j++) {
                                    var s = moment(disableDatesInRange[j]).format("YYYY-MM-DD"),
                                        r = moment.range(s, moment(s).add(24, "hours"));
                                    if (when.within(r)) {
                                        disable = !0;
                                        break;
                                    }
                                }
                                disable || eventIndex.push(i);
                            }
                        }
                        return eventIndex;
                    },
                    hasEventByDate: function (date, format) {
                        date = moment(date).format(format);
                        for (var i = 0; i < Calendar.settings.events.length; i++) {
                            var event = moment(Calendar.settings.events.start).format(format);
                            if (0 == date.localeCompare(event)) return !0;
                        }
                        return !1;
                    },
                    eventCountByDate: function (date, format) {
                        date = moment(date).format(format);
                        for (var count = 0, i = 0; i < Calendar.settings.events.length; i++) {
                            var event = moment(Calendar.settings.events.start).format(format);
                            0 == date.localeCompare(event) && count++;
                        }
                        return count;
                    },
                    getDisableDateIndexInDateRange: function (start, end, returnFormat) {
                        (start = moment(start).format("YYYY-MM-DD")), (end = moment(end).format("YYYY-MM-DD"));
                        for (var range = moment.range(start, end), dateIndex = [], i = 0; i < Calendar.settings.disableDates.length; i++) {
                            moment(Calendar.settings.disableDates[i]).within(range) && dateIndex.push(moment(Calendar.settings.disableDates[i]).format(returnFormat));
                        }
                        return dateIndex;
                    },
                    eventCountByDateRange: function (startDate, endDate, format) {
                        for (var start = moment(startDate).format("YYYY-MM-DD"), end = moment(endDate).format("YYYY-MM-DD"), range = moment.range(start, end), count = [], i = 0; i < Calendar.settings.events.length; i++) {
                            moment(Calendar.settings.events[i].start).within(range) && count++;
                        }
                        return count;
                    },
                }),
                (function () {
                    function yearSelector(container) {
                        (this.container = container), this.render();
                    }
                    var _setActive = function () {
                        var diff = calendar.year - Calendar.settings.ui.year.startYear;
                        $(".year a").removeClass("active"), $(".year:nth-child(" + diff + ") > a").addClass("active");
                    };
                    return (
                        (yearSelector.prototype.render = function () {
                            $(this.container).html(""), (Calendar.content = "");
                            var diffYears = Calendar.settings.ui.year.endYear - Calendar.settings.ui.year.startYear;
                            diffYears = 90 < diffYears ? 90 : diffYears;
                            for (var yearInc = Calendar.settings.ui.year.startYear, i = 1; i <= diffYears; i++) {
                                yearInc = moment(yearInc, Calendar.settings.ui.year.format).add(1, "year").format(Calendar.settings.ui.year.format);
                                var activeClass = calendar.year == yearInc ? "active" : "";
                                (Calendar.content += '<div class="year">'), (Calendar.content += '<a href="#" class="year-selector ' + activeClass + '" data-year=' + yearInc + ">" + yearInc + "</a>"), (Calendar.content += "</div>");
                            }
                            $(this.container).append(Calendar.content),
                                Calendar.dragHandler("years"),
                                _setActive(),
                                $(document).off("click", "body:not(.pending) .year-selector"),
                                $(document).on("click", "body:not(.pending) .year-selector", function (e) {
                                    var year = $(this).attr("data-year");
                                    (calendar.year = moment(year, Calendar.settings.ui.year.format).year()), _setActive(), Calendar._onYearChange();
                                });
                        }),
                        yearSelector
                    );
                })()),
            monthSelector = function (container) {
                (this.container = container), this.render();
            };
        monthSelector.prototype = {
            render: function () {
                $(this.container).html(""), (Calendar.content = "");
                for (var months = moment.monthsShort(), currentMonth = moment([calendar.year, calendar.month, calendar.date]).format(Calendar.settings.ui.month.format), i = 0; i < months.length; i++) {
                    var formatedMonth = moment(months[i], "MMMM").format(Calendar.settings.ui.month.format),
                        activeClass = currentMonth == formatedMonth ? "active" : "";
                    (Calendar.content += '<div class="month">'), (Calendar.content += '<a href="javascript:void(0)" class="month-selector ' + activeClass + '" data-month="' + formatedMonth + '">' + formatedMonth + "</a>"), (Calendar.content += "</div>");
                }
                $(this.container).append(Calendar.content), Calendar.dragHandler("months"), this._bindEvents();
            },
            _setActive: function () {
                $(".month a").removeClass("active"), $(".month:nth-child(" + (parseInt(calendar.month) + 1) + ") > a").addClass("active");
            },
            _bindEvents: function () {
                var $this = this;
                $(document).off("click", "body:not(.pending) .month-selector"),
                    $(document).on("click", "body:not(.pending) .month-selector", function (e) {
                        var month = $(this).attr("data-month");
                        (calendar.month = moment(month, Calendar.settings.ui.month.format).month()),
                            (Calendar.daysOfMonth = moment([calendar.year, calendar.month]).daysInMonth()),
                            Calendar.daysOfMonth < calendar.date && (calendar.date = Calendar.daysOfMonth),
                            Calendar._onMonthChange(),
                            $this._setActive();
                    });
            },
        };
        var dateSelector = function (container) {
            (this.container = container), this.render(), this._bindEvents();
        };
        dateSelector.prototype = {
            render: function () {
                $(this.container).html(""), (Calendar.daysOfMonth = moment([calendar.year, calendar.month]).daysInMonth()), (Calendar.content = "");
                for (var weekStart = parseInt(moment(Calendar.settings.ui.week.startOfTheWeek, "d").format("d")), weekEnd = parseInt(moment(Calendar.settings.ui.week.endOfTheWeek, "d").format("d")), i = 1; i <= Calendar.daysOfMonth; i++) {
                    var date = moment([calendar.year, calendar.month, i]),
                        t = parseInt(moment([calendar.year, calendar.month, i]).format("d")),
                        activeClass = calendar.date == i ? "active current-date" : "";
                    function _construcWeekday() {
                        (Calendar.content += '<div class="day-wrapper date-selector">'),
                            (Calendar.content += '<div class="week-day">'),
                            (Calendar.content += '<div class="day week-header">' + date.format(Calendar.settings.ui.week.header.format) + "</div>"),
                            (Calendar.content += "</div>"),
                            (Calendar.content += '<div class="week-date ' + activeClass + '">'),
                            (Calendar.content += '<div class="day"><a href="#" data-date=' + date.format(Calendar.settings.ui.week.day.format) + ">" + i + "</a></div>"),
                            (Calendar.content += "</div>"),
                            (Calendar.content += "</div>");
                    }
                    (t == weekStart || 1 == i) && (Calendar.content += '<div class="week ' + activeClass + '">'),
                        weekEnd <= weekStart ? (weekStart <= t || t == weekEnd) && _construcWeekday() : weekStart <= t && t <= weekEnd && _construcWeekday(),
                        t == weekEnd && (Calendar.content += "</div>");
                }
                (Calendar.content += "</div>"), $(this.container).append(Calendar.content), $(".weeks-wrapper .week .day-wrapper .week-date.active").closest(".week").addClass("active"), Calendar.dragHandler("weeks-wrapper");
            },
            _setActive: function () {
                $(".week").removeClass("active"), $(elem).closest(".week").addClass("active");
            },
            _bindEvents: function () {
                $(document).off("click", "body:not(.pending) .date-selector"),
                    $(document).on("click", "body:not(.pending) .date-selector", function (e) {
                        $(".week-date").removeClass("active"),
                            $(this).children(".week-date").addClass("active"),
                            (calendar.date = parseInt($(this).children(".week-date").children(".day").children("a").attr("data-date"))),
                            Calendar._onDayChange();
                    });
            },
        };
        var gridFractory = function (layout) {
            (this.grid = null), (this.miniCalendar = null), (this.layout = layout), this.build();
        };
        (gridFractory.prototype.build = function () {
            switch (this.layout) {
                case "month":
                    this.grid = new MonthView(".calendar-container");
                    break;
                case "week":
                case "day":
                    (this.miniCalendar = new dateSelector(".weeks-wrapper")), (this.grid = new wView(".calendar-container", this.layout));
            }
        }),
            (gridFractory.prototype.refresh = function () {
                return this.grid.refresh();
            }),
            (gridFractory.prototype.rebuild = function () {
                this.build();
            }),
            (gridFractory.prototype.reloadEvents = function () {
                this.grid.reloadEvents();
            }),
            (gridFractory.prototype.doubletap = function (obj) {
                return this.grid.doubletap(obj);
            }),
            (gridFractory.prototype.getCurrentDateRange = function () {
                return this.grid.getCurrentDateRange();
            }),
            (gridFractory.prototype.scrollToFirstEvent = function () {
                return this.grid.scrollToFirstEvent();
            });
        var view = function (container) {
                this.container = container;
            },
            wView = function (container, layout) {
                (this.layout = layout), view.call(this, container), this.render(), (this.snapGridWidth = null), (this.snapGridHeight = null);
            };
        (wView.prototype = Object.create(view.prototype)),
            (wView.prototype.render = function () {
                (this.weekStart = parseInt(moment(Calendar.settings.ui.week.startOfTheWeek, "d").format("d"))),
                    (this.weekEnd = parseInt(moment(Calendar.settings.ui.week.endOfTheWeek, "d").format("d"))),
                    (this.startOfWeek = moment([calendar.year, calendar.month, calendar.date]).startOf("week")),
                    this.weekStart >= this.weekEnd
                        ? (this.endOfWeek = moment([calendar.year, calendar.month, calendar.date])
                              .startOf("week")
                              .add("days", 7 + parseInt(Calendar.settings.ui.week.endOfTheWeek)))
                        : (this.endOfWeek = moment([calendar.year, calendar.month, calendar.date]).startOf("week").add("days", parseInt(Calendar.settings.ui.week.endOfTheWeek))),
                    this._buildLayout(),
                    this._timeslots(),
                    this._loadDates(),
                    this._bindEventDraggers(),
                    this._setActive(),
                    this.windowResize(),
                    Calendar.settings.ui.grid.scrollToFirstEvent && this.scrollToFirstEvent();
            }),
            (wView.prototype._buildLayout = function () {
                var headerContent = '<div class="thead" >',
                    weekend = this.weekEnd;
                this.weekStart >= this.weekEnd && (weekend = 7);
                for (var j = this.weekStart; j <= weekend; j++) headerContent += '<div class="tcell"></div>';
                (headerContent += "</div>"),
                    $(this.container).html(""),
                    (Calendar.content = ""),
                    (Calendar.content += '<div class="view ' + this.layout + '-view">'),
                    (Calendar.content += '<div class="allday-cell">'),
                    (Calendar.content += "</div>"),
                    (Calendar.content += '<div class="tble" id="viewTableHead">'),
                    (Calendar.content += headerContent),
                    (Calendar.content += "</div>"),
                    (Calendar.content += '<div class="grid slot-' + Calendar.settings.slotDuration + '">'),
                    (Calendar.content += '<div class="time-slot-wrapper" id="time-slots">'),
                    (Calendar.content += "</div>"),
                    (Calendar.content += '<div class="tble" id="weekGrid">');
                for (var slot = parseInt(Calendar.settings.slotDuration), slotCount = 60 / Math.round(slot) - 1, i = Calendar.settings.minTime; i < Calendar.settings.maxTime; i++) {
                    Calendar.content += '<div class="trow" >';
                    for (j = this.weekStart; j <= weekend; j++) {
                        (Calendar.content += '<div class="tcell">'), (Calendar.content += '<div class="cell-inner" data-time-slot="' + i + ':00" ></div>');
                        for (var _slot = 0, s = 0; s < slotCount; s++) Calendar.content += '<div class="cell-inner" data-time-slot="' + i + ":" + (_slot += slot) + '" ></div>';
                        Calendar.content += "</div>";
                    }
                    Calendar.content += "</div>";
                }
                (Calendar.content += "</div>"),
                    (Calendar.content += "</div>"),
                    (Calendar.content += "</div>"),
                    $(this.container).append(Calendar.content),
                    (Calendar.cellHeight = $(".tcell").innerHeight()),
                    (calendar.startOfWeekDate = moment([calendar.year, calendar.month, calendar.date]).startOf("week")),
                    (calendar.endOfWeek = moment([calendar.year, calendar.month, calendar.date]).endOf("week").format("D")),
                    (this.maxMinsPerDay = moment(Calendar.settings.maxTime + ":00", ["H:mm"]).diff(moment(Calendar.settings.minTime + ":00", ["H:mm"]), "minutes")),
                    $(".calendar").removeClass("month").addClass("week");
            }),
            (wView.prototype._loadDates = function () {
                var column = 1,
                    weekend = this.weekEnd;
                this.weekStart >= this.weekEnd && (weekend = 7);
                for (var i = this.weekStart; i <= weekend; i++) {
                    var date = moment(this.startOfWeek).add(i, "days");
                    $("#viewTableHead")
                        .find(".thead .tcell:nth-child(" + column + ")")
                        .html('<div class="weekdate">' + moment(date).format("D") + '</div><div class="weekday">' + moment(date).format("dddd") + "</div>")
                        .attr("data-day", moment(date).format("YYYY-MM-DD")),
                        column++;
                }
                (calendar.startOfWeekDate = moment([calendar.year, calendar.month, calendar.date]).startOf("week")), this.showDisableDates(), this._drawEvent();
            }),
            (wView.prototype.renderEvent = function (eventStartHours, eventStartMins, eventEndHours, evenEndMins, cellNo, arrayIndex, eventDuration) {
                var minsRemoved = Math.round(moment(eventStartHours + ":" + eventStartMins, ["H:mm"]).diff(moment("0:00", ["H:mm"]), "minutes") / Calendar.settings.slotDuration) * Calendar.settings.slotDuration,
                    MAXMINS = this.maxMinsPerDay - minsRemoved,
                    slotNumber = Math.round(eventStartMins / Calendar.settings.slotDuration) * Calendar.settings.slotDuration;
                60 == slotNumber && ((eventStartHours = parseInt(eventStartHours) + 1), (slotNumber = 0)), (slotNumber /= Calendar.settings.slotDuration);
                for (
                    var height,
                        slotParent = $($(".tble .cell-inner[data-time-slot='" + eventStartHours + ":00']")[cellNo]).parent(),
                        duration = moment(Calendar.settings.events[arrayIndex].end).diff(Calendar.settings.events[arrayIndex].start, "minutes"),
                        remainingMins = (duration = Math.round(duration / Calendar.settings.slotDuration) * Calendar.settings.slotDuration);
                    0 < remainingMins;

                )
                    remainingMins > this.maxMinsPerDay
                        ? ((height = (MAXMINS / Calendar.settings.slotDuration) * this.snapGridHeight),
                          (remainingMins -= MAXMINS),
                          (remainingMins = Math.round(remainingMins / Calendar.settings.slotDuration) * Calendar.settings.slotDuration),
                          $(slotParent.children()[slotNumber]).append(this._buildEventElement(height, arrayIndex, cellNo, eventStartHours, eventStartMins, eventEndHours, evenEndMins)),
                          (eventStartMins = eventStartHours = 0),
                          (cellNo = parseInt(cellNo) + 1),
                          (slotParent = $($(".tble .cell-inner[data-time-slot='" + eventStartHours + ":00']")[cellNo]).parent()))
                        : ((height = (remainingMins / Calendar.settings.slotDuration) * this.snapGridHeight),
                          (remainingMins = 0),
                          $(slotParent.children()[slotNumber]).append(this._buildEventElement(height, arrayIndex, cellNo, eventStartHours, eventStartMins, eventEndHours, evenEndMins)));
                plugin.settings.onEventRender.call(this);
            }),
            (wView.prototype._buildEventElement = function (h, arrayIndex, cellNo, eventStartHours, eventStartMins, eventEndHours, evenEndMins) {
                h = "height:" + h + "px;";
                var id = "ca_" + moment(Calendar.settings.events[arrayIndex].start).unix() + arrayIndex,
                    readonly = 1 == Calendar.settings.events[arrayIndex].readOnly ? "readonly" : "",
                    eventContent =
                        "<div class='event-container " +
                        Calendar.settings.events[arrayIndex].class +
                        " " +
                        readonly +
                        "' data-event-duration=" +
                        moment(Calendar.settings.events[arrayIndex].end).diff(Calendar.settings.events[arrayIndex].start, "minutes") +
                        " data-index=" +
                        arrayIndex +
                        " data-startTime=" +
                        Calendar.settings.events[arrayIndex].start +
                        " data-endTime=" +
                        Calendar.settings.events[arrayIndex].end +
                        " id=" +
                        id +
                        " data-id=" +
                        id +
                        " data-row=" +
                        parseInt(eventStartHours) +
                        " data-cell=" +
                        cellNo +
                        " style=" +
                        h +
                        ">";
                return (
                    (eventContent += "<div class='event-inner'>"),
                    (eventContent += "<div class='event-title'>" + Calendar.settings.events[arrayIndex].title + "</div>"),
                    (eventContent += "<div class='time-wrap'><span class='event-start-time'>" + moment(Calendar.settings.events[arrayIndex].start).format(Calendar.settings.timeFormat) + "</span> - "),
                    (eventContent += "<span class='event-end-time'>" + moment(Calendar.settings.events[arrayIndex].end).format(Calendar.settings.timeFormat) + "</span></div>"),
                    (eventContent += "</div>"),
                    (0 != Calendar.settings.events[arrayIndex].readOnly && null != Calendar.settings.events[arrayIndex].readOnly) || (eventContent += "<div class='resizable-handle'></div>"),
                    (eventContent += "</div>"),
                    (Calendar.settings.events[arrayIndex].dataID = id),
                    eventContent
                );
            }),
            (wView.prototype._drawEvent = function () {
                (this.snapGridWidth = parseInt($(".tcell").outerWidth())), (this.snapGridHeight = parseInt($(".cell-inner").outerHeight()));
                this.weekEnd;
                this.weekStart, this.weekEnd;
                var _eventIndex = EventManager.byDateRange(this.startOfWeek, this.endOfWeek);
                $("#weekGrid").find(".event-container").remove(), $(".tble > .thead > .tcell").find(".event-bubble").remove();
                for (var i = 0; i < _eventIndex.length; i++) {
                    var index = _eventIndex[i],
                        e = moment(Calendar.settings.events[index].start),
                        dayOfTheWeek = moment(Calendar.settings.events[index].start).format("e");
                    "0" == dayOfTheWeek && 0 < parseInt(Calendar.settings.ui.week.startOfTheWeek) && (dayOfTheWeek = 7);
                    var eventEndHours,
                        evenEndMins,
                        cellNo = parseInt(dayOfTheWeek) - parseInt(Calendar.settings.ui.week.startOfTheWeek),
                        eventStartHours = e.format("H"),
                        eventStartMins = e.format("m");
                    null == Calendar.settings.events[index].end
                        ? ((eventEndHours = parseInt(eventStartHours) + 1), (evenEndMins = "0"))
                        : ((eventEndHours = moment(Calendar.settings.events[index].end).format("H")), (evenEndMins = moment(Calendar.settings.events[index].end).format("m")));
                    var eventDuration = moment(eventEndHours + ":" + evenEndMins, "h:mm").diff(moment(eventStartHours + ":" + eventStartMins, "h:mm"), "minutes", !0);
                    Calendar.settings.ui.grid.eventBubble && null != Calendar.settings.events[index].class && this._setEventBubble(Calendar.settings.events[index].class, cellNo),
                        this.renderEvent(eventStartHours, eventStartMins, eventEndHours, evenEndMins, cellNo, index, eventDuration);
                }
                0 == Calendar.settings.eventOverlap && this.collisionGroups(_eventIndex), this.setEventBubbles(Calendar.settings.events);
            }),
            (wView.prototype.collisionGroups = function (_eventIndex) {
                for (var collisionGroups = [], i = 1; i < _eventIndex.length; i++) {
                    var event1Range = moment.range(Calendar.settings.events[_eventIndex[i]].start, Calendar.settings.events[_eventIndex[i]].end),
                        j = i - 1;
                    do {
                        var temp = [];
                        (event2Range = moment.range(Calendar.settings.events[_eventIndex[j]].start, Calendar.settings.events[_eventIndex[j]].end)),
                            event1Range.overlaps(event2Range) && (temp.push(_eventIndex[i]), temp.push(_eventIndex[j]), collisionGroups.push(temp)),
                            j--;
                    } while (0 === j);
                }
                function union_arrays(array) {
                    for (var a = array.concat(), startLength = a.length, i = 0; i < a.length; ++i) for (var j = i + 1; j < a.length; ++j) a[i] === a[j] && a.splice(j--, 1);
                    return startLength != a.length ? a : [];
                }
                for (i = 0; i < collisionGroups.length; ++i)
                    for (j = i + 1; j < collisionGroups.length; ++j) {
                        var newArray = union_arrays(collisionGroups[i].concat(collisionGroups[j]));
                        0 < newArray.length && ((collisionGroups[j] = newArray), collisionGroups.splice(i, 1), j--);
                    }
                for (i = 0; i < collisionGroups.length; i++) {
                    var width = 100 / collisionGroups[i].length,
                        left = 0;
                    for (j = 0; j < collisionGroups[i].length; j++) {
                        $(".event-container[data-index='" + collisionGroups[i][j] + "']").css({ width: width + "%", left: left + "%" }), (left += width);
                    }
                }
            }),
            (wView.prototype._bindEventDraggers = function () {
                var mins = 0,
                    days = 0,
                    duration = 0,
                    parent = $("#weekGrid")[0],
                    $snapGridHeight = this.snapGridHeight,
                    $snapGridWidth = this.snapGridWidth,
                    $this = this;
                interact(".event-container:not(.readonly)")
                    .draggable({
                        snap: { targets: [interact.createSnapGrid({ x: this.snapGridWidth, y: this.snapGridHeight })] },
                        inertia: !0,
                        restrict: { restriction: parent, elementRect: { top: 0, left: 0, bottom: 0, right: 0 }, endOnly: !0 },
                        autoScroll: !0,
                        onmove: function (event) {
                            (mins += (event.dy / $snapGridHeight) * Calendar.settings.slotDuration), (days += event.dx / $snapGridWidth), Calendar.dragMoveListener(event), event.target.classList.add("dragging");
                        },
                        onend: function (event) {
                            var el = event.target;
                            el = $(el);
                            var eventData = $(el).data(),
                                eventO = Calendar.constructEventForUser(eventData.index);
                            eventO.readOnly
                                ? $this._drawEvent()
                                : ((eventO.start = moment(eventO.start).add(days, "days")),
                                  (eventO.start = moment(eventO.start).add(mins, "minutes").format()),
                                  (eventO.end = moment(eventO.start).add(el.attr("data-event-duration"), "minutes").format()),
                                  (Calendar.settings.events[eventData.index] = eventO),
                                  (days = mins = 0),
                                  event.target.classList.remove("dragging"),
                                  $this._drawEvent(),
                                  plugin.settings.onEventDragComplete(eventO));
                        },
                    })
                    .resizable({ edges: { left: !1, right: !0, bottom: !0, top: !1 }, snap: { targets: [interact.createSnapGrid({ x: this.snapGridWidth, y: this.snapGridHeight })] } })
                    .on("resizemove", function (event) {
                        var target = event.target,
                            height = event.rect.height;
                        if ((height < $snapGridHeight && ((height = $snapGridHeight), (event.rect.height = $snapGridHeight)), 0 != event.dx)) {
                            days += event.dx / this.snapGridWidth;
                            $(target);
                            var eventData = $(target).data(),
                                eventO = Calendar.constructEventForUser(eventData.index),
                                difference = moment(eventO.start).format("H");
                            height = 80 * (duration = 24 - difference);
                        }
                        (duration = (height / $snapGridHeight) * Calendar.settings.slotDuration), (target.style.height = height + "px");
                    })
                    .on("resizeend", function (event) {
                        var target = event.target,
                            eventData = ($(target), $(target).data()),
                            eventO = Calendar.constructEventForUser(eventData.index);
                        eventO.readOnly
                            ? $this._drawEvent()
                            : ((eventO.end = moment(eventO.start).add(duration, "minutes").format()),
                              (days = duration = 0),
                              (Calendar.settings.events[eventData.index] = eventO),
                              $this._drawEvent(),
                              plugin.settings.onEventDragComplete(eventO));
                    });
            }),
            (wView.prototype._setEventBubble = function (className, cellNo) {
                var $elem = $("#viewTableHead").find(".thead .tcell:nth-child(" + (parseInt(cellNo) + 1) + ")");
                0 == $elem.children("." + className).length && $elem.append('<div class="event-bubble ' + className + '"></div>');
            }),
            (wView.prototype._timeslots = function () {
                Calendar.content = "";
                for (var i = Calendar.settings.minTime; i < Calendar.settings.maxTime; i++) Calendar.content += '<div class="time-slot"><span>' + moment().hour(i).format(Calendar.settings.ui.grid.timeFormat) + "</span></div>";
                $("#time-slots").append(Calendar.content);
            }),
            (wView.prototype._setActive = function () {
                $("#viewTableHead").find(".thead .tcell").removeClass("active"), $("#weekGrid").find(".trow .tcell").removeClass("active");
                var d = moment([calendar.year, calendar.month, calendar.date]).format("e"),
                    index = 0;
                (index = 0 == d && parseInt(Calendar.settings.ui.week.startOfTheWeek) > d ? 7 : parseInt(d) + (1 - parseInt(Calendar.settings.ui.week.startOfTheWeek))),
                    $("#viewTableHead")
                        .find(".thead .tcell:nth-child(" + index + ")")
                        .addClass("active"),
                    $("#weekGrid")
                        .find(".trow .tcell:nth-child(" + index + ")")
                        .addClass("active");
            }),
            (wView.prototype.scrollToFirstEvent = function () {
                var _eventIndex = EventManager.byDateRange(this.startOfWeek, this.endOfWeek);
                if (0 == _eventIndex.length) return !1;
                var el = $(".cell-inner [data-index='" + _eventIndex[0] + "']"),
                    grid = $(".calendar .calendar-container .grid"),
                    parentOffSet = $(".calendar .calendar-container .grid").get(0).getBoundingClientRect(),
                    position = el.get(0).getBoundingClientRect().top - parentOffSet.top - Calendar.settings.ui.grid.scrollToGap,
                    currentPosition = grid.scrollTop();
                grid.animate({ scrollTop: currentPosition + position }, Calendar.settings.ui.grid.scrollToAnimationSpeed);
            }),
            (wView.prototype.doubletap = function (obj) {
                var elem = $(obj),
                    date = $("#viewTableHead")
                        .find(".thead .tcell:nth-child(" + (elem.parent().index() + 1) + ")")
                        .attr("data-day"),
                    time = moment(elem.attr("data-time-slot"), ["H:mm"]).format(" H:mm");
                return { date: (date = moment(date + time, "YYYY/MM/D h:mm").format()) };
            }),
            (wView.prototype.showDisableDates = function () {
                $(".tble .trow .tcell").removeClass("disable").children(".cell-inner").removeClass("disable"), (this.disableDates = EventManager.getDisableDateIndexInDateRange(this.startOfWeek, this.endOfWeek, "e"));
                for (var i = 0; i < this.disableDates.length; i++)
                    $(".tble .trow .tcell:nth-child(" + (parseInt(this.disableDates[i]) + 1) + ")")
                        .addClass("disable")
                        .children(".cell-inner")
                        .addClass("disable");
            }),
            (wView.prototype.refresh = function () {
                var currentDate = moment([calendar.year, calendar.month, calendar.date]);
                (this.weekStart = parseInt(moment(Calendar.settings.ui.week.startOfTheWeek, "d").format("d"))),
                    (this.weekEnd = parseInt(moment(Calendar.settings.ui.week.endOfTheWeek, "d").format("d"))),
                    0 == this.weekEnd && (this.weekEnd = 7),
                    0 == currentDate.weekday() && 0 < this.weekStart
                        ? (this.startOfWeek = moment([calendar.year, calendar.month, calendar.date]).subtract("days", this.weekEnd).startOf("week"))
                        : (this.startOfWeek = moment([calendar.year, calendar.month, calendar.date]).startOf("week")),
                    (this.endOfWeek = currentDate.startOf("week").add("days", this.weekEnd)),
                    this._loadDates(),
                    this._setActive();
                var dates = [this.startOfWeek, this.endOfWeek];
                return this.setEventBubbles(Calendar.settings.events), dates;
            }),
            (wView.prototype.reloadEvents = function () {
                this._drawEvent();
            }),
            (wView.prototype.rebuild = function () {
                this.grid._buildLayout(), this.refresh();
            }),
            (wView.prototype.windowResize = function () {
                var $this = this;
                $(window).resize(function () {
                    ($this.snapGridWidth = parseInt($(".tcell").outerWidth())), $this._bindEventDraggers();
                });
            }),
            (wView.prototype.getCurrentDateRange = function () {
                return [this.startOfWeek, this.endOfWeek];
            }),
            (wView.prototype.setEventBubbles = function (eventArray) {
                for (var item in ($(".has-event").removeClass("has-event"), Calendar.settings.events)) {
                    var eventYear = moment(Calendar.settings.events[item].start).format(Calendar.settings.ui.year.format),
                        eventMonth = moment(Calendar.settings.events[item].start).format(Calendar.settings.ui.month.format),
                        eventDate = moment(Calendar.settings.events[item].start).format("D");
                    Calendar.settings.ui.year.eventBubble && $('.year > [data-year="' + eventYear + '"]').addClass("has-event"),
                        calendar.year == moment(Calendar.settings.events[item].start).format("YYYY") &&
                            (Calendar.settings.ui.month.eventBubble && $('.month > [data-month="' + eventMonth + '"]').addClass("has-event"),
                            calendar.month + 1 == moment(Calendar.settings.events[item].start).format("M") &&
                                Calendar.settings.ui.week.eventBubble &&
                                $('.date-selector > .week-date > .day > [data-date="' + eventDate + '"]').addClass("has-event"));
                }
            });
        var MonthView = function (container) {
            (this.monthViewStartDate = ""), (this.monthViewEndDate = ""), view.call(this, container), (this.gridElem = ""), this.render();
        };
        return (
            (MonthView.prototype = Object.create(view.prototype)),
            (MonthView.prototype.render = function () {
                (this.weekStart = parseInt(moment(Calendar.settings.ui.week.startOfTheWeek, "d").format("d"))),
                    (this.weekEnd = parseInt(moment(Calendar.settings.ui.week.endOfTheWeek, "d").format("d"))),
                    (this.startOfWeek = moment([calendar.year, calendar.month, calendar.date]).startOf("week")),
                    (this.endOfWeek = moment([calendar.year, calendar.month, calendar.date])),
                    this._buildLayout(),
                    this._loadDates(),
                    this._bindEvents(),
                    this.bindEventDraggers();
            }),
            (MonthView.prototype._buildLayout = function () {
                for (var headerContent = '<div class="thead" >', j = this.weekStart; j <= this.weekEnd; j++) headerContent += '<div class="tcell"></div>';
                (headerContent += "</div>"),
                    $(this.container).html(""),
                    (Calendar.content = ""),
                    (Calendar.content += '<div class="view month-view">'),
                    (Calendar.content += '<div class="tble" id="viewTableHead">'),
                    (Calendar.content += headerContent),
                    (Calendar.content += "</div>"),
                    (Calendar.content += '<div class="grid">'),
                    (Calendar.content += '<div class="tble" id="monthGrid">');
                for (var i = 0; i < 6; i++) {
                    Calendar.content += '<div class="trow" >';
                    for (j = this.weekStart; j <= this.weekEnd; j++)
                        (Calendar.content += '<div class="tcell">'), (Calendar.content += '<div class="month-date"></div>'), (Calendar.content += '<div class="cell-inner"><div class="holder"></div></div>'), (Calendar.content += "</div>");
                    Calendar.content += "</div>";
                }
                (Calendar.content += "</div>"),
                    (Calendar.content += "</div>"),
                    (Calendar.content += "</div>"),
                    $(this.container).append(Calendar.content),
                    (cellHeight = $(".tcell").innerHeight()),
                    (calendar.startOfWeekDate = moment([calendar.year, calendar.month, calendar.date]).startOf("week")),
                    (calendar.endOfWeek = moment([calendar.year, calendar.month, calendar.date]).endOf("week").format("D")),
                    (this.snapGridWidth = parseInt($(".tcell").outerWidth())),
                    (this.snapGridHeight = parseInt($(".cell-inner").outerHeight())),
                    (this.holderHeight = parseInt($(".holder").outerHeight())),
                    $(".calendar").removeClass("week").addClass("month");
            }),
            (MonthView.prototype._loadDates = function () {
                $(".tcell").removeClass("not active current-date"), $(".month-date").removeClass("not active current-date");
                for (var column = 1, i = this.weekStart; i <= this.weekEnd; i++) {
                    var date = moment(this.startOfWeek).add(i, "days");
                    $("#viewTableHead")
                        .find(".thead .tcell:nth-child(" + column + ")")
                        .html('</div><div class="weekday">' + moment(date).format("dddd") + "</div>")
                        .attr("data-day", moment(date).format("YYYY-MM-DD")),
                        column++;
                }
                calendar.startOfWeekDate = moment([calendar.year, calendar.month, calendar.date]).startOf("week");
                var startDate = moment([calendar.year, calendar.month, calendar.date]).startOf("month").startOf("week");
                this.monthViewStartDate = moment([calendar.year, calendar.month, calendar.date]).startOf("month").startOf("week");
                var extraClass = null;
                this.gridElem = $(this.container).find(".view .grid");
                for (i = 1; i <= 6; i++)
                    for (var j = this.weekStart; j <= this.weekEnd; j++) {
                        var monthOfDate = parseInt(startDate.format("M")) - 1;
                        calendar.month != monthOfDate && (extraClass = "not"),
                            calendar.date == startDate.format("D") && calendar.month == monthOfDate && (extraClass = "current-date active"),
                            this.gridElem
                                .find(".tble .trow:nth-child(" + i + ") .tcell:nth-child(" + (j + 1) + ")")
                                .addClass(extraClass)
                                .attr("data-date", startDate.format("YYYY-MM-DD"))
                                .children(".month-date")
                                .html(startDate.format("D"))
                                .addClass(extraClass),
                            (startDate = startDate.add(1, "days")),
                            (extraClass = "");
                    }
                (this.monthViewEndDate = startDate), this._showDisableDates(), this._drawEvent(), this.setEventBubbles();
            }),
            (MonthView.prototype._bindEvents = function () {
                var $this = this;
                $(".month-view .tcell").off("click"),
                    $(".month-view .tcell").on("click", function () {
                        var d = moment($(this).attr("data-date"));
                        (calendar.date = d.format("D")), (calendar.month = parseInt(d.format("M")) - 1), $this.setActive($(this)), Calendar._buildCurrentDateHeader();
                    });
            }),
            (MonthView.prototype._drawEvent = function () {
                var _eventIndex = EventManager.byDateRange(this.monthViewStartDate.format("YYYY-MM-DD"), this.monthViewEndDate.format("YYYY-MM-DD"));
                $("#monthGrid").find(".event-container").remove(), $("#monthGrid").find(".ghost-element").remove();
                for (var i = 0; i < _eventIndex.length; i++) {
                    var index = _eventIndex[i],
                        cell = $(".tble [data-date='" + moment(Calendar.settings.events[index].start).format("YYYY-MM-DD") + "']"),
                        days = moment(moment(Calendar.settings.events[index].end)).diff(moment(Calendar.settings.events[index].start), "days");
                    (days = days = 0 === days ? 1 : days), this.renderEvent(cell, index, days);
                }
            }),
            (MonthView.prototype.renderEvent = function (elem, arrayIndex, days) {
                var width = "style='width:" + 100 * parseInt(days) + "%'",
                    id = "ca_" + moment(Calendar.settings.events[arrayIndex].start).unix() + arrayIndex,
                    readonly = 1 == Calendar.settings.events[arrayIndex].readOnly ? "readonly" : "",
                    eventContent =
                        "<div class='event-container " +
                        Calendar.settings.events[arrayIndex].class +
                        " " +
                        readonly +
                        "' " +
                        width +
                        " data-index=" +
                        arrayIndex +
                        " data-startTime=" +
                        Calendar.settings.events[arrayIndex].start +
                        " data-endTime=" +
                        Calendar.settings.events[arrayIndex].end +
                        " id=" +
                        id +
                        " data-id=" +
                        id +
                        ">";
                (eventContent += "<div class='event-inner'>"),
                    (eventContent += "<div class='event-title'>" + Calendar.settings.events[arrayIndex].title + "</div>"),
                    (eventContent += "</div>"),
                    (eventContent += "</div>"),
                    elem.children(".cell-inner").children(".holder").append(eventContent),
                    (Calendar.settings.events[arrayIndex].dataID = id),
                    plugin.settings.onEventRender.call(this);
            }),
            (MonthView.prototype.doubletap = function (obj) {
                var el = $(obj).parent();
                return { date: moment(el.attr("data-date")).format() };
            }),
            (MonthView.prototype.bindEventDraggers = function () {
                var parent = $("#monthGrid")[0],
                    startPos = null,
                    $this = this;
                interact(".event-container:not(.readonly)")
                    .draggable({
                        inertia: !0,
                        restrict: { restriction: parent, elementRect: { top: 0, left: 0, bottom: 1, right: 1 }, endOnly: !0, drag: "" },
                        mode: "anchor",
                        anchors: [],
                        autoScroll: !0,
                        onmove: function (event) {
                            Calendar.dragMoveListener(event), event.target.classList.add("dragging");
                        },
                        onend: function (event) {
                            event.target.classList.remove("dragging");
                        },
                    })
                    .snap({ mode: "anchor", anchors: [], range: 1 / 0, elementOrigin: { x: 0.5, y: 0.5 }, endOnly: !0 })
                    .on("dragstart", function (event) {
                        if (!startPos) {
                            var rect = interact.getElementRect(event.target);
                            startPos = { x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 };
                        }
                        event.interactable.snap({ anchors: [startPos] });
                    }),
                    interact(".tcell")
                        .dropzone({ overlap: "center", accept: ".event-container" })
                        .on("dragenter", function (event) {
                            var dropRect = interact.getElementRect($(event.target).children(".cell-inner").children(".holder")[0]),
                                elementRect = interact.getElementRect(event.relatedTarget),
                                dropCenter = { x: dropRect.left + elementRect.width / 2, y: dropRect.top + elementRect.height / 2 };
                            event.draggable.snap({ anchors: [dropCenter] });
                            event.relatedTarget;
                            event.target.classList.add("drop-target");
                        })
                        .on("dragleave", function (event) {
                            event.draggable.snap(!1), event.draggable.snap({ anchors: [startPos] }), event.target.classList.remove("drop-target"), $(event.target).find(".ghost-element").remove();
                        })
                        .on("dropactivate", function (event) {})
                        .on("dropdeactivate", function (event) {
                            event.target.classList.remove("drop-active"), event.target.classList.remove("drop-target");
                        })
                        .on("drop", function (event) {
                            var drop = event.target,
                                draggableElement = event.relatedTarget;
                            drop = $(drop);
                            var eventData = (draggableElement = $(draggableElement)).data(),
                                eventO = Calendar.constructEventForUser(eventData.index);
                            eventO.readOnly
                                ? $this._drawEvent()
                                : ((eventO.start = moment(eventO.start)
                                      .set("month", moment(drop.attr("data-date")).get("month"))
                                      .format()),
                                  (eventO.start = moment(eventO.start)
                                      .set("date", moment(drop.attr("data-date")).get("date"))
                                      .format()),
                                  (eventO.end = moment(eventO.start)
                                      .set("date", moment(drop.attr("data-date")).get("date"))
                                      .format()),
                                  (Calendar.settings.events[eventData.index] = eventO),
                                  $this._drawEvent(),
                                  plugin.settings.onEventDragComplete(eventO));
                        });
            }),
            (MonthView.prototype._showDisableDates = function () {
                $(".tble .trow .tcell").removeClass("disable").children(".cell-inner").removeClass("disable"),
                    (this.disableDates = EventManager.getDisableDateIndexInDateRange(this.monthViewStartDate.format("YYYY-MM-DD"), this.monthViewEndDate.format("YYYY-MM-DD"), "YYYY-MM-DD"));
                for (var i = 0; i < this.disableDates.length; i++)
                    $(".tble .trow .tcell[data-date='" + this.disableDates[i] + "']")
                        .addClass("disable")
                        .children(".cell-inner")
                        .addClass("disable");
            }),
            (MonthView.prototype.setActive = function (el) {
                $(".month-view .tcell").children(".month-date").removeClass("active"), el.children(".month-date").addClass("active");
            }),
            (MonthView.prototype.refresh = function () {
                return this._loadDates(), this._drawEvent(), [this.monthViewStartDate, this.monthViewEndDate];
            }),
            (MonthView.prototype.reloadEvents = function () {
                this._drawEvent();
            }),
            (MonthView.prototype.getCurrentDateRange = function () {
                return [this.monthViewStartDate, this.monthViewEndDate];
            }),
            (MonthView.prototype.setEventBubbles = function (eventArray) {
                for (var item in ($(".has-event").removeClass("has-event"), Calendar.settings.events)) {
                    var eventYear = moment(Calendar.settings.events[item].start).format(Calendar.settings.ui.year.format),
                        eventMonth = moment(Calendar.settings.events[item].start).format(Calendar.settings.ui.month.format),
                        eventDate = moment(Calendar.settings.events[item].start).format("D");
                    $('.year > [data-year="' + eventYear + '"]').addClass("has-event"),
                        calendar.year == moment(Calendar.settings.events[item].start).format("YYYY") &&
                            ($('.month > [data-month="' + eventMonth + '"]').addClass("has-event"),
                            calendar.month + 1 == moment(Calendar.settings.events[item].start).format("M") && $('.date-selector > .week-date > .day > [data-date="' + eventDate + '"]').addClass("has-event"));
                }
            }),
            (plugin.Calendar = plugin.Calendar || {}),
            (plugin.Calendar = { settings: plugin.settings, yearPicker: null, monthPicker: null, gridLayout: null }),
            (Calendar = {
                Init: function (rebuild) {
                    (this.settings = plugin.settings),
                        (null != Calendar.settings.ui.year && null != Calendar.settings.ui.month && null != Calendar.settings.ui.dateHeader && null != Calendar.settings.ui.week && null != Calendar.settings.ui.grid) ||
                            alert("You have not included the proper ui[] settings, please refer docs"),
                        this._setLocale(),
                        (calendar.monthLong = moment().format(this.settings.ui.month.format)),
                        null != this.settings.now
                            ? ((calendar.month = moment(plugin.settings.now).month()),
                              (calendar.year = moment(plugin.settings.now).year()),
                              (calendar.date = moment(plugin.settings.now).format("D")),
                              (calendar.dayOfWeek = moment(plugin.settings.now).day()),
                              (calendar.monthLong = moment(plugin.now).format("MMM")))
                            : ((calendar.month = moment().month()), (calendar.year = moment().year()), (calendar.date = moment().format("D")), (calendar.dayOfWeek = moment().day()), (calendar.monthLong = moment().format("MMM"))),
                        Calendar.settings.ui.year.visible || $(".calendar-header").hide(),
                        Calendar.settings.ui.month.visible || $("#months").hide(),
                        Calendar.settings.ui.dateHeader.visible || $("#currentDate").hide(),
                        Calendar.settings.ui.week.visible || $("#weeks-wrapper").hide(),
                        (this.yearPicker = new yearSelector("#years")),
                        Calendar.settings.weekends || ((Calendar.settings.ui.week.startOfTheWeek = 1), (Calendar.settings.ui.week.endOfTheWeek = 5)),
                        (this.monthPicker = new monthSelector("#months")),
                        this._buildCurrentDateHeader(),
                        (this.gridLayout = new gridFractory(Calendar.settings.view));
                    var val = this.gridLayout.getCurrentDateRange(),
                        range = { start: val[0], end: val[1] };
                    plugin.settings.onViewRenderComplete(range), !rebuild && this.bindEventHanders(), this.autoFocusActiveElement(), this.scrollToElement("#weeks-wrapper .active");
                    var calendarHeight = "calc(100% - " + ($(".calendar .options").get(0).getBoundingClientRect().height + $(".calendar-header").get(0).getBoundingClientRect().height) + "px)";
                    $("#calendar").css({ height: calendarHeight });
                },
                rebuild: function () {
                    Calendar.Init(!0);
                },
                _highlightWeek: function (elem) {
                    $(".week").removeClass("active"), $(elem).closest(".week").addClass("active");
                },
                _buildCurrentDateHeader: function () {
                    $("#currentDate").text(moment([calendar.year, calendar.month, calendar.date]).format(Calendar.settings.ui.dateHeader.format));
                },
                _onYearChange: function () {
                    this._onMonthChange();
                },
                _onMonthChange: function () {
                    this._onDayChange();
                },
                _onDayChange: function () {
                    this._buildCurrentDateHeader(), null != this.gridLayout.miniCalendar && this.gridLayout.miniCalendar.render();
                    var val = this.gridLayout.refresh(),
                        range = { start: val[0], end: val[1] };
                    plugin.settings.onDateChange(range);
                },
                _loading: function () {
                    $(".grid .tble").prepend("<div class='loading'><div class='progress progress-small'><div class='progress-bar-indeterminate' style='display: block;'></div></div></div>");
                },
                _loaded: function () {
                    $(".grid .tble .loading").remove();
                },
                _errorMessage: function (msg) {
                    $(".grid .tble").prepend("<div class='loading'></div>"),
                        msg &&
                            $(".grid .tble .loading")
                                .pgNotification({
                                    style: "bar",
                                    message: msg,
                                    position: "top",
                                    timeout: 0,
                                    type: "danger",
                                    onClosed: function () {
                                        $(".grid .tble .loading").remove();
                                    },
                                })
                                .show();
                },
                dragHandler: function (element) {
                    var el = $("#" + element),
                        parent = el.parent();
                    if (!$("html").hasClass("mobile") && 1 == el.length) {
                        $(".drager").scrollbar();
                        parent.scrollLeft();
                        interact("#" + element).draggable({
                            preventDefault: "auto",
                            onmove: function (event) {
                                var target = event.target,
                                    x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
                                (inverseX = -x), parent.scrollLeft(inverseX), target.setAttribute("data-x", x);
                            },
                        });
                    }
                },
                autoFocusActiveElement: function () {
                    var timer;
                    $(window).resize(function () {
                        clearTimeout(timer),
                            (timer = setTimeout(function () {
                                Calendar.scrollToElement("#weeks-wrapper .active");
                            }, 500));
                    });
                },
                scrollToElement: function (el) {
                    if (0 == !(el = $(el)).length) {
                        var par = $(el).parent();
                        if (!this._isElementInViewport(el)) {
                            var offset,
                                elOffset = el.offset().left,
                                elHeight = par.children().width(),
                                windowHeight = $(window).width();
                            (offset = elHeight < windowHeight ? elOffset - (windowHeight / 2 - elHeight / 2) : elOffset), $("#weeks-wrapper").parent().animate({ scrollLeft: offset }, 10);
                        }
                    }
                },
                _isElementInViewport: function (el) {
                    "function" == typeof jQuery && el instanceof jQuery && (el = el[0]);
                    var rect = el.getBoundingClientRect();
                    return 0 <= rect.top && 0 <= rect.left && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
                },
                dragMoveListener: function (event) {
                    var target = event.target,
                        x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx,
                        y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
                    (target.style.webkitTransform = target.style.transform = "translate(" + x + "px, " + y + "px)"), target.setAttribute("data-x", x), target.setAttribute("data-y", y);
                },
                nextMonth: function () {
                    (currentYear = moment([calendar.year, calendar.month, calendar.date]).add(1, "months").year()),
                        (currentMonth = moment([calendar.year, calendar.month, calendar.date]).add(1, "months").month()),
                        this._refreshViewOnDateChange();
                },
                previousMonth: function () {
                    (currentYear = moment([calendar.year, calendar.month, calendar.date]).subtract(1, "months").year()),
                        (currentMonth = moment([calendar.year, calendar.month, calendar.date]).subtract(1, "months").month()),
                        this._refreshViewOnDateChange();
                },
                today: function () {
                    (calendar.year = moment().year()), (calendar.month = moment().month()), (calendar.date = moment().format("D")), this._refreshViewOnDateChange();
                },
                _addEvent: function (event) {
                    Calendar.settings.events.push(event), this.gridLayout.reloadEvents();
                },
                _addEvents: function (eventArray) {
                    Calendar.settings.events.concat(eventArray);
                    for (var i = 0; i < eventArray.length; i++) Calendar.settings.events.push(eventArray[i]);
                    this.gridLayout.reloadEvents();
                },
                _deleteEvent: function (index) {
                    if (Calendar.constructEventForUser(index).readOnly) return !1;
                    Calendar.settings.events.splice(parseInt(index), 1), this.gridLayout.reloadEvents();
                },
                _removeAllEvents: function () {
                    Calendar.settings.events = [];
                },
                _updateEvent: function (eventObj) {
                    if (Calendar.constructEventForUser(eventObj.index).readOnly) return !1;
                    (Calendar.settings.events[eventObj.index] = eventObj), this.gridLayout.reloadEvents();
                },
                fitEventsToSlot: function (startSlot) {
                    if (1 == Calendar.settings.eventOverlap) {
                        var events = $(startSlot).children().length,
                            elem = $(startSlot).children();
                        if (1 < events)
                            for (var width = elem.width(), i = 0; i < events; i++) {
                                $(elem[i]).width(width / events), $(elem[i + 1]).css("left", width / 2);
                            }
                        else elem.width("100%"), $(elem).css("left", 0);
                    }
                },
                _changeView: function (view) {
                    (this.gridLayout.layout = view), this.gridLayout.rebuild();
                },
                _getView: function () {
                    return Calendar.settings.view;
                },
                timeSlotDblClick: function (obj) {
                    plugin.settings.onTimeSlotDblClick(this.gridLayout.doubletap(obj));
                },
                bindEventHanders: function () {
                    interact(".cell-inner:not(.disable)").on("doubletap", function (event) {
                        Calendar.timeSlotDblClick($(event.currentTarget)), event.preventDefault();
                    }),
                        interact(".event-container").on("tap", function (event) {
                            var eventO = Calendar.constructEventForUser($(event.currentTarget).attr("data-index"));
                            plugin.settings.onEventClick(eventO);
                        });
                },
                constructEventForUser: function (i) {
                    return {
                        index: i,
                        title: Calendar.settings.events[i].title,
                        class: Calendar.settings.events[i].class,
                        start: Calendar.settings.events[i].start,
                        end: Calendar.settings.events[i].end,
                        allDay: Calendar.settings.events[i].allDay,
                        other: Calendar.settings.events[i].other,
                        readOnly: Calendar.settings.events[i].readOnly,
                    };
                },
                _setLocale: function () {
                    moment.locale(Calendar.settings.locale);
                },
                _refreshViewOnDateChange: function () {
                    this._buildCurrentDateHeader(), this.yearPicker.render(), this.monthPicker.render(), this.gridLayout.refresh(), null != this.gridLayout.miniCalendar && this.gridLayout.miniCalendar.render();
                },
                _setDate: function (d) {
                    (calendar.month = moment(d).month()),
                        (calendar.year = moment(d).year()),
                        (calendar.date = moment(d).format("D")),
                        (calendar.dayOfWeek = moment(d).day()),
                        (calendar.monthLong = moment(d).format("MMM")),
                        this._refreshViewOnDateChange();
                },
                _getDate: function (format) {
                    return null == format && (format = "MMMM Do YYYY"), moment([calendar.year, calendar.month, calendar.date]).format(format);
                },
                _getEventArray: function (option) {
                    if (null == option || "all" == option) return Calendar.settings.events;
                },
                _getDateRangeInView: function () {
                    return this.gridLayout.getCurrentDateRange();
                },
                _scrollToFirstEvent: function () {
                    this.gridLayout.scrollToFirstEvent();
                },
            }),
            Calendar.Init(),
            this
        );
    };
    (PagesCalendar.prototype.rebuild = function () {
        Calendar.rebuild();
    }),
        (PagesCalendar.prototype.today = function () {
            Calendar.today();
        }),
        (PagesCalendar.prototype.next = function () {
            Calendar.nextMonth();
        }),
        (PagesCalendar.prototype.prev = function () {
            Calendar.previousMonth();
        }),
        (PagesCalendar.prototype.setDate = function (date) {
            Calendar._setDate(date);
        }),
        (PagesCalendar.prototype.getDate = function (format) {
            return Calendar._getDate(format);
        }),
        (PagesCalendar.prototype.destroy = function () {}),
        (PagesCalendar.prototype.setLocale = function (lang) {
            (Calendar.settings.locale = lang), Calendar._setLocale(), Calendar.rebuild();
        }),
        (PagesCalendar.prototype.reloadEvent = function () {
            Calendar.loadEvents();
        }),
        (PagesCalendar.prototype.addEvent = function (event) {
            Calendar._addEvent(event);
        }),
        (PagesCalendar.prototype.addEvents = function (eventArray) {
            Calendar._addEvents(eventArray);
        }),
        (PagesCalendar.prototype.removeEvent = function (index) {
            Calendar._deleteEvent(index);
        }),
        (PagesCalendar.prototype.removeAllEvents = function () {
            Calendar._removeAllEvents();
        }),
        (PagesCalendar.prototype.updateEvent = function (eventObj) {
            Calendar._updateEvent(eventObj);
        }),
        (PagesCalendar.prototype.getEvents = function (option) {
            return Calendar._getEventArray(option);
        }),
        (PagesCalendar.prototype.view = function (option) {
            return Calendar._changeView(option);
        }),
        (PagesCalendar.prototype.getView = function () {
            return Calendar._getView();
        }),
        (PagesCalendar.prototype.getDateRangeInView = function () {
            return Calendar._getDateRangeInView();
        }),
        (PagesCalendar.prototype.scrollToFirstEvent = function () {
            return Calendar._scrollToFirstEvent();
        }),
        (PagesCalendar.prototype.setState = function (state) {
            switch (state) {
                case "loading":
                    Calendar._loading();
                    break;
                case "loaded":
                    Calendar._loaded();
            }
        }),
        (PagesCalendar.prototype.error = function (msg) {
            Calendar._errorMessage(msg);
        });
    var old = $.fn.pagescalendar;
    ($.fn.pagescalendar = function (option, obj) {
        var $this = $(this),
            data = $this.data("pagescalendar"),
            options = "object" == typeof option && option;
        return "string" == typeof option
            ? data[option](obj)
            : this.each(function () {
                  data || $this.data("pagescalendar", (data = new PagesCalendar(this, options)));
              });
    }),
        ($.fn.pagescalendar.Constructor = PagesCalendar),
        ($.fn.pagescalendar.defaults = {
            ui: {
                year: { visible: !0, format: "YYYY", startYear: "2000", endYear: moment().add(10, "year").format("YYYY"), eventBubble: !0 },
                month: { visible: !0, format: "MMM", eventBubble: !0 },
                dateHeader: { format: "MMMM YYYY, D dddd", visible: !0 },
                week: { day: { format: "D" }, header: { format: "dd" }, eventBubble: !0, startOfTheWeek: "0", endOfTheWeek: "6", visible: !0 },
                grid: { dateFormat: "D dddd", timeFormat: "h A", eventBubble: !0, scrollToFirstEvent: !1, scrollToAnimationSpeed: 300, scrollToGap: 20 },
            },
            eventObj: { editable: !0 },
            view: "week",
            now: null,
            locale: "en",
            timeFormat: "h:mm a",
            minTime: 0,
            maxTime: 24,
            dateFormat: "MMMM Do YYYY",
            slotDuration: "30",
            events: [],
            eventOverlap: !1,
            weekends: !0,
            disableDates: [],
            onViewRenderComplete: function () {},
            onEventDblClick: function () {},
            onEventClick: function (event) {},
            onEventRender: function () {},
            onEventDragComplete: function (event) {},
            onEventResizeComplete: function (event) {},
            onTimeSlotDblClick: function (timeSlot) {},
            onDateChange: function (range) {},
        }),
        ($.fn.pagescalendar.noConflict = function () {
            return ($.fn.pagescalendar = old), this;
        });
}),
    (function (root, factory) {
        "function" == typeof define && define.amd
            ? define(["moment"], function (a0) {
                  return (root.DateRange = factory(a0));
              })
            : "object" == typeof exports
            ? (module.exports = factory(require("moment")))
            : (root.DateRange = factory(moment));
    })(this, function (moment) {
        var INTERVALS = { year: !0, month: !0, week: !0, day: !0, hour: !0, minute: !0, second: !0 };
        function DateRange(start, end) {
            var parts,
                s = start,
                e = end;
            (1 !== arguments.length && void 0 !== end) || ("object" == typeof start && 2 === start.length ? ((s = start[0]), (e = start[1])) : "string" == typeof start && ((s = (parts = start.split("/"))[0]), (e = parts[1]))),
                (this.start = moment(null === s ? -864e13 : s)),
                (this.end = moment(null === e ? 864e13 : e));
        }
        return (
            ((DateRange.prototype.constructor = DateRange).prototype.clone = function () {
                return moment().range(this.start, this.end);
            }),
            (DateRange.prototype.contains = function (other, exclusive) {
                var start = this.start,
                    end = this.end;
                return other instanceof DateRange ? start <= other.start && (end > other.end || (end.isSame(other.end) && !exclusive)) : start <= other && (other < end || (end.isSame(other) && !exclusive));
            }),
            (DateRange.prototype.overlaps = function (range) {
                return null !== this.intersect(range);
            }),
            (DateRange.prototype.intersect = function (other) {
                var start = this.start,
                    end = this.end;
                return start <= other.start && other.start < end && end < other.end
                    ? new DateRange(other.start, end)
                    : other.start < start && start < other.end && other.end <= end
                    ? new DateRange(start, other.end)
                    : other.start < start && start <= end && end < other.end
                    ? this
                    : start <= other.start && other.start <= other.end && other.end <= end
                    ? other
                    : null;
            }),
            (DateRange.prototype.add = function (other) {
                return this.overlaps(other) ? new DateRange(moment.min(this.start, other.start), moment.max(this.end, other.end)) : null;
            }),
            (DateRange.prototype.subtract = function (other) {
                var start = this.start,
                    end = this.end;
                return null === this.intersect(other)
                    ? [this]
                    : other.start <= start && start < end && end <= other.end
                    ? []
                    : other.start <= start && start < other.end && other.end < end
                    ? [new DateRange(other.end, end)]
                    : start < other.start && other.start < end && end <= other.end
                    ? [new DateRange(start, other.start)]
                    : start < other.start && other.start < other.end && other.end < end
                    ? [new DateRange(start, other.start), new DateRange(other.end, end)]
                    : start < other.start && other.start < end && other.end < end
                    ? [new DateRange(start, other.start), new DateRange(other.start, end)]
                    : void 0;
            }),
            (DateRange.prototype.toArray = function (by, exclusive) {
                var acc = [];
                return (
                    this.by(
                        by,
                        function (unit) {
                            acc.push(unit);
                        },
                        exclusive
                    ),
                    acc
                );
            }),
            (DateRange.prototype.by = function (range, hollaback, exclusive) {
                return (
                    "string" == typeof range
                        ? function (interval, hollaback, exclusive) {
                              var current = moment(this.start);
                              for (; this.contains(current, exclusive); ) hollaback.call(this, current.clone()), current.add(1, interval);
                          }.call(this, range, hollaback, exclusive)
                        : function (interval, hollaback, exclusive) {
                              var div = this / interval,
                                  l = Math.floor(div);
                              if (l === 1 / 0) return;
                              l === div && exclusive && l--;
                              for (var i = 0; i <= l; i++) hollaback.call(this, moment(this.start.valueOf() + interval.valueOf() * i));
                          }.call(this, range, hollaback, exclusive),
                    this
                );
            }),
            (DateRange.prototype.toString = function () {
                return this.start.format() + "/" + this.end.format();
            }),
            (DateRange.prototype.valueOf = function () {
                return this.end - this.start;
            }),
            (DateRange.prototype.center = function () {
                var center = this.start + this.diff() / 2;
                return moment(center);
            }),
            (DateRange.prototype.toDate = function () {
                return [this.start.toDate(), this.end.toDate()];
            }),
            (DateRange.prototype.isSame = function (other) {
                return this.start.isSame(other.start) && this.end.isSame(other.end);
            }),
            (DateRange.prototype.diff = function (unit) {
                return this.end.diff(this.start, unit);
            }),
            (moment.range = function (start, end) {
                return start in INTERVALS ? new DateRange(moment(this).startOf(start), moment(this).endOf(start)) : new DateRange(start, end);
            }),
            (moment.range.constructor = DateRange),
            (moment.fn.range = moment.range),
            (moment.fn.within = function (range) {
                return range.contains(this._d);
            }),
            DateRange
        );
    });

    