document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "announcements") {

        let table_announcements = $('#table-announcements').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-announcements').data('source'),
                data: function(e) {
                    //e.service_id = $("#filter-rate_services").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "title"},
                {"data": "start"},
                {"data": "ends"},
                {"data": "active"},
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="/announcements/${record.id}/edit" class='btn-see-rates' data-service_id='${record.id}' title="Modifier"><i class="fa-solid fa-pen" style="color:white" aria-hidden="true"></i></a>
                                <a href="javascript:void(0)" class='btn-delete-announcement' data-announcement_id='${record.id}' title="Supprimer"><i class="fa-solid fa-trash" aria-hidden="true"></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "initComplete":function( settings, json){
                $(document).on("click", ".btn-delete-announcement", function() {
                    let announcement_id = $(this).attr("data-announcement_id")

                    deleteAnnouncement(announcement_id)
                })
            },
            "columnDefs": [
                {
                    "targets": 4, "orderable": false,
                    "width": '60%', targets: 0,
                    "width": '10%', targets: 1,
                    "width": '10%', targets: 2,
                    "width": '5%', targets: 3,
                }
                
            ],
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bAutoWidth": false,
            'order' : [[3,'desc']]
        });
    
        $(document).on("click", "#btn_delete_announcement_image", function() {
            let id = $(this).attr("data-id")

            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer l'image?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/announcements/delete-image/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                $(".announcement-thumb").remove()
                                $("#btn_delete_announcement_image").remove()
                            })
                        }
                    })
                }
            }).then((result) => {})
        })

        function deleteAnnouncement(announcement_id) {
            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer cette annonce?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/announcements/${announcement_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                table_announcements.ajax.reload()
                            })
                        }
                    })
                }
            }).then((result) => {})
        }
    }
})