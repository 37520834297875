const { get } = require("jquery");
const { duration } = require("moment/moment");
const e = require("turbolinks");

document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "workshops") {
        let modal_workshop
        let remove_holidays = []
        let remove_equipe = []
        let table_workshops;
        let table_brands;
        let services_id = []
        let active_change_duration_winter = false;

        if (document.getElementById("modal-workshop") != null) {
            modal_workshop = new bootstrap.Modal(document.getElementById("modal-workshop"), {backdrop: 'static', keyboard: false});
        }

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_workshops != null)
                table_workshops.destroy();
            if (table_brands != null)
                table_brands.destroy();
        })

        let users_id = []
        let workshop_id;
        let service_to_remove = []
        
        table_workshops = $('#table-workshops').DataTable({
            responsive: true,
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            language: {
                url: '/datatable-fr-FR.json',
            },
            "pageLength": 10,
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });
    
        //if ($('#table-workshops').length > 0) {
            let table_equipe = $('#table-equipe').DataTable({
                "bPaginate": false,
                "bFilter": true,
                "bInfo": false,
                "bLengthChange": false,
                "searching": false,
                "destroy": true,
                "stateSave": false,
                "scrollCollapse": true,
                "pageLength": 10,
                language: {
                    url: '/datatable-fr-FR.json',
                },
                "bAutoWidth": false,
                'order' : [[0,'desc']]
            });
        //}
            
        table_brands = $('#table-workshop-brands').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-workshop-brands').data('source'),
                data: function(e) {
                    e.workshop_id = $("#workshop-id").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "brand"},
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="javascript:void(0)" class='btn-remove-brand' data-brand_id='${record.id}' style="color:white" title="Retirer"><i class="fa-solid fa-trash""></i></a>`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "initComplete":function( settings, json){
                $(document).on("click", ".btn-remove-brand", function() {
                    let brand_id = $(this).attr("data-brand_id")

                    removeBrand(brand_id)
                })
            },
            "columnDefs": [
                {
                    "width": '90%', targets: 0,
                    "width": '1%', targets: 1,
                }
            ],
            "bPaginate": false,
            "bFilter": false,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        $(".workshop_service").on("click", function(e) {
            
            if ($(e.currentTarget).prop("checked") == false) {
                service_to_remove.push($(e.currentTarget).data('service_id'))
            }
            else {
                if (service_to_remove.indexOf($(e.currentTarget).data('service_id')) != -1){
                    service_to_remove.splice(service_to_remove.indexOf($(e.currentTarget).data('service_id')), 1)
                }
            }

            $("#service_to_remove").val(service_to_remove)
        })

        $('#btn-add-user').on("click", function(e) {
            if ($("#workshop_equipe").val() != null ){
                $("#workshop_equipe option:selected").attr("disabled", true)

                let newRow = table_equipe.row.add([
                    $("#workshop_equipe option:selected").text(),
                    "<span class='badge bg-secondary'>Manager</span>",
                    `<a href="javascript:" class="btn-delete-equipe"><i class="fa-solid fa-trash" aria-hidden="true"></i></a>`
                ] ).draw( false ).node();

                $(newRow).attr("data-element_id", $("#workshop_equipe option:selected").val())
                $(newRow).data("element_id", $("#workshop_equipe option:selected").val())

                /* $(".btn-delete-equipe").on("click", function(e) {
                    let element_id = $(e.currentTarget).parent().parent().data('element_id')

                    users_id.splice(users_id.indexOf(element_id), 1)[0];

                    $("#workshop-users").val(users_id)
                    table_equipe.row($(`tr[data-element_id='${element_id}']`)).remove().draw();
                    $(`#workshop_equipe option[value='${element_id}']`).attr("disabled", false)
                })*/

                var selected_user_id = $("#workshop_equipe option:selected").val()

                users_id.push(selected_user_id)

                remove_equipe.splice(remove_equipe.indexOf(parseInt(selected_user_id)), 1)[0]

                
                $("#remove_workshops").val(remove_equipe.join(','))

                $("#workshop-users").val(users_id)
                $("#workshop_equipe").val(0)
            }
        })

        $("#btn-add-brand").on("click", function() {
            const formData = new FormData();

            let workshop_id = $("#workshop-id").val()
            let brand_id = $("#workshop_brands option:selected").val()

            if (brand_id == "0") {
                return
            }

            formData.append('workshop_id', workshop_id);
            formData.append('brand_id', brand_id);

            fetch(`/workshops/join_brand`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                dataType: "json",
                contentType: "application/json; charset=UTF-8",
                processData: false,
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        table_brands.ajax.reload()
                    })
                }
                else {
                    response.json().then(function(data) {
                       
                    })
                }
            })
        })
        
        $("#table-equipe").on("click", ".btn-delete-equipe", function(e) {
            let element_id = $(e.currentTarget).parent().parent().data('element_id')

            remove_equipe.push(element_id)

            $("#remove_workshops").val(remove_equipe.join(','))
            
            $("#workshop-users").val(users_id)
            table_equipe.row($(`tr[data-element_id='${element_id}']`)).remove().draw();
            $(`#workshop_equipe option[value='${element_id}']`).attr("disabled", false)
        })
        
        $(".btn-edit-workshop").on("click", function(e) {
            workshop_id = ($(e.currentTarget).parent().parent().data('element_id'))

            JsLoadingOverlay.show();

            getWorkshopSchedule(workshop_id, $(this).attr("data-type"))

            modal_workshop.show()
        })

        // add new holiday
        $("#btn-new-holiday").on("click", function() {
            let count = $(".schedule_holiday").last().data('index') //$(".schedule_holiday").length
            if (count == undefined)
                count = 0

            $(".schedule_holidays").append(`<div class='d-flex schedule_holiday schedule_holiday_${count+1}' data-index='${count+1}'>
                    <div class='m-2 mt-3'>
                        <a href="javascript:void(0)" title="Supprimer" data-index='${count+1}' class='schedule_holiday_remove'><i class="fa-solid fa-trash"></i></a>
                    </div>
                    <div class='m-2 col-6 text-start'>
                        <input type="text" class="form-control" placeholder="Nom" id="schedule_holiday_name_${count+1}" required>
                    </div>
                    <div class='m-2 text-start'>
                        <input type="date" class="form-control" placeholder="Date" id="schedule_holiday_date_${count+1}" required>
                    </div>
                    <div class='m-2 mt-3 text-start'>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="schedule_holiday_active_${count+1}">
                            <label class="form-check-label" for="schedule_holiday_active_${count+1}">Active</label>
                        </div>
                    </div>
                </div>
            </div>`)
        })
        
        // remove holiday
        $(document).on("click", ".schedule_holiday_remove", function(e) {
            let index = $(this).data("index")

            if ($(`#schedule_holiday_name_${index}`).val() == "" && $(`.schedule_holiday_${index}`).data('id') == null) {
                $(`.schedule_holiday_${index}`).remove()
            }
            else {
                if ($(`#schedule_holiday_name_${index}`).val() == "" || $(`.schedule_holiday_${index}`).data('id') != null) {
                    Swal.fire({
                        title: 'Supprimer?',
                        text: "Voulez-vous vraiment supprimer ce jour?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Annuler',
                        confirmButtonText: 'Oui',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showLoaderOnConfirm: true,
                        preConfirm: () => {
                            remove_holidays.push($(`.schedule_holiday_${index}`).data('id'))
                            return $(`.schedule_holiday_${index}`).remove()
                        }
                    }).then((result) => {})
                }
            }
        })

        // delete workshop
        $("#table-workshops tbody").on("click", ".btn-delete-workshop", function() {
            let id = $(this).data("id")

            deleteWorshop(id)
        })

        // normal morning start
        $(document).on("change", `#horaire-tab-pane .morning-start`, function() {
            let service_id = $("#horaire_services").val()
            let index = $(this).attr('data-index')
            let time = $(this).val()

            if(time == 'off')
                $(`#winter_schedule${service_id} .day-${index} .morning`).addClass('visually-hidden')
            else
                $(`#winter_schedule${service_id} .day-${index} .morning`).removeClass('visually-hidden')
                
            $(`#winter_schedule${service_id} .morning-end[data-index='${index}'] option`).attr('disabled', false)
            $(`#winter_schedule${service_id} .morning-end[data-index='${index}'] option[value='${time}']`).attr('disabled', true)
            if ($(this).prop('selectedIndex') == 1) {
                $(`#winter_schedule${service_id} .morning-end[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
            }
            //$(`#winter_schedule .morning-end[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
        })
        
        // normal morning end
        $(document).on("change", `#horaire-tab-pane .morning-end`, function() {
            let service_id = $("#horaire_services").val()
            let index = $(this).attr('data-index')
            let time = $(this).val()

            if(time == 'off')
                $(`#winter_schedule${service_id} .day-${index} .morning`).addClass('visually-hidden')
            else
                $(`#winter_schedule${service_id} .day-${index} .morning`).removeClass('visually-hidden')
                
            $(`#winter_schedule${service_id} .morning-start[data-index='${index}'] option`).attr('disabled', false)
            $(`#winter_schedule${service_id} .morning-start[data-index='${index}'] option[value='${time}']`).attr('disabled', true)
            //$(`#winter_schedule .morning-start[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
        })

        // normal afternoon start
        $(document).on("change", `#horaire-tab-pane .afternoon-start`, function() {
            let service_id = $("#horaire_services").val()
            let index = $(this).attr('data-index')
            let time = $(this).val()

            if(time == 'off')
                $(`#winter_schedule${service_id} .day-${index} .afternoon`).addClass('visually-hidden')
            else
                $(`#winter_schedule${service_id} .day-${index} .afternoon`).removeClass('visually-hidden')

            $(`#winter_schedule${service_id} .afternoon-end[data-index='${index}'] option`).attr('disabled', false)
            $(`#winter_schedule${service_id} .afternoon-end[data-index='${index}'] option[value='${time}']`).attr('disabled', true)
            if ($(this).prop('selectedIndex') == 1) {
                $(`#winter_schedule${service_id} .afternoon-end[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
            }
            //$(`#winter_schedule .afternoon-end[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
        })
        
        // normal afternoon end
        $(document).on("change", `#horaire-tab-pane .afternoon-end`, function() {
            let service_id = $("#horaire_services").val()
            let index = $(this).attr('data-index')
            let time = $(this).val()

            if(time == 'off')
                $(`.day-${index} .afternoon`).addClass('visually-hidden')
            else
                $(`.day-${index} .afternoon`).removeClass('visually-hidden')

            $(`#winter_schedule${service_id} .afternoon-start[data-index='${index}'] option`).attr('disabled', false)
            $(`#winter_schedule${service_id} .afternoon-start[data-index='${index}'] option[value='${time}']`).attr('disabled', true)
            //$(`#winter_schedule .afternoon-start[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
        })

        // summer morning start
        $(document).on("change", `#horaire2-tab-pane .morning-start`, function() {
            let service_id = $("#horaire_services").val()
            let index = $(this).attr('data-index')
            let time = $(this).val()

            if(time == 'off')
                $(`#summer_schedule${service_id} .day-${index} .morning`).addClass('visually-hidden')
            else
                $(`#summer_schedule${service_id} .day-${index} .morning`).removeClass('visually-hidden')
                
            $(`#summer_schedule${service_id} .morning-end[data-index='${index}'] option`).attr('disabled', false)
            $(`#summer_schedule${service_id} .morning-end[data-index='${index}'] option[value='${time}']`).attr('disabled', true)
            if ($(this).prop('selectedIndex') == 1) {
                $(`#summer_schedule${service_id} .morning-end[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
            }
            //$(`#summer_schedule .morning-end[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
        })

        // summer morning end
        $(document).on("change", `#horaire2-tab-pane .morning-end`, function() {
            let service_id = $("#horaire_services").val()
            let index = $(this).attr('data-index')
            let time = $(this).val()

            if(time == 'off')
                $(`#summer_schedule .day-${index} .morning`).addClass('visually-hidden')
            else
                $(`#summer_schedule .day-${index} .morning`).removeClass('visually-hidden')
                
            $(`#summer_schedule${service_id} .morning-start[data-index='${index}'] option`).attr('disabled', false)
            $(`#summer_schedule${service_id} .morning-start[data-index='${index}'] option[value='${time}']`).attr('disabled', true)
            //$(`#summer_schedule .morning-start[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
        })
        
        /*$("#summer_schedule .afternoon-start").on("click", function() {
            let index = $(this).attr('data-index')
            let time = $(this).val()
            if(time == 'off')
                $(`.day-${index} .afternoon`).addClass('visually-hidden')
            else
                $(`.day-${index} .afternoon`).removeClass('visually-hidden')

            $(`#summer_schedule .afternoon-end[data-index='${index}'] option`).attr('disabled', false)
            $(`#summer_schedule .afternoon-end[data-index='${index}'] option[value='${time}']`).attr('disabled', true)
            $(`#summer_schedule .afternoon-end[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
        })*/

        // ramadan morning start
        $(document).on("change", `#horaire3-tab-pane .morning-start`, function() {
            let service_id = $("#horaire_services").val()
            let index = $(this).attr('data-index')
            let time = $(this).val()

            if(time == 'off')
                $(`#ramadan_schedule${service_id} .day-${index} .morning`).addClass('visually-hidden')
            else
                $(`#ramadan_schedule${service_id} .day-${index} .morning`).removeClass('visually-hidden')
                
            $(`#ramadan_schedule${service_id} .morning-end[data-index='${index}'] option`).attr('disabled', false)
            $(`#ramadan_schedule${service_id} .morning-end[data-index='${index}'] option[value='${time}']`).attr('disabled', true)
            if ($(this).prop('selectedIndex') == 1) {
                $(`#ramadan_schedule${service_id} .morning-end[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
            }
            //$(`#ramadan_schedule .morning-end[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
        })

        // ramadan morning end
        $(document).on("change", `#horaire3-tab-pane .morning-end`, function() {
            let service_id = $("#horaire_services").val()
            let index = $(this).attr('data-index')
            let time = $(this).val()
            if(time == 'off')
                $(`#ramadan_schedule${service_id} .day-${index} .morning`).addClass('visually-hidden')
            else
                $(`#ramadan_schedule${service_id} .day-${index} .morning`).removeClass('visually-hidden')
                
            $(`#ramadan_schedule${service_id} .morning-start[data-index='${index}'] option`).attr('disabled', false)
            $(`#ramadan_schedule${service_id} .morning-start[data-index='${index}'] option[value='${time}']`).attr('disabled', true)
            //$(`#ramadan_schedule .morning-start[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
        })

        /*$("#ramadan_schedule .afternoon-start").on("click", function() {
            let index = $(this).attr('data-index')
            let time = $(this).val()
            if(time == 'off')
                $(`.day-${index} .afternoon`).addClass('visually-hidden')
            else
                $(`.day-${index} .afternoon`).removeClass('visually-hidden')

            $(`#ramadan_schedule .afternoon-end[data-index='${index}'] option`).attr('disabled', false)
            $(`#ramadan_schedule .afternoon-end[data-index='${index}'] option[value='${time}']`).attr('disabled', true)
            $(`#ramadan_schedule .afternoon-end[data-index='${index}'] option[value='${time}']`).next().attr('selected', 'selected');
        })*/


        $("#btn-save-schedule").on('click', function(){
            
            var validator = $("#form_holidays").validate();
            let holidays_total = $('.schedule_holiday').length
   
            $( ".schedule_holiday" ).each(function( index, data ) {
                let indx = $(data).data("index")
                if(!$(`#schedule_holiday_name_${indx}`).valid() || $(`#schedule_holiday_date_${indx}`).val() == "") {
                    return
                }
            })

            /*for (let holiday_day = 1; holiday_day <= holidays_total; holiday_day++) {

                if(!$(`#schedule_holiday_name_${holiday_day}`).valid() || $(`#schedule_holiday_date_${holiday_day}`).val() == "") {
                    return
                }
            }*/

           /* if(!$(`#summer_periode_start`).valid()) {
                return
            }*/

            $("#btn-save-schedule").attr("disabled", true)

            createWorkshop()
        })


        // $("#winter_time_slot_duration").on("change", function() {
        //     let duration = $(this).val()

        //     times_slots_update(duration, "#winter_schedule")
        // })

        // $("#summer_time_slot_duration").on("change", function() {
        //     let duration = $(this).val()

        //     times_slots_update(duration, "#summer_schedule")
        // })

        // $("#ramadan_time_slot_duration").on("change", function() {
        //     let duration = $(this).val()

        //     times_slots_update(duration, "#ramadan_schedule")
        // })

        function times_slots_update(duration, periode_id) {
            $(`${periode_id} .morning-start`).filter(function()  {
                
                $(this).empty()
                let time = moment(new Date("2023-08-19 7:00"))
                
                $(this).append(`<option value='off'>OFF</option>`)
                $(this).append(`<option value='${moment(time).format("h:mm")}'>${moment(time).format("h:mm")}</option>`)
                
                let end = 12
                if (periode_id.includes("#summer_schedule") || periode_id.includes("#ramadan_schedule")) {
                    end = 18
                }

                while(moment(time).get('H') <= end ) {
                    time = moment(new Date(`2023-08-19 ${moment(time).format("k:mm")}`)).add(duration, "minutes")

                    if (moment(time).get('H') <= end) {
                        $(this).append(`<option value='${moment(time).format("H:mm")}'>${moment(time).format("H:mm")}</option>`)
                    }
                }
            })
            
            $(`${periode_id} .morning-end`).filter(function()  {
                $(this).empty()
                let time = moment(new Date("2023-08-19 7:00"))
                $(this).append(`<option value='${moment(time).format("h:mm")}'>${moment(time).format("h:mm")}</option>`)
                
                let end = 12
                if (periode_id.includes("#summer_schedule") || periode_id.includes("#ramadan_schedule")) {
                    end = 18
                }

                while(moment(time).get('H') <= end ) {
                    time = moment(new Date(`2023-08-19 ${moment(time).format("k:mm")}`)).add(duration, "minutes")
               
                    if (moment(time).get('H') <= end) {
                        $(this).append(`<option value='${moment(time).format("H:mm")}'>${moment(time).format("H:mm")}</option>`)
                    }
                }
            })

            $(`${periode_id} .afternoon-start`).filter(function()  {
                $(this).empty()
                let time = moment(new Date("2023-08-19 14:00"))
                $(this).append(`<option value='off'>OFF</option>`)
                $(this).append(`<option value='${moment(time).format("H:mm")}'>${moment(time).format("H:mm")}</option>`)
                
                while(moment(time).get('H') <= 18 ) {
                    time = moment(new Date(`2023-08-19 ${moment(time).format("H:mm")}`)).add(duration, "minutes")
               
                    if (moment(time).get('H') <= 18) {
                        $(this).append(`<option value='${moment(time).format("H:mm")}'>${moment(time).format("H:mm")}</option>`)
                    }
                }
            })

            $(`${periode_id} .afternoon-end`).filter(function()  {
                $(this).empty()
                let time = moment(new Date("2023-08-19 14:00"))
                $(this).append(`<option value='${moment(time).format("H:mm")}'>${moment(time).format("H:mm")}</option>`)

                while(moment(time).get('H') <= 18 ) {
                    time = moment(new Date(`2023-08-19 ${moment(time).format("H:mm")}`)).add(duration, "minutes")
               
                    if (moment(time).get('H') <= 18) {
                        $(this).append(`<option value='${moment(time).format("H:mm")}'>${moment(time).format("H:mm")}</option>`)
                    }
                }
            })
        }
        
        $("#horaire_services").on("change", function() {
            $(".tab-pane.active .modal-body:not(#horaire4-tab-pane > div, #horaire5-tab-pane > div)").addClass("d-none")

            let service_id = $(this).val()
            
            if ($("#horaire_services option:selected").attr("data-slug") == "entretien-périodique") {
                if ($(`#winter_time_slot_duration_service_${service_id}`).val() == "60") {
                    $("#horaire-tab-pane").append(`
                        <div class="form-check text-start active_change_duration">
                            <input type="checkbox" class="form-check-input" id="active_change_duration">
                            <label class="form-check-label" for="active_change_duration">Activer l'option de 2 heures lorsque le kilométrage atteint 40 000</label>
                        </div>
                    `)

                    $("#active_change_duration").attr("checked", active_change_duration_winter)
                }
                else {
                    $(".active_change_duration").remove()
                }
            }
            else {
                $(".active_change_duration").remove()
            }

            $(`.content_service`).addClass("d-none")
            $(`.content_service_${$(this).val()}`).removeClass("d-none")
        })

        $("#horaire-tab").on("click", function() {
            $(".tab-pane.active .modal-body").addClass("d-none")
            
            let service_id = $("#horaire_services").val()
            
            $(`.content_service_${service_id}`).removeClass("d-none")
        })

        function getWorkshopSchedule(workshop_id, type) {
            fetch(`/workshops/${workshop_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        
                        services_id = []
                        periode_active = ""
                        active_change_duration_winter = false

                        $(`#horaire_services`).html('')
                        $("#horaire-tab-pane").html('')
                        $("#horaire2-tab-pane").html('')
                        $("#horaire3-tab-pane").html('')

                        data.services.forEach(function(service, serv_index) {
                            services_id.push(service.id)

                            let duration = []
                            let horaire_morning = []
                            let horaire_afternoon = []
                            let min_time_prior_booking = []

                            if (service.service.slug == "entretien-périodique") {
                                active_change_duration_winter = service.service.active_change_duration

                            }

                            let days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

                            for (let dur = 15; dur <= 120; dur += 5) {
                                duration.push(`<option value='${dur}'>${dur}</option>`)              
                            } 

                            for (let horaire = 7; horaire <= 12; horaire++) {
                                horaire_morning.push(`<option value='${horaire}:00'>${horaire}:00</option>`)
                            }

                            for (let horaire = 13; horaire <= 20; horaire++) {
                                horaire_afternoon.push(`<option value='${horaire}:00'>${horaire}:00</option>`)
                            }

                            for (let time = 1; time <= 24; time++) {
                                min_time_prior_booking.push(`<option value='${time}'>${time}h</option>`)
                            }

                            // parameters
                            $("#horaire5-tab-pane").append(`
                                <div class="modal-body ${serv_index > 0 ? 'd-none' : ''} content_service content_service_${service.id}">
                                    <div class='row mb-2'>
                                        <div class='col-5'>
                                            <label>Temps minimal avant une réservation</label>
                                            <select class='form-select' id='service-min_time_booking_${service.id}' name="workshop[min_time_prior_booking_${service.id}]">
                                                <option value=''>Désactivé</option>
                                                ${min_time_prior_booking}
                                            </select> 
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col">
                                            <div class="form-check text-start">
                                                <input type="radio" class="form-check-input" name="workshop[appointment_acceptation_${service.id}]" id="service-appointment_acceptation_no_${service.id}" data-accept='false' value="no">
                                                <label class="form-check-label" for="service-appointment_acceptation_no_${service.id}">Ajouter les nouveaux rendez-vous à la liste d'attente.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col">
                                            <div class="form-check text-start">
                                                <input type="radio" class="form-check-input" name="workshop[appointment_acceptation_${service.id}]" id="service-appointment_acceptation_yes_${service.id}" data-accept='true' value="yes">
                                                <label class="form-check-label" for="service-appointment_acceptation_yes_${service.id}">Accepter automatiquement les nouveaux rendez-vous.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            `)

                            // winter
                            $("#horaire-tab-pane").append(`
                                <div class="modal-body ${serv_index > 0 ? 'd-none' : ''} content_service content_service_${service.id}">
                                    <div class="d-flex flex-row mb-3">
                                        <div class="mt-2 pe-2">Durée: </div>
                                        <div >
                                            <select class='form-select' id="winter_time_slot_duration_service_${service.id}">
                                                ${duration}
                                            </select> 
                                        </div>
                                        <div class='mt-2 ps-2'> minutes</div>
                                        <div class="d-flex ms-4">
                                            <div class="mt-2 pe-2">Capacité: </div>
                                            <div>
                                                <input type="number" class="form-control w-50" id="winter_service_capacity_service_${service.id}" min="1">
                                            </div>
                                        </div>
                                    </div>
                                    <table width='100%' id='winter_schedule${service.id}'>
                                        <thead>
                                            <tr class='text-center'>
                                                <th>Matin</th>
                                                <th>Après-midi</th>
                                            </tr>
                                        <thead>
                                        <tbody class='body_horaire_service_${service.id}'>
                                        </tbody>
                                    </table>
                                </div>
                            `)

                            days.forEach((day, index) => {
                                $(`#horaire-tab-pane .body_horaire_service_${service.id}`).append(`
                                    <tr class='day-${index}'>
                                        <td width='50%'>
                                            <div class='row mb-2'>
                                                <div class='col-3 text-start'>
                                                    ${day}
                                                </div>
                                                <div class='col-4 justify-content-md-center'>
                                                    <select class='form-select morning-start' data-index='${index}'>
                                                        <option value='off'>OFF</option>
                                                        ${horaire_morning}
                                                    </select> 
                                                </div>
                                                <div class='col-1 morning visually-hidden'>à</div>
                                                <div class='col-4 morning visually-hidden'>
                                                    <select class='form-select morning-end' data-index='${index}'>
                                                        ${horaire_morning}
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                        <td style='padding-left: 32px;'>
                                            <div class='row mb-2 justify-content-md-left'>
                                                <div class='col-5'>
                                                    <select class='form-select afternoon-start' data-index='${index}'>
                                                        <option value='off'>OFF</option>
                                                        ${horaire_afternoon}
                                                    </select> 
                                                </div>
                                                <div class='col-1 afternoon visually-hidden'>à</div>
                                                <div class='col-5 afternoon visually-hidden'>
                                                    <select class='form-select afternoon-end' data-index='${index}'>
                                                        ${horaire_afternoon}
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                `)
                            })
                        
                            // summer
                            $("#horaire2-tab-pane").append(`
                                <div class="modal-body ${serv_index > 0 ? 'd-none' : ''} content_service content_service_${service.id}">
                                    <div class='row mb-3'>
                                        <div class='col-3 text-start'>
                                            <div class="form-group">
                                                <label for="summer_periode_start_service${service.id}">Début de période</label>
                                                <input type="date" class="form-control" id="summer_periode_start_service${service.id}" required>
                                            </div>
                                        </div>
                                        <div class='col-3 text-start'>
                                            <div class="form-group">
                                                <label for="summer_periode_end_service${service.id}">Fin de période</label>
                                                <input type="date" class="form-control" id="summer_periode_end_service${service.id}" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-row">
                                        <div class="mt-2 pe-2">Durée: </div>
                                        <div>
                                            <select class='form-select' id="summer_time_slot_duration_service_${service.id}">
                                                ${duration}
                                            </select> 
                                        </div>
                                        <div class='mt-2 ps-2'> minutes</div>
                                        <div class="d-flex ms-4">
                                            <div class="mt-2 pe-2">Capacité: </div>
                                            <div>
                                                <input type="number" class="form-control w-50" id="summer_service_capacity_service_${service.id}" min="1">
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <table width='100%' id='summer_schedule${service.id}'>
                                        <tbody class='body_horaire_service_${service.id}'>
                                        </tbody>
                                    </table>
                                </div>
                            `)

                            days.forEach((day, index) => {
                                $(`#horaire2-tab-pane .body_horaire_service_${service.id}`).append(`
                                    <tr class='day-${index}'>
                                        <td width='50%'>
                                            <div class='row mb-2'>
                                                <div class='col-3 text-start'>
                                                    ${day}
                                                </div>
                                                <div class='col-4 justify-content-md-center'>
                                                    <select class='form-select morning-start' data-index='${index}'>
                                                        <option value='off'>OFF</option>
                                                        ${horaire_morning}
                                                    </select> 
                                                </div>
                                                <div class='col-1 morning visually-hidden'>à</div>
                                                <div class='col-4 morning visually-hidden'>
                                                    <select class='form-select morning-end' data-index='${index}'>
                                                        ${horaire_morning}
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                `)
                            })

                            // ramadan
                            $("#horaire3-tab-pane").append(`
                                <div class="modal-body ${serv_index > 0 ? 'd-none' : ''} content_service content_service_${service.id}">
                                    <div class='row mb-3'>
                                        <div class='col-3 text-start'>
                                            <div class="form-group">
                                                <label for="ramadan_periode_start_service${service.id}">Début de période</label>
                                                <input type="date" class="form-control" id="ramadan_periode_start_service${service.id}" required>
                                            </div>
                                        </div>
                                        <div class='col-3 text-start'>
                                            <div class="form-group">
                                                <label for="ramadan_periode_end_service${service.id}">Fin de période</label>
                                                <input type="date" class="form-control" id="ramadan_periode_end_service${service.id}" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-row">
                                        <div class="mt-2 pe-2">Durée: </div>
                                        <div>
                                            <select class='form-select' id="ramadan_time_slot_duration_service_${service.id}">
                                                ${duration}
                                            </select> 
                                        </div>
                                        <div class='mt-2 ps-2'> minutes</div>
                                        <div class="d-flex ms-4">
                                            <div class="mt-2 pe-2">Capacité: </div>
                                            <div>
                                                <input type="number" class="form-control w-50" id="ramadan_service_capacity_service_${service.id}" min="1">
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <table width='100%' id='ramadan_schedule${service.id}'>
                                        <tbody class='body_horaire_service_${service.id}'>
                                        </tbody>
                                    </table>
                                </div>
                            `)

                            days.forEach((day, index) => {
                                $(`#horaire3-tab-pane .body_horaire_service_${service.id}`).append(`
                                    <tr class='day-${index}'>
                                        <td width='50%'>
                                            <div class='row mb-2'>
                                                <div class='col-3 text-start'>
                                                    ${day}
                                                </div>
                                                <div class='col-4 justify-content-md-center'>
                                                    <select class='form-select morning-start' data-index='${index}'>
                                                        <option value='off'>OFF</option>
                                                        ${horaire_morning}
                                                    </select> 
                                                </div>
                                                <div class='col-1 morning visually-hidden'>à</div>
                                                <div class='col-4 morning visually-hidden'>
                                                    <select class='form-select morning-end' data-index='${index}'>
                                                        ${horaire_morning}
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                `)
                            })
                            

                            // Services 
                            $(`#horaire_services`).append(`<option value='${service.id}' data-slug="${service.service.slug}">${service.service.name}</option>`)

                            $(`#winter_time_slot_duration_service_${service.id}`).on("change", function() {
                                let duration = $(this).val()
      
                                if ($(`#horaire_services option:selected`).attr("data-slug") == "entretien-périodique") {
                                    if (duration == "60") {
                                        $("#horaire-tab-pane").append(`
                                            <div class="form-check text-start active_change_duration">
                                                <input type="checkbox" class="form-check-input" id="active_change_duration">
                                                <label class="form-check-label" for="active_change_duration">Activer l'option de 2 heures lorsque le kilométrage atteint 40 000</label>
                                            </div>
                                        `)

                                        active_change_duration_winter = service.service.active_change_duration
                                        $("#active_change_duration").attr("checked", active_change_duration_winter)
                                    }
                                    else {
                                        $(".active_change_duration").remove()
                                    }
                                }
                                else {
                                    $(".active_change_duration").remove()
                                }

                                times_slots_update(duration, `#winter_schedule${service.id}`)
                            })
                            
                            $(`#summer_time_slot_duration_service_${service.id}`).on("change", function() {
                                let duration = $(this).val()
                    
                                times_slots_update(duration, `#summer_schedule${service.id}`)
                            })
                    
                            $(`#ramadan_time_slot_duration_service_${service.id}`).on("change", function() {
                                let duration = $(this).val()
                    
                                times_slots_update(duration, `#ramadan_schedule${service.id}`)
                            })

                            //schedules 
                            data.services_schedule.forEach((service_schedule, schedule_index) => {
                                // winter
                                if (service_schedule.workshop_schedule_winter.length > 0) {
                                    $(`#winter_time_slot_duration_service_${service_schedule.service_id}`).val(service_schedule.workshop_schedule_winter[0].duration == "" ? "15" : service_schedule.workshop_schedule_winter[0].duration)
                                    $(`#winter_service_capacity_service_${service_schedule.service_id}`).val(service_schedule.workshop_schedule_winter[0].capacity == "" ? "1" : service_schedule.workshop_schedule_winter[0].capacity)
                                }

                                let dur = $(`#winter_time_slot_duration_service_${service_schedule.service_id}`).val()
                                times_slots_update(dur, `#winter_schedule${service_schedule.service_id}`)
                                
                                if (service_schedule.workshop_schedule_winter.length > 0) {
                                    if (service_schedule.workshop_schedule_winter[0].active == true) {
                                        $(`input[id='schedule_winter_active']`).prop("checked", true)
                                        $("#form_holidays .nav-link2").removeClass("active")
                                        $("#horaire-tab").addClass("active")
                                        
                                        $("#workshop-tab-content .tab-pane").removeClass("active show")
                                        $("#horaire-tab-pane").addClass("active show")

                                        periode_active = "winter"
                                    }
                                }
                                else {
                                    $(`#winter_service_capacity_${service_schedule.service_id}`).val(1)
                                }

                                for (day of service_schedule.workshop_schedule_winter) {
                                    if (day.morning_start != 'off') {
                                        $(`#winter_schedule${service_schedule.service_id} .day-${day.day} .morning`).removeClass('visually-hidden')

                                        $(`#winter_schedule${service_schedule.service_id} .morning-start[data-index='${day.day}']`).val(day.morning_start)
                                        $(`#winter_schedule${service_schedule.service_id} .morning-end[data-index='${day.day}']`).val(day.morning_end)
                                    }
                                    else {
                                        $(`#winter_schedule${service_schedule.service_id} .day-${day.day} .morning`).addClass('visually-hidden')
                                    }

                                    if (day.afternoon_start != 'off') {
                                        $(`#winter_schedule${service_schedule.service_id} .day-${day.day} .afternoon`).removeClass('visually-hidden')

                                        $(`#winter_schedule${service_schedule.service_id} .afternoon-start[data-index='${day.day}']`).val(day.afternoon_start)
                                        $(`#winter_schedule${service_schedule.service_id} .afternoon-end[data-index='${day.day}']`).val(day.afternoon_end)
                                    }
                                    else {
                                        $(`#winter_schedule${service_schedule.service_id} .day-${day.day} .afternoon`).addClass('visually-hidden')
                                    }
                                }
                            
                                // summer
                                if (service_schedule.workshop_schedule_summer.length > 0) {
                                    $(`#summer_time_slot_duration_service_${service_schedule.service_id}`).val(service_schedule.workshop_schedule_summer[0].duration == "" ? "15" : service_schedule.workshop_schedule_summer[0].duration)
                                    $(`#summer_service_capacity_service_${service_schedule.service_id}`).val(service_schedule.workshop_schedule_summer[0].capacity == "" ? "1" : service_schedule.workshop_schedule_summer[0].capacity)
                                    $(`#summer_periode_start_service${service_schedule.service_id}`).val(moment(service_schedule.workshop_schedule_summer[0].start).format('YYYY-MM-DD'))
                                    $(`#summer_periode_end_service${service_schedule.service_id}`).val(moment(service_schedule.workshop_schedule_summer[0].end).format('YYYY-MM-DD'))
                                }

                                dur = $(`#summer_time_slot_duration_service_${service_schedule.service_id}`).val()
                                times_slots_update(dur, `#summer_schedule${service_schedule.service_id}`)
                                
                                if (service_schedule.workshop_schedule_summer.length > 0) {
                                    if (service_schedule.workshop_schedule_summer[0].active == true) {
                                        $(`input[id='schedule_summer_active']`).prop("checked", true)
                                        $("#form_holidays .nav-link2").removeClass("active")
                                        $("#horaire2-tab").addClass("active")
                                        
                                        $("#workshop-tab-content .tab-pane").removeClass("active show")
                                        $("#horaire2-tab-pane").addClass("active show")

                                
                                        periode_active = "summer"
                                    }
                                }
                                else {
                                    $(`#summer_service_capacity_${service_schedule.service_id}`).val(1)
                                }

                                for (day of service_schedule.workshop_schedule_summer) {
                                    if (day.morning_start != 'off') {
                                        $(`#summer_schedule${service_schedule.service_id} .day-${day.day} .morning`).removeClass('visually-hidden')

                                        $(`#summer_schedule${service_schedule.service_id} .morning-start[data-index='${day.day}']`).val(day.morning_start)
                                        $(`#summer_schedule${service_schedule.service_id} .morning-end[data-index='${day.day}']`).val(day.morning_end)
                                    }
                                    else {
                                        $(`#summer_schedule${service_schedule.service_id} .day-${day.day} .morning`).addClass('visually-hidden')
                                    }
                                }

                                // ramadan
                                if (service_schedule.workshop_schedule_ramadan.length > 0) {
                                    $(`#ramadan_time_slot_duration_service_${service_schedule.service_id}`).val(service_schedule.workshop_schedule_ramadan[0].duration == "" ? "15" : service_schedule.workshop_schedule_ramadan[0].duration)
                                    $(`#ramadan_service_capacity_service_${service_schedule.service_id}`).val(service_schedule.workshop_schedule_ramadan[0].capacity == "" ? "1" : service_schedule.workshop_schedule_ramadan[0].capacity)
                                    $(`#ramadan_periode_start_service${service_schedule.service_id}`).val(moment(service_schedule.workshop_schedule_ramadan[0].start).format('YYYY-MM-DD'))
                                    $(`#ramadan_periode_end_service${service_schedule.service_id}`).val(moment(service_schedule.workshop_schedule_ramadan[0].end).format('YYYY-MM-DD'))
                                }

                                dur = $(`#ramadan_time_slot_duration_service_${service_schedule.service_id}`).val()
                                times_slots_update(dur, `#ramadan_schedule${service_schedule.service_id}`)
                                
                                if (service_schedule.workshop_schedule_ramadan.length > 0) {
                                    if (service_schedule.workshop_schedule_ramadan[0].active == true) {
                                        $(`input[id='schedule_ramadan_active']`).prop("checked", true)
                                        $("#form_holidays .nav-link2").removeClass("active")
                                        $("#horaire3-tab").addClass("active")
                                        
                                        $("#workshop-tab-content .tab-pane").removeClass("active show")
                                        $("#horaire3-tab-pane").addClass("active show")

                                        periode_active = "ramadan"
                                    }
                                }
                                else {
                                    $(`#ramadan_service_capacity_${service_schedule.service_id}`).val(1)
                                }

                                for (day of service_schedule.workshop_schedule_ramadan) {
                                    if (day.morning_start != 'off') {
                                        $(`#ramadan_schedule${service_schedule.service_id} .day-${day.day} .morning`).removeClass('visually-hidden')

                                        $(`#ramadan_schedule${service_schedule.service_id} .morning-start[data-index='${day.day}']`).val(day.morning_start)
                                        $(`#ramadan_schedule${service_schedule.service_id} .morning-end[data-index='${day.day}']`).val(day.morning_end)
                                    }
                                    else {
                                        $(`#ramadan_schedule${service_schedule.service_id} .day-${day.day} .morning`).addClass('visually-hidden')
                                    }
                                }


                            })

                        })

                        $("#horaire-tab-pane").append(`
                            <div class="form-check text-start">
                                <input type="radio" class="form-check-input" name="schedule_active" id="schedule_winter_active" value="winter">
                                <label class="form-check-label" for="schedule_winter_active">Activer horaire double séance</label>
                            </div>
                        `)

                        $("#horaire2-tab-pane").append(`
                            <div class="form-check text-start">
                                <input type="radio" class="form-check-input"  name="schedule_active" id="schedule_summer_active" value="summer">
                                <label class="form-check-label" for="schedule_summer_active">Activer horaire séance unique</label>
                            </div>
                        `)

                        $("#horaire3-tab-pane").append(`
                            <div class="form-check text-start">
                                <input type="radio" class="form-check-input" name="schedule_active" id="schedule_ramadan_active" value="ramadan">
                                <label class="form-check-label" for="schedule_ramadan_active">Active horaire Ramadan</label>
                            </div>
                        `)

                        
                        if (periode_active == "winter") {
                            $(`input[id='schedule_winter_active']`).prop("checked", true)
                        }
                        else if (periode_active == "summer") {
                            $(`input[id='schedule_summer_active']`).prop("checked", true)
                        }
                        else if (periode_active == "ramadan") {
                            $(`input[id='schedule_ramadan_active']`).prop("checked", true)
                        }
                        else {
                            $("#schedule_winter_active").prop("checked", true)
                        }
                        
                        // services
                        $(".workshop_services").html('')
                        for (service of data.services) {
                            $(".workshop_services").append(`
                                <div class='row'>
                                    <span>${service.service.name}</span>
                                    <div class='col-2 mb-3'>
                                        <div class="form-floating"> 
                                            <input type='number' min='1' class='form-control ms-2 workshop_service_duration' data-workshop_service_id='${service.id}' style='width:100px' id='workshop_service_${service.id}' value='${service.duration}'>
                                            <label>Heure*</label>
                                        </div>
                                    </div>
                                    <div class='col-6 mb-3'>
                                        <div class="form-floating"> 
                                            <input type='number' min='1' class='form-control ms-2 workshop_service_capacity' data-workshop_capacity='${service.id}' style='width:100px' id='workshop_capacity_${service.id}' value='${service.capacity}'>
                                            <label>Capacité*</label>
                                        </div>
                                    </div>
                                </div>
                            `)
                        }
                        
                        data.services.forEach(function(service) {
                         
                            if ($("#horaire_services option:selected").attr("data-slug") == "entretien-périodique" && service.service.slug == "entretien-périodique") {
                                if ($(`#winter_time_slot_duration_service_${service.id} option:selected`).val() == "60") {
                                    $("#horaire-tab-pane").append(`
                                        <div class="form-check text-start active_change_duration">
                                            <input type="checkbox" class="form-check-input" id="active_change_duration">
                                            <label class="form-check-label" for="active_change_duration">Activer l'option de 2 heures lorsque le kilométrage atteint 40 000</label>
                                        </div>
                                    `)
        
                                    $("#active_change_duration").attr("checked", active_change_duration_winter)
                                    
                                }

                                return
                            }
                        })

                        // holidays
                        var i = 1;
                        for (holiday of data.holidays) {
                            
                            $(".schedule_holidays").append(`<div class='d-flex schedule_holiday schedule_holiday_${i}' data-id="${holiday.id}" data-index='${i}'>
                                    <div class='m-2 mt-3'>
                                        <a href="javascript:void(0)" title="Supprimer" data-index='${i}' class='schedule_holiday_remove'><i class="fa-solid fa-trash"></i></a>
                                    </div>
                                    <div class='m-2 col-6 text-start'>
                                        <input type="text" class="form-control" placeholder="Nom" id="schedule_holiday_name_${i}" required>
                                    </div>
                                    <div class='m-2 text-start'>
                                        <input type="date" class="form-control" placeholder="Date" id="schedule_holiday_date_${i}" required>
                                    </div>
                                    <div class='m-2 mt-3 text-start'>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="schedule_holiday_active_${i}">
                                            <label class="form-check-label" for="schedule_holiday_active_${i}">Active</label>
                                        </div>
                                    </div>
                                </div>
                            </div>`)

                            $(`#schedule_holiday_name_${i}`).val(holiday.name)
                            $(`#schedule_holiday_date_${i}`).val(moment(holiday.date).format('YYYY-MM-DD'))
                            $(`#schedule_holiday_active_${i}`).prop("checked", holiday.active)
                            
                            i++
                        }

                        $("#btn-save-schedule").html(`<i class="fa-solid fa-floppy-disk"></i> Enregistrer`)
                        $("#btn-save-schedule").attr("disable", false)

                        $("#workshop-tab-content").removeClass("d-none")

                        if (type == "service") {
                            $("#horaire_service-tab").addClass("active")
                            $(".nav-link2").removeClass("active")
                            $(".tab-pane").removeClass("show active")
                            $("#horaire_service-tab-pane").addClass("active show")
                            $("#horaire_service-tab").removeClass("d-none")
                            $("#horaire_service-tab").addClass("active show")

                            $("#horaire-tab").addClass("d-none")
                            $("#horaire2-tab").addClass("d-none")
                            $("#horaire3-tab").addClass("d-none")
                            $("#horaire4-tab").addClass("d-none")
                        }
                        else {
                            $("#horaire_service-tab").addClass("d-none")

                            $("#horaire-tab").removeClass("d-none")
                            $("#horaire2-tab").removeClass("d-none")
                            $("#horaire3-tab").removeClass("d-none")
                            $("#horaire4-tab").removeClass("d-none")

                        }

                        // parameters
                        data.workshop_services.forEach(function(service) {
                            $(`#service-min_time_booking_${service.id}`).val(service.min_time_prior_booking)
                            if (service.accept_auto_new_reservation)
                                $(`#service-appointment_acceptation_yes_${service.id}`).attr("checked", true)
                            else
                                $(`#service-appointment_acceptation_no_${service.id}`).attr("checked", true)
                        })
                        
                        JsLoadingOverlay.hide();

                    })
                }
            })
        }

        function createWorkshop() {
            $("#btn-save-schedule").html(`<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>`)
            $("#btn-save-schedule").attr("disable", true)

            const formData = new FormData();
            let schedule = []

            services_id.forEach(service_id => {
                
                let winter_total_days = $(`#winter_schedule${service_id} .morning-start`).length
                let winter_duration = $(`#winter_time_slot_duration_service_${service_id}`).val()
                let winter_capacity = $(`#winter_service_capacity_service_${service_id}`).val()
                let active_change_duration = $("#active_change_duration").prop("checked")
                let summer_total_days = $(`#summer_schedule${service_id} .morning-start`).length
                let summer_start = $(`#summer_periode_start_service${service_id}`).val()
                let summer_end = $(`#summer_periode_end_service${service_id}`).val()
                let summer_duration = $(`#summer_time_slot_duration_service_${service_id}`).val()
                let summer_capacity = $(`#summer_service_capacity_service_${service_id}`).val()
                let ramadan_total_days = $(`#ramadan_schedule${service_id} .morning-start`).length
                let ramadan_start = $(`#ramadan_periode_start_service${service_id}`).val()
                let ramadan_end = $(`#ramadan_periode_end_service${service_id}`).val()
                let ramadan_duration = $(`#ramadan_time_slot_duration_service_${service_id}`).val()
                let ramadan_capacity = $(`#ramadan_service_capacity_service_${service_id}`).val()
                let min_time_prior_booking = $(`#service-min_time_booking_${service_id} option:selected`).val()
                let accept_auto_new_reservation = $(`#service-appointment_acceptation_yes_${service_id}`).prop("checked") ? true : false

                formData.append(`service${service_id}[winter_total_days]`, winter_total_days);
                formData.append(`service${service_id}[winter_duration]`, winter_duration);
                formData.append(`service${service_id}[winter_capacity]`, winter_capacity);
                formData.append(`service${service_id}[active_change_duration]`, active_change_duration);
                formData.append(`service${service_id}[summer_total_days]`, summer_total_days);
                formData.append(`service${service_id}[summer_start]`, summer_start);
                formData.append(`service${service_id}[summer_end]`, summer_end);
                formData.append(`service${service_id}[summer_duration]`, summer_duration);
                formData.append(`service${service_id}[summer_capacity]`, summer_capacity);
                formData.append(`service${service_id}[ramadan_total_days]`, ramadan_total_days);
                formData.append(`service${service_id}[ramadan_start]`, ramadan_start);
                formData.append(`service${service_id}[ramadan_end]`, ramadan_end);
                formData.append(`service${service_id}[ramadan_duration]`, ramadan_duration);
                formData.append(`service${service_id}[min_time_prior_booking]`, min_time_prior_booking);
                formData.append(`service${service_id}[accept_auto_new_reservation]`, accept_auto_new_reservation);
                
                // winter
                for (let current_day = 0; current_day < winter_total_days; current_day++) {
                    let morning_start = $(`#winter_schedule${service_id} .morning-start[data-index=${current_day}] option:selected`).val()
                    let morning_end = $(`#winter_schedule${service_id} .morning-end[data-index=${current_day}] option:selected`).val()
                    let afternoon_start = $(`#winter_schedule${service_id} .afternoon-start[data-index=${current_day}] option:selected`).val()
                    let afternoon_end = $(`#winter_schedule${service_id} .afternoon-end[data-index=${current_day}] option:selected`).val()

                    let day =[morning_start, morning_end, afternoon_start, afternoon_end]

                    schedule.push(day)
                    formData.append(`service${service_id}[winter_day_${current_day}]`, day);
                }

                // summer
                for (let current_day = 0; current_day < summer_total_days; current_day++) {
                    let morning_start = $(`#summer_schedule${service_id} .morning-start[data-index=${current_day}] option:selected`).val()
                    let morning_end = $(`#summer_schedule${service_id} .morning-end[data-index=${current_day}] option:selected`).val()

                    let day =[morning_start, morning_end]

                    schedule.push(day)
                    formData.append(`service${service_id}[summer_day_${current_day}]`, day);
                }

                // ramadan
                for (let current_day = 0; current_day < ramadan_total_days; current_day++) {
                    let morning_start = $(`#ramadan_schedule${service_id} .morning-start[data-index=${current_day}] option:selected`).val()
                    let morning_end = $(`#ramadan_schedule${service_id} .morning-end[data-index=${current_day}] option:selected`).val()

                    let day =[morning_start, morning_end]

                    schedule.push(day)
                    formData.append(`service${service_id}[ramadan_day_${current_day}]`, day);
                }

            });

            let active = $(`input[name='schedule_active']:checked`).val()
            let holidays_total = $(`.schedule_holiday`).length
            
            formData.append(`active`, active);
            formData.append('holidays_total', holidays_total);
        
            // // summer
            // for (let current_day = 0; current_day < summer_total_days; current_day++) {
            //     let start = $("#summer_periode_start").val()
            //     let end = $("#summer_periode_end").val()
            //     let morning_start = $(`#summer_schedule .morning-start[data-index=${current_day}] option:selected`).val()
            //     let morning_end = $(`#summer_schedule .morning-end[data-index=${current_day}] option:selected`).val()
            //     let afternoon_start = $(`#summer_schedule .afternoon-start[data-index=${current_day}] option:selected`).val()
            //     let afternoon_end = $(`#summer_schedule .afternoon-end[data-index=${current_day}] option:selected`).val()

            //     let day = [morning_start, morning_end, afternoon_start, afternoon_end, start, end]

            //     schedule.push(day)
            //     formData.append(`summer_day_${current_day}`, day);
            // }

            // // ramadan
            // for (let current_day = 0; current_day < ramadan_total_days; current_day++) {
            //     let start = $("#ramadan_periode_start").val()
            //     let end = $("#ramadan_periode_end").val()
            //     let morning_start = $(`#ramadan_schedule .morning-start[data-index=${current_day}] option:selected`).val()
            //     let morning_end = $(`#ramadan_schedule .morning-end[data-index=${current_day}] option:selected`).val()

            //     let day =[morning_start, morning_end, start, end]

            //     schedule.push(day)
            //     formData.append(`ramadan_day_${current_day}`, day);
            // }

            // holidays
            let holiday_ids = []
            let edit_holidays = []
            let new_holidays = []
            let count_new = 0
            let count_edit = 0

            $( ".schedule_holiday" ).each(function( index, data ) {
            //for (let holiday_day = 1; holiday_day <= holidays_total; holiday_day++) {
                //holiday_ids.push($(`.schedule_holiday_${indx}`).data("id"))
                //if ($(`.schedule_holiday_${holiday_day}`).data("id") != undefined) {
                if ($(data).data("id") != undefined) {
                    let indx = $(data).data("index")
                    count_edit++
                    
                    let holiday_name = $(`#schedule_holiday_name_${indx}`).val()
                    let holiday_date = $(`#schedule_holiday_date_${indx}`).val()
                    let holiday_active = $(`#schedule_holiday_active_${indx}`).prop('checked')
                    //let holiday = [`'${holiday_name}'`, `'${holiday_date}'`, `'${holiday_active}'`]
                    //edit_holidays.push(holiday)
                    
                    formData.append(`edit_holidays_${count_edit}[]`, $(`.schedule_holiday_${indx}`).data("id"));
                    formData.append(`edit_holidays_${count_edit}[]`, holiday_name);
                    formData.append(`edit_holidays_${count_edit}[]`, holiday_date);
                    formData.append(`edit_holidays_${count_edit}[]`, holiday_active);
                    
                }
                else {
                    let indx = $(data).data("index")
                    count_new++
                    let holiday_name = $(`#schedule_holiday_name_${indx}`).val()
                    let holiday_date = $(`#schedule_holiday_date_${indx}`).val()
                    let holiday_active = $(`#schedule_holiday_active_${indx}`).prop('checked')
                    let holiday = [`'${holiday_name}'`, `'${holiday_date}'`, `'${holiday_active}'`]
                    
                    formData.append(`holiday_ids`, holiday_ids)
                    if (holiday_name != "" && holiday_date != "") {
                        formData.append(`new_holidays_${count_new}[]`, holiday_name);
                        formData.append(`new_holidays_${count_new}[]`, holiday_date);
                        formData.append(`new_holidays_${count_new}[]`, holiday_active);
                    }
                    //new_holidays.push(holiday)
                }               

            })

            formData.append('edit_holidays', edit_holidays);
            formData.append('new_holidays', new_holidays);
            formData.append('count_new', count_new);
            formData.append('count_edit', count_edit);
            formData.append('remove_holidays', remove_holidays);
            
            // services
            services = []
            $( ".workshop_service_duration").each(function( index, data ) {  
                let workshop_service_id = $(data).data("workshop_service_id")
                let workshop_capacity = $("#workshop_capacity_" + workshop_service_id).val()
                
                let service = {
                    id: workshop_service_id,
                    duration: $(data).val()
                }

                //services.push(JSON.stringify(service))
                formData.append('services[]', [workshop_service_id, $(data).val(), workshop_capacity])
            })
            //formData.append('services', services);

            formData.append('capacity', $("#workshop-capacity").val());
            
            fetch(`/workshops/${workshop_id}/schedule`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                dataType: "json",
                contentType: "application/json; charset=UTF-8",
                processData: false,
                body: formData,
            }).then(function(response) {
                
                if (response.ok) {
                    response.json().then(function(data) {
                        
                        $("#btn-save-schedule").attr("disabled", false)

                        modal_workshop.hide()
                    })
                }
                else {
                    response.json().then(function(data) {
                       
                    })
                }
            })
        }

        function deleteWorshop(id) {
            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer cet atelier?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/workshops/${id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            table_workshops.row(`tr[data-element_id='${id}']`).remove().draw();
                        }
                    })
                }
            }).then((result) => {})
        }
        

        function removeBrand(brand_id) {
            Swal.fire({
                title: 'Retirer?',
                text: "Voulez-vous vraiment retirer cette marque?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/workshops/brand/${brand_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            table_brands.ajax.reload()
                        }
                    })
                }
            }).then((result) => {})
        }

        
        if (document.getElementById("modal-workshop") != null) {
            document.getElementById("modal-workshop").addEventListener('hidden.bs.modal', function (event) {
                $(".afternoon-start").val("off")
                $(`input[name='schedule_active']`).prop("checked", false)
                $(".schedule_holidays").html("")
                $("#horaire5-tab-pane").html("")
                remove_holidays = []

                $("#workshop-tab-content").addClass("d-none")
            })
        }
        
    }
})