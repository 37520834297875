import consumer from "./consumer"

consumer.subscriptions.create("AppointmentChannel", {
    connected() {
        console.log("connected")
    },
    disconnected() {
        console.log("disconnected")
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        // update agenda appointments status
        if (data.type == "status_update") {
            if (data.appointment.state == "accepted") {
                $(`.appointment-${data.appointment.id}.rdv .badge`).remove()
                $(`.appointment-${data.appointment.id}.rdv`).prepend(`<span class="badge text-bg-primary">Accepté</span>`)
            }
            else if (data.appointment.state == "in_progress") {
                $(`.appointment-${data.appointment.id}.rdv .badge`).remove()
                $(`.appointment-${data.appointment.id}.rdv`).prepend(`<span class="badge text-bg-info">En cours d'entretien</span>`)
            }
            else if (data.appointment.state == "completed") {
                $(`.appointment-${data.appointment.id}.rdv .badge`).remove()
                $(`.appointment-${data.appointment.id}.rdv`).prepend(`<span class="badge text-bg-success">Fini</span>`)
            }
            else if (data.appointment.state == "missed") {
                $(`.appointment-${data.appointment.id}.rdv .badge`).remove()
                $(`.appointment-${data.appointment.id}.rdv`).prepend(`<span class="badge text-bg-danger">Client ne s'est pas présenté</span>`)
            }
        }

        // new appointment
        if (data.type == "new_appointment") {
            if ($(`#selected_date[data-date='${data.appointment.date}']`).length > 0) {

                let selected_services = $("#calendar-services option:selected").val().split(',')

                if (selected_services.includes(data.appointment.service.id.toString()) && $("#calendar-services option:selected").attr("data-workshop_id") == data.appointment.workshop.id) {
                    $(".no-rdv").remove()
                    
                    let appointment = data.appointment
                    let time = `${appointment.time} à ${ moment(new Date(`2024-08-27 ${appointment.time}`)).add(appointment.duration, "minutes").format("k:mm")}`
                    
                    if ($(`#list_appointments table[data-time='${time}']`).length == 0) {
                        $(`#list_appointments`).prepend(`<table class='table_${appointment.date}_${appointment.time.replace(':', 'h')} mb-3 w-100' data-time='${time}'>
                                <tbody>
                                <tr style='background: #f7f7f7;'>
                                    <td class='p-3 text-center'>
                                        <b>${time}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td class='p-2'>
                                        <div class='date_${appointment.date}_${appointment.time.replace(":", "h")} mb-3'>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        `)

                        AddNewAppointmentToCalendar(appointment)
                    }
                }
                else {
                    if ($("#calendar-services option:selected").val() == data.appointment.service_id) {
                        AddNewAppointmentToCalendar(appointment)
                    }
                    
                }
            }
            else {
               
            }
        }

        // delete appointment
        if (data.type == "delete_appointment") {

            let selected_services = $("#calendar-services option:selected").val().split(',')

            if (selected_services.includes(data.appointment.service_id.toString())) {
                $(`.appointment-${data.appointment.id}-content`).remove()
                
                if ($(`.date_${data.appointment.date}_${data.appointment.time.replace(":", "h")}`).length > 0) {
                    if ($(`.date_${data.appointment.date}_${data.appointment.time.replace(":", "h")}`).html().trim() == '') {
                        $(`.table_${data.appointment.date}_${data.appointment.time.replace(":", "h")}`).remove()
                    }
                }

                if ($("#list_appointments").children().length == 0) {
                    $("#list_appointments").html(`<div class="text-center no-rdv mt-3 mb-3">Aucun rendez vous disponible!</div>`)
                }
            }
        }


        function AddNewAppointmentToCalendar(appointment) {
            let time = appointment.time
            let state = ""
            
            if (appointment.state == "completed")
                state = "<span class='badge text-bg-success'>Fini</span>"
            if (appointment.state == "accepted")
                state = "<span class='badge text-bg-primary'>Accepté</span>"
            if (appointment.state == "in_progress")
                state = "<span class='badge text-bg-info'>En cours d'entretien</span>"
            else if (appointment.state == "missed") {
                state = "<span class='badge text-bg-danger'>Client ne s'est pas présenté</span>"
            }

            $(`.date_${appointment.date}_${time.replace(":", "h")}`).append(`<div class="appointment-${appointment.id}-content">
                - <a href="javascript:void(0)" class='appointment-${appointment.id} rdv' data-appointment_id='${appointment.id}'> ${state} ${appointment.service.name} (${appointment.user.full_name} - <span style='color:#008dbb'>${appointment.brand.brand} ${appointment.car.model.name} (${appointment.car.car_number})</span>)</a>
            </div>`)

            $(`.appointment-${appointment.id}`).on("click", function(e) {
                fetch(`/appointments/${$(e.currentTarget).data('appointment_id')}`, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'default',
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $(`.appointment-${$(e.currentTarget).data('appointment_id')} .indicator`).remove()
                            $('#see_appointment_id').val(data.id)
                            $("#see_service").val(`${data.service.name} (${data.duration} ${Pluralize( 'minute', data.duration )})`)
                            $("#see_date").val(moment(data.date).locale("fr").format('dddd DD MMM YYYY'))
                            $("#see_periode").val(`${data.time} à ${ moment(new Date(`2024-08-27 ${data.time}`)).add(data.duration, "minutes").format("k:mm")}`);
                            $("#see_full_name").val(data.user.full_name)
                            $("#see_phone").val(data.phone)
                            $("#see_email").val(data.email);
                            $("#see_car_number").val(`${data.brand.brand} ${data.car.model.name} (${data.car.car_number})`);
                            $("#see_vin").val(`${data.car.vin}`);
                            $("#see_km").val(data.km);
                            $("#see_notes").val(data.notes);
                            $("#see_description").val(data.description);
                            $("#see_note_intern").val(data.note_intern);
                            $("#see_state").val(data.state);
                            $('#see_note_client').val(data.note_client)

                            if (data.service.slug == 'carrosserie') {
                                $(".carrosserie").removeClass('visually-hidden')
                            }
                            else {
                                $(".carrosserie").addClass('visually-hidden')
                            }

                            const modal_appointment = new bootstrap.Modal(document.getElementById("modal-see_appointment"), {backdrop: 'static', keyboard: false});
                            modal_appointment.show()
                        })
                    }
                })
            })
        }
    }
})