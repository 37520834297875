document.addEventListener("turbolinks:load", function() {
    if(($("body").data("controller") == "notifications" && $("body").data("user_role") == "client"))  {
    

        let appointment_id;

        let table_alerts = $('#table-alerts').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "language": {
                "emptyTable": "Aucune alerte.",
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "pageLength": 5,
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            drawCallback: function () {

            },
        });

        
        let table_alerts_read = $('#table-alerts_read').DataTable({
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "language": {
                "emptyTable": "Aucune alerte.",
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "pageLength": 5,
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });


        $(".btn-alert-set-seen").on("click", function() {
            let id = $(this).data("id")
            
            Swal.fire({
                text: "Marquer cette alerte comme lue?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/appointments/alert/${id}`, {
                        method: 'PUT',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                table_alerts.row($(`tr[data-element_id='${id}']`)).remove().draw();

                                table_alerts_read.row.add([data.libelle, '']).draw(false);
                            })
                        }
                    })
                }
            }).then((result) => {})
        })
    }
})